export default [
	"mdi mdi-application-cog",
	"mdi mdi-application-settings",
	"mdi mdi-arrow-bottom-left-thin-circle-outline",
	"mdi mdi-arrow-bottom-right-thin-circle-outline",
	"mdi mdi-arrow-down-thin-circle-outline",
	"mdi mdi-arrow-left-thin-circle-outline",
	"mdi mdi-arrow-right-thin-circle-outline",
	"mdi mdi-arrow-top-left-thin-circle-outline",
	"mdi mdi-arrow-top-right-thin-circle-outline",
	"mdi mdi-arrow-up-thin-circle-outline",
	"mdi mdi-bag-suitcase",
	"mdi mdi-bag-suitcase-off",
	"mdi mdi-bag-suitcase-off-outline",
	"mdi mdi-bag-suitcase-outline",
	"mdi mdi-butterfly",
	"mdi mdi-butterfly-outline",
	"mdi mdi-calculator-variant-outline",
	"mdi mdi-calendar-cursor",
	"mdi mdi-comment-bookmark",
	"mdi mdi-comment-bookmark-outline",
	"mdi mdi-comment-flash",
	"mdi mdi-comment-flash-outline",
	"mdi mdi-dance-pole",
	"mdi mdi-delete-clock",
	"mdi mdi-delete-clock-outline",
	"mdi mdi-eiffel-tower",
	"mdi mdi-emoticon-sick",
	"mdi mdi-emoticon-sick-outline",
	"mdi mdi-face-mask",
	"mdi mdi-face-mask-outline",
	"mdi mdi-food-halal",
	"mdi mdi-food-kosher",
	"mdi mdi-hand-heart-outline",
	"mdi mdi-hand-wash",
	"mdi mdi-hand-wash-outline",
	"mdi mdi-handshake-outline",
	"mdi mdi-human-cane",
	"mdi mdi-human-capacity-decrease",
	"mdi mdi-human-capacity-increase",
	"mdi mdi-human-greeting-proximity",
	"mdi mdi-human-queue",
	"mdi mdi-hvac-off",
	"mdi mdi-inbox-remove",
	"mdi mdi-inbox-remove-outline",
	"mdi mdi-kangaroo",
	"mdi mdi-key-chain",
	"mdi mdi-key-chain-variant",
	"mdi mdi-ladder",
	"mdi mdi-lamps",
	"mdi mdi-list-status",
	"mdi mdi-lotion",
	"mdi mdi-lotion-outline",
	"mdi mdi-lotion-plus",
	"mdi mdi-lotion-plus-outline",
	"mdi mdi-message-bookmark",
	"mdi mdi-message-bookmark-outline",
	"mdi mdi-message-flash",
	"mdi mdi-message-flash-outline",
	"mdi mdi-motion",
	"mdi mdi-motion-outline",
	"mdi mdi-motion-pause",
	"mdi mdi-motion-pause-outline",
	"mdi mdi-motion-play",
	"mdi mdi-motion-play-outline",
	"mdi mdi-mouse-move-down",
	"mdi mdi-mouse-move-up",
	"mdi mdi-mouse-move-vertical",
	"mdi mdi-phone-dial",
	"mdi mdi-phone-dial-outline",
	"mdi mdi-pitchfork",
	"mdi mdi-pretzel",
	"mdi mdi-reiterate",
	"mdi mdi-router-wireless-off",
	"mdi mdi-seesaw",
	"mdi mdi-shield-account-variant",
	"mdi mdi-shield-account-variant-outline",
	"mdi mdi-slide",
	"mdi mdi-social-distance-2-meters",
	"mdi mdi-social-distance-6-feet",
	"mdi mdi-sofa-outline",
	"mdi mdi-sofa-single",
	"mdi mdi-sofa-single-outline",
	"mdi mdi-star-check",
	"mdi mdi-star-check-outline",
	"mdi mdi-star-minus",
	"mdi mdi-star-minus-outline",
	"mdi mdi-star-off-outline",
	"mdi mdi-star-plus",
	"mdi mdi-star-plus-outline",
	"mdi mdi-star-remove",
	"mdi mdi-star-remove-outline",
	"mdi mdi-submarine",
	"mdi mdi-text-account",
	"mdi mdi-tooltip-check",
	"mdi mdi-tooltip-check-outline",
	"mdi mdi-tooltip-minus",
	"mdi mdi-tooltip-minus-outline",
	"mdi mdi-tooltip-remove",
	"mdi mdi-tooltip-remove-outline",
	"mdi mdi-vanish-quarter",
	"mdi mdi-ab-testing",
	"mdi mdi-abjad-arabic",
	"mdi mdi-abjad-hebrew",
	"mdi mdi-abugida-devanagari",
	"mdi mdi-abugida-thai",
	"mdi mdi-access-point",
	"mdi mdi-access-point-check",
	"mdi mdi-access-point-minus",
	"mdi mdi-access-point-network",
	"mdi mdi-access-point-network-off",
	"mdi mdi-access-point-off",
	"mdi mdi-access-point-plus",
	"mdi mdi-access-point-remove",
	"mdi mdi-account",
	"mdi mdi-account-alert",
	"mdi mdi-account-alert-outline",
	"mdi mdi-account-arrow-left",
	"mdi mdi-account-arrow-left-outline",
	"mdi mdi-account-arrow-right",
	"mdi mdi-account-arrow-right-outline",
	"mdi mdi-account-box",
	"mdi mdi-account-box-multiple",
	"mdi mdi-account-box-multiple-outline",
	"mdi mdi-account-box-outline",
	"mdi mdi-account-cancel",
	"mdi mdi-account-cancel-outline",
	"mdi mdi-account-cash",
	"mdi mdi-account-cash-outline",
	"mdi mdi-account-check",
	"mdi mdi-account-check-outline",
	"mdi mdi-account-child",
	"mdi mdi-account-child-circle",
	"mdi mdi-account-child-outline",
	"mdi mdi-account-circle",
	"mdi mdi-account-circle-outline",
	"mdi mdi-account-clock",
	"mdi mdi-account-clock-outline",
	"mdi mdi-account-cog",
	"mdi mdi-account-cog-outline",
	"mdi mdi-account-convert",
	"mdi mdi-account-convert-outline",
	"mdi mdi-account-cowboy-hat",
	"mdi mdi-account-details",
	"mdi mdi-account-details-outline",
	"mdi mdi-account-edit",
	"mdi mdi-account-edit-outline",
	"mdi mdi-account-group",
	"mdi mdi-account-group-outline",
	"mdi mdi-account-hard-hat",
	"mdi mdi-account-heart",
	"mdi mdi-account-heart-outline",
	"mdi mdi-account-key",
	"mdi mdi-account-key-outline",
	"mdi mdi-account-lock",
	"mdi mdi-account-lock-outline",
	"mdi mdi-account-minus",
	"mdi mdi-account-minus-outline",
	"mdi mdi-account-multiple",
	"mdi mdi-account-multiple-check",
	"mdi mdi-account-multiple-check-outline",
	"mdi mdi-account-multiple-minus",
	"mdi mdi-account-multiple-minus-outline",
	"mdi mdi-account-multiple-outline",
	"mdi mdi-account-multiple-plus",
	"mdi mdi-account-multiple-plus-outline",
	"mdi mdi-account-multiple-remove",
	"mdi mdi-account-multiple-remove-outline",
	"mdi mdi-account-music",
	"mdi mdi-account-music-outline",
	"mdi mdi-account-network",
	"mdi mdi-account-network-outline",
	"mdi mdi-account-off",
	"mdi mdi-account-off-outline",
	"mdi mdi-account-outline",
	"mdi mdi-account-plus",
	"mdi mdi-account-plus-outline",
	"mdi mdi-account-question",
	"mdi mdi-account-question-outline",
	"mdi mdi-account-reactivate",
	"mdi mdi-account-reactivate-outline",
	"mdi mdi-account-remove",
	"mdi mdi-account-remove-outline",
	"mdi mdi-account-search",
	"mdi mdi-account-search-outline",
	"mdi mdi-account-settings",
	"mdi mdi-account-settings-outline",
	"mdi mdi-account-star",
	"mdi mdi-account-star-outline",
	"mdi mdi-account-supervisor",
	"mdi mdi-account-supervisor-circle",
	"mdi mdi-account-supervisor-circle-outline",
	"mdi mdi-account-supervisor-outline",
	"mdi mdi-account-switch",
	"mdi mdi-account-switch-outline",
	"mdi mdi-account-tie",
	"mdi mdi-account-tie-outline",
	"mdi mdi-account-tie-voice",
	"mdi mdi-account-tie-voice-off",
	"mdi mdi-account-tie-voice-off-outline",
	"mdi mdi-account-tie-voice-outline",
	"mdi mdi-account-voice",
	"mdi mdi-adjust",
	"mdi mdi-adobe",
	"mdi mdi-adobe-acrobat",
	"mdi mdi-air-conditioner",
	"mdi mdi-air-filter",
	"mdi mdi-air-horn",
	"mdi mdi-air-humidifier",
	"mdi mdi-air-humidifier-off",
	"mdi mdi-air-purifier",
	"mdi mdi-airbag",
	"mdi mdi-airballoon",
	"mdi mdi-airballoon-outline",
	"mdi mdi-airplane",
	"mdi mdi-airplane-landing",
	"mdi mdi-airplane-off",
	"mdi mdi-airplane-takeoff",
	"mdi mdi-airport",
	"mdi mdi-alarm",
	"mdi mdi-alarm-bell",
	"mdi mdi-alarm-check",
	"mdi mdi-alarm-light",
	"mdi mdi-alarm-light-outline",
	"mdi mdi-alarm-multiple",
	"mdi mdi-alarm-note",
	"mdi mdi-alarm-note-off",
	"mdi mdi-alarm-off",
	"mdi mdi-alarm-plus",
	"mdi mdi-alarm-snooze",
	"mdi mdi-album",
	"mdi mdi-alert",
	"mdi mdi-alert-box",
	"mdi mdi-alert-box-outline",
	"mdi mdi-alert-circle",
	"mdi mdi-alert-circle-check",
	"mdi mdi-alert-circle-check-outline",
	"mdi mdi-alert-circle-outline",
	"mdi mdi-alert-decagram",
	"mdi mdi-alert-decagram-outline",
	"mdi mdi-alert-minus",
	"mdi mdi-alert-minus-outline",
	"mdi mdi-alert-octagon",
	"mdi mdi-alert-octagon-outline",
	"mdi mdi-alert-octagram",
	"mdi mdi-alert-octagram-outline",
	"mdi mdi-alert-outline",
	"mdi mdi-alert-plus",
	"mdi mdi-alert-plus-outline",
	"mdi mdi-alert-remove",
	"mdi mdi-alert-remove-outline",
	"mdi mdi-alert-rhombus",
	"mdi mdi-alert-rhombus-outline",
	"mdi mdi-alien",
	"mdi mdi-alien-outline",
	"mdi mdi-align-horizontal-center",
	"mdi mdi-align-horizontal-left",
	"mdi mdi-align-horizontal-right",
	"mdi mdi-align-vertical-bottom",
	"mdi mdi-align-vertical-center",
	"mdi mdi-align-vertical-top",
	"mdi mdi-all-inclusive",
	"mdi mdi-allergy",
	"mdi mdi-alpha",
	"mdi mdi-alpha-a",
	"mdi mdi-alpha-a-box",
	"mdi mdi-alpha-a-box-outline",
	"mdi mdi-alpha-a-circle",
	"mdi mdi-alpha-a-circle-outline",
	"mdi mdi-alpha-b",
	"mdi mdi-alpha-b-box",
	"mdi mdi-alpha-b-box-outline",
	"mdi mdi-alpha-b-circle",
	"mdi mdi-alpha-b-circle-outline",
	"mdi mdi-alpha-c",
	"mdi mdi-alpha-c-box",
	"mdi mdi-alpha-c-box-outline",
	"mdi mdi-alpha-c-circle",
	"mdi mdi-alpha-c-circle-outline",
	"mdi mdi-alpha-d",
	"mdi mdi-alpha-d-box",
	"mdi mdi-alpha-d-box-outline",
	"mdi mdi-alpha-d-circle",
	"mdi mdi-alpha-d-circle-outline",
	"mdi mdi-alpha-e",
	"mdi mdi-alpha-e-box",
	"mdi mdi-alpha-e-box-outline",
	"mdi mdi-alpha-e-circle",
	"mdi mdi-alpha-e-circle-outline",
	"mdi mdi-alpha-f",
	"mdi mdi-alpha-f-box",
	"mdi mdi-alpha-f-box-outline",
	"mdi mdi-alpha-f-circle",
	"mdi mdi-alpha-f-circle-outline",
	"mdi mdi-alpha-g",
	"mdi mdi-alpha-g-box",
	"mdi mdi-alpha-g-box-outline",
	"mdi mdi-alpha-g-circle",
	"mdi mdi-alpha-g-circle-outline",
	"mdi mdi-alpha-h",
	"mdi mdi-alpha-h-box",
	"mdi mdi-alpha-h-box-outline",
	"mdi mdi-alpha-h-circle",
	"mdi mdi-alpha-h-circle-outline",
	"mdi mdi-alpha-i",
	"mdi mdi-alpha-i-box",
	"mdi mdi-alpha-i-box-outline",
	"mdi mdi-alpha-i-circle",
	"mdi mdi-alpha-i-circle-outline",
	"mdi mdi-alpha-j",
	"mdi mdi-alpha-j-box",
	"mdi mdi-alpha-j-box-outline",
	"mdi mdi-alpha-j-circle",
	"mdi mdi-alpha-j-circle-outline",
	"mdi mdi-alpha-k",
	"mdi mdi-alpha-k-box",
	"mdi mdi-alpha-k-box-outline",
	"mdi mdi-alpha-k-circle",
	"mdi mdi-alpha-k-circle-outline",
	"mdi mdi-alpha-l",
	"mdi mdi-alpha-l-box",
	"mdi mdi-alpha-l-box-outline",
	"mdi mdi-alpha-l-circle",
	"mdi mdi-alpha-l-circle-outline",
	"mdi mdi-alpha-m",
	"mdi mdi-alpha-m-box",
	"mdi mdi-alpha-m-box-outline",
	"mdi mdi-alpha-m-circle",
	"mdi mdi-alpha-m-circle-outline",
	"mdi mdi-alpha-n",
	"mdi mdi-alpha-n-box",
	"mdi mdi-alpha-n-box-outline",
	"mdi mdi-alpha-n-circle",
	"mdi mdi-alpha-n-circle-outline",
	"mdi mdi-alpha-o",
	"mdi mdi-alpha-o-box",
	"mdi mdi-alpha-o-box-outline",
	"mdi mdi-alpha-o-circle",
	"mdi mdi-alpha-o-circle-outline",
	"mdi mdi-alpha-p",
	"mdi mdi-alpha-p-box",
	"mdi mdi-alpha-p-box-outline",
	"mdi mdi-alpha-p-circle",
	"mdi mdi-alpha-p-circle-outline",
	"mdi mdi-alpha-q",
	"mdi mdi-alpha-q-box",
	"mdi mdi-alpha-q-box-outline",
	"mdi mdi-alpha-q-circle",
	"mdi mdi-alpha-q-circle-outline",
	"mdi mdi-alpha-r",
	"mdi mdi-alpha-r-box",
	"mdi mdi-alpha-r-box-outline",
	"mdi mdi-alpha-r-circle",
	"mdi mdi-alpha-r-circle-outline",
	"mdi mdi-alpha-s",
	"mdi mdi-alpha-s-box",
	"mdi mdi-alpha-s-box-outline",
	"mdi mdi-alpha-s-circle",
	"mdi mdi-alpha-s-circle-outline",
	"mdi mdi-alpha-t",
	"mdi mdi-alpha-t-box",
	"mdi mdi-alpha-t-box-outline",
	"mdi mdi-alpha-t-circle",
	"mdi mdi-alpha-t-circle-outline",
	"mdi mdi-alpha-u",
	"mdi mdi-alpha-u-box",
	"mdi mdi-alpha-u-box-outline",
	"mdi mdi-alpha-u-circle",
	"mdi mdi-alpha-u-circle-outline",
	"mdi mdi-alpha-v",
	"mdi mdi-alpha-v-box",
	"mdi mdi-alpha-v-box-outline",
	"mdi mdi-alpha-v-circle",
	"mdi mdi-alpha-v-circle-outline",
	"mdi mdi-alpha-w",
	"mdi mdi-alpha-w-box",
	"mdi mdi-alpha-w-box-outline",
	"mdi mdi-alpha-w-circle",
	"mdi mdi-alpha-w-circle-outline",
	"mdi mdi-alpha-x",
	"mdi mdi-alpha-x-box",
	"mdi mdi-alpha-x-box-outline",
	"mdi mdi-alpha-x-circle",
	"mdi mdi-alpha-x-circle-outline",
	"mdi mdi-alpha-y",
	"mdi mdi-alpha-y-box",
	"mdi mdi-alpha-y-box-outline",
	"mdi mdi-alpha-y-circle",
	"mdi mdi-alpha-y-circle-outline",
	"mdi mdi-alpha-z",
	"mdi mdi-alpha-z-box",
	"mdi mdi-alpha-z-box-outline",
	"mdi mdi-alpha-z-circle",
	"mdi mdi-alpha-z-circle-outline",
	"mdi mdi-alphabet-aurebesh",
	"mdi mdi-alphabet-cyrillic",
	"mdi mdi-alphabet-greek",
	"mdi mdi-alphabet-latin",
	"mdi mdi-alphabet-piqad",
	"mdi mdi-alphabet-tengwar",
	"mdi mdi-alphabetical",
	"mdi mdi-alphabetical-off",
	"mdi mdi-alphabetical-variant",
	"mdi mdi-alphabetical-variant-off",
	"mdi mdi-altimeter",
	"mdi mdi-amazon",
	"mdi mdi-amazon-alexa",
	"mdi mdi-ambulance",
	"mdi mdi-ammunition",
	"mdi mdi-ampersand",
	"mdi mdi-amplifier",
	"mdi mdi-amplifier-off",
	"mdi mdi-anchor",
	"mdi mdi-android",
	"mdi mdi-android-auto",
	"mdi mdi-android-debug-bridge",
	"mdi mdi-android-messages",
	"mdi mdi-android-studio",
	"mdi mdi-angle-acute",
	"mdi mdi-angle-obtuse",
	"mdi mdi-angle-right",
	"mdi mdi-angular",
	"mdi mdi-angularjs",
	"mdi mdi-animation",
	"mdi mdi-animation-outline",
	"mdi mdi-animation-play",
	"mdi mdi-animation-play-outline",
	"mdi mdi-ansible",
	"mdi mdi-antenna",
	"mdi mdi-anvil",
	"mdi mdi-apache-kafka",
	"mdi mdi-api",
	"mdi mdi-api-off",
	"mdi mdi-apple",
	"mdi mdi-apple-airplay",
	"mdi mdi-apple-finder",
	"mdi mdi-apple-icloud",
	"mdi mdi-apple-ios",
	"mdi mdi-apple-keyboard-caps",
	"mdi mdi-apple-keyboard-command",
	"mdi mdi-apple-keyboard-control",
	"mdi mdi-apple-keyboard-option",
	"mdi mdi-apple-keyboard-shift",
	"mdi mdi-apple-safari",
	"mdi mdi-application",
	"mdi mdi-application-cog",
	"mdi mdi-application-export",
	"mdi mdi-application-import",
	"mdi mdi-application-settings",
	"mdi mdi-approximately-equal",
	"mdi mdi-approximately-equal-box",
	"mdi mdi-apps",
	"mdi mdi-apps-box",
	"mdi mdi-arch",
	"mdi mdi-archive",
	"mdi mdi-archive-alert",
	"mdi mdi-archive-alert-outline",
	"mdi mdi-archive-arrow-down",
	"mdi mdi-archive-arrow-down-outline",
	"mdi mdi-archive-arrow-up",
	"mdi mdi-archive-arrow-up-outline",
	"mdi mdi-archive-outline",
	"mdi mdi-arm-flex",
	"mdi mdi-arm-flex-outline",
	"mdi mdi-arrange-bring-forward",
	"mdi mdi-arrange-bring-to-front",
	"mdi mdi-arrange-send-backward",
	"mdi mdi-arrange-send-to-back",
	"mdi mdi-arrow-all",
	"mdi mdi-arrow-bottom-left",
	"mdi mdi-arrow-bottom-left-bold-outline",
	"mdi mdi-arrow-bottom-left-thick",
	"mdi mdi-arrow-bottom-left-thin-circle-outline",
	"mdi mdi-arrow-bottom-right",
	"mdi mdi-arrow-bottom-right-bold-outline",
	"mdi mdi-arrow-bottom-right-thick",
	"mdi mdi-arrow-bottom-right-thin-circle-outline",
	"mdi mdi-arrow-collapse",
	"mdi mdi-arrow-collapse-all",
	"mdi mdi-arrow-collapse-down",
	"mdi mdi-arrow-collapse-horizontal",
	"mdi mdi-arrow-collapse-left",
	"mdi mdi-arrow-collapse-right",
	"mdi mdi-arrow-collapse-up",
	"mdi mdi-arrow-collapse-vertical",
	"mdi mdi-arrow-decision",
	"mdi mdi-arrow-decision-auto",
	"mdi mdi-arrow-decision-auto-outline",
	"mdi mdi-arrow-decision-outline",
	"mdi mdi-arrow-down",
	"mdi mdi-arrow-down-bold",
	"mdi mdi-arrow-down-bold-box",
	"mdi mdi-arrow-down-bold-box-outline",
	"mdi mdi-arrow-down-bold-circle",
	"mdi mdi-arrow-down-bold-circle-outline",
	"mdi mdi-arrow-down-bold-hexagon-outline",
	"mdi mdi-arrow-down-bold-outline",
	"mdi mdi-arrow-down-box",
	"mdi mdi-arrow-down-circle",
	"mdi mdi-arrow-down-circle-outline",
	"mdi mdi-arrow-down-drop-circle",
	"mdi mdi-arrow-down-drop-circle-outline",
	"mdi mdi-arrow-down-thick",
	"mdi mdi-arrow-down-thin-circle-outline",
	"mdi mdi-arrow-expand",
	"mdi mdi-arrow-expand-all",
	"mdi mdi-arrow-expand-down",
	"mdi mdi-arrow-expand-horizontal",
	"mdi mdi-arrow-expand-left",
	"mdi mdi-arrow-expand-right",
	"mdi mdi-arrow-expand-up",
	"mdi mdi-arrow-expand-vertical",
	"mdi mdi-arrow-horizontal-lock",
	"mdi mdi-arrow-left",
	"mdi mdi-arrow-left-bold",
	"mdi mdi-arrow-left-bold-box",
	"mdi mdi-arrow-left-bold-box-outline",
	"mdi mdi-arrow-left-bold-circle",
	"mdi mdi-arrow-left-bold-circle-outline",
	"mdi mdi-arrow-left-bold-hexagon-outline",
	"mdi mdi-arrow-left-bold-outline",
	"mdi mdi-arrow-left-box",
	"mdi mdi-arrow-left-circle",
	"mdi mdi-arrow-left-circle-outline",
	"mdi mdi-arrow-left-drop-circle",
	"mdi mdi-arrow-left-drop-circle-outline",
	"mdi mdi-arrow-left-right",
	"mdi mdi-arrow-left-right-bold",
	"mdi mdi-arrow-left-right-bold-outline",
	"mdi mdi-arrow-left-thick",
	"mdi mdi-arrow-left-thin-circle-outline",
	"mdi mdi-arrow-right",
	"mdi mdi-arrow-right-bold",
	"mdi mdi-arrow-right-bold-box",
	"mdi mdi-arrow-right-bold-box-outline",
	"mdi mdi-arrow-right-bold-circle",
	"mdi mdi-arrow-right-bold-circle-outline",
	"mdi mdi-arrow-right-bold-hexagon-outline",
	"mdi mdi-arrow-right-bold-outline",
	"mdi mdi-arrow-right-box",
	"mdi mdi-arrow-right-circle",
	"mdi mdi-arrow-right-circle-outline",
	"mdi mdi-arrow-right-drop-circle",
	"mdi mdi-arrow-right-drop-circle-outline",
	"mdi mdi-arrow-right-thick",
	"mdi mdi-arrow-right-thin-circle-outline",
	"mdi mdi-arrow-split-horizontal",
	"mdi mdi-arrow-split-vertical",
	"mdi mdi-arrow-top-left",
	"mdi mdi-arrow-top-left-bold-outline",
	"mdi mdi-arrow-top-left-bottom-right",
	"mdi mdi-arrow-top-left-bottom-right-bold",
	"mdi mdi-arrow-top-left-thick",
	"mdi mdi-arrow-top-left-thin-circle-outline",
	"mdi mdi-arrow-top-right",
	"mdi mdi-arrow-top-right-bold-outline",
	"mdi mdi-arrow-top-right-bottom-left",
	"mdi mdi-arrow-top-right-bottom-left-bold",
	"mdi mdi-arrow-top-right-thick",
	"mdi mdi-arrow-top-right-thin-circle-outline",
	"mdi mdi-arrow-up",
	"mdi mdi-arrow-up-bold",
	"mdi mdi-arrow-up-bold-box",
	"mdi mdi-arrow-up-bold-box-outline",
	"mdi mdi-arrow-up-bold-circle",
	"mdi mdi-arrow-up-bold-circle-outline",
	"mdi mdi-arrow-up-bold-hexagon-outline",
	"mdi mdi-arrow-up-bold-outline",
	"mdi mdi-arrow-up-box",
	"mdi mdi-arrow-up-circle",
	"mdi mdi-arrow-up-circle-outline",
	"mdi mdi-arrow-up-down",
	"mdi mdi-arrow-up-down-bold",
	"mdi mdi-arrow-up-down-bold-outline",
	"mdi mdi-arrow-up-drop-circle",
	"mdi mdi-arrow-up-drop-circle-outline",
	"mdi mdi-arrow-up-thick",
	"mdi mdi-arrow-up-thin-circle-outline",
	"mdi mdi-arrow-vertical-lock",
	"mdi mdi-artstation",
	"mdi mdi-aspect-ratio",
	"mdi mdi-assistant",
	"mdi mdi-asterisk",
	"mdi mdi-at",
	"mdi mdi-atlassian",
	"mdi mdi-atm",
	"mdi mdi-atom",
	"mdi mdi-atom-variant",
	"mdi mdi-attachment",
	"mdi mdi-audio-video",
	"mdi mdi-audio-video-off",
	"mdi mdi-augmented-reality",
	"mdi mdi-auto-download",
	"mdi mdi-auto-fix",
	"mdi mdi-auto-upload",
	"mdi mdi-autorenew",
	"mdi mdi-av-timer",
	"mdi mdi-aws",
	"mdi mdi-axe",
	"mdi mdi-axis",
	"mdi mdi-axis-arrow",
	"mdi mdi-axis-arrow-info",
	"mdi mdi-axis-arrow-lock",
	"mdi mdi-axis-lock",
	"mdi mdi-axis-x-arrow",
	"mdi mdi-axis-x-arrow-lock",
	"mdi mdi-axis-x-rotate-clockwise",
	"mdi mdi-axis-x-rotate-counterclockwise",
	"mdi mdi-axis-x-y-arrow-lock",
	"mdi mdi-axis-y-arrow",
	"mdi mdi-axis-y-arrow-lock",
	"mdi mdi-axis-y-rotate-clockwise",
	"mdi mdi-axis-y-rotate-counterclockwise",
	"mdi mdi-axis-z-arrow",
	"mdi mdi-axis-z-arrow-lock",
	"mdi mdi-axis-z-rotate-clockwise",
	"mdi mdi-axis-z-rotate-counterclockwise",
	"mdi mdi-babel",
	"mdi mdi-baby",
	"mdi mdi-baby-bottle",
	"mdi mdi-baby-bottle-outline",
	"mdi mdi-baby-buggy",
	"mdi mdi-baby-carriage",
	"mdi mdi-baby-carriage-off",
	"mdi mdi-baby-face",
	"mdi mdi-baby-face-outline",
	"mdi mdi-backburger",
	"mdi mdi-backspace",
	"mdi mdi-backspace-outline",
	"mdi mdi-backspace-reverse",
	"mdi mdi-backspace-reverse-outline",
	"mdi mdi-backup-restore",
	"mdi mdi-bacteria",
	"mdi mdi-bacteria-outline",
	"mdi mdi-badge-account",
	"mdi mdi-badge-account-alert",
	"mdi mdi-badge-account-alert-outline",
	"mdi mdi-badge-account-horizontal",
	"mdi mdi-badge-account-horizontal-outline",
	"mdi mdi-badge-account-outline",
	"mdi mdi-badminton",
	"mdi mdi-bag-carry-on",
	"mdi mdi-bag-carry-on-check",
	"mdi mdi-bag-carry-on-off",
	"mdi mdi-bag-checked",
	"mdi mdi-bag-personal",
	"mdi mdi-bag-personal-off",
	"mdi mdi-bag-personal-off-outline",
	"mdi mdi-bag-personal-outline",
	"mdi mdi-bag-suitcase",
	"mdi mdi-bag-suitcase-off",
	"mdi mdi-bag-suitcase-off-outline",
	"mdi mdi-bag-suitcase-outline",
	"mdi mdi-baguette",
	"mdi mdi-balloon",
	"mdi mdi-ballot",
	"mdi mdi-ballot-outline",
	"mdi mdi-ballot-recount",
	"mdi mdi-ballot-recount-outline",
	"mdi mdi-bandage",
	"mdi mdi-bandcamp",
	"mdi mdi-bank",
	"mdi mdi-bank-minus",
	"mdi mdi-bank-outline",
	"mdi mdi-bank-plus",
	"mdi mdi-bank-remove",
	"mdi mdi-bank-transfer",
	"mdi mdi-bank-transfer-in",
	"mdi mdi-bank-transfer-out",
	"mdi mdi-barcode",
	"mdi mdi-barcode-off",
	"mdi mdi-barcode-scan",
	"mdi mdi-barley",
	"mdi mdi-barley-off",
	"mdi mdi-barn",
	"mdi mdi-barrel",
	"mdi mdi-baseball",
	"mdi mdi-baseball-bat",
	"mdi mdi-bash",
	"mdi mdi-basket",
	"mdi mdi-basket-fill",
	"mdi mdi-basket-minus",
	"mdi mdi-basket-minus-outline",
	"mdi mdi-basket-off",
	"mdi mdi-basket-off-outline",
	"mdi mdi-basket-outline",
	"mdi mdi-basket-plus",
	"mdi mdi-basket-plus-outline",
	"mdi mdi-basket-remove",
	"mdi mdi-basket-remove-outline",
	"mdi mdi-basket-unfill",
	"mdi mdi-basketball",
	"mdi mdi-basketball-hoop",
	"mdi mdi-basketball-hoop-outline",
	"mdi mdi-bat",
	"mdi mdi-battery",
	"mdi mdi-battery-10",
	"mdi mdi-battery-10-bluetooth",
	"mdi mdi-battery-20",
	"mdi mdi-battery-20-bluetooth",
	"mdi mdi-battery-30",
	"mdi mdi-battery-30-bluetooth",
	"mdi mdi-battery-40",
	"mdi mdi-battery-40-bluetooth",
	"mdi mdi-battery-50",
	"mdi mdi-battery-50-bluetooth",
	"mdi mdi-battery-60",
	"mdi mdi-battery-60-bluetooth",
	"mdi mdi-battery-70",
	"mdi mdi-battery-70-bluetooth",
	"mdi mdi-battery-80",
	"mdi mdi-battery-80-bluetooth",
	"mdi mdi-battery-90",
	"mdi mdi-battery-90-bluetooth",
	"mdi mdi-battery-alert",
	"mdi mdi-battery-alert-bluetooth",
	"mdi mdi-battery-alert-variant",
	"mdi mdi-battery-alert-variant-outline",
	"mdi mdi-battery-bluetooth",
	"mdi mdi-battery-bluetooth-variant",
	"mdi mdi-battery-charging",
	"mdi mdi-battery-charging-10",
	"mdi mdi-battery-charging-100",
	"mdi mdi-battery-charging-20",
	"mdi mdi-battery-charging-30",
	"mdi mdi-battery-charging-40",
	"mdi mdi-battery-charging-50",
	"mdi mdi-battery-charging-60",
	"mdi mdi-battery-charging-70",
	"mdi mdi-battery-charging-80",
	"mdi mdi-battery-charging-90",
	"mdi mdi-battery-charging-high",
	"mdi mdi-battery-charging-low",
	"mdi mdi-battery-charging-medium",
	"mdi mdi-battery-charging-outline",
	"mdi mdi-battery-charging-wireless",
	"mdi mdi-battery-charging-wireless-10",
	"mdi mdi-battery-charging-wireless-20",
	"mdi mdi-battery-charging-wireless-30",
	"mdi mdi-battery-charging-wireless-40",
	"mdi mdi-battery-charging-wireless-50",
	"mdi mdi-battery-charging-wireless-60",
	"mdi mdi-battery-charging-wireless-70",
	"mdi mdi-battery-charging-wireless-80",
	"mdi mdi-battery-charging-wireless-90",
	"mdi mdi-battery-charging-wireless-alert",
	"mdi mdi-battery-charging-wireless-outline",
	"mdi mdi-battery-heart",
	"mdi mdi-battery-heart-outline",
	"mdi mdi-battery-heart-variant",
	"mdi mdi-battery-high",
	"mdi mdi-battery-low",
	"mdi mdi-battery-medium",
	"mdi mdi-battery-minus",
	"mdi mdi-battery-negative",
	"mdi mdi-battery-off",
	"mdi mdi-battery-off-outline",
	"mdi mdi-battery-outline",
	"mdi mdi-battery-plus",
	"mdi mdi-battery-positive",
	"mdi mdi-battery-unknown",
	"mdi mdi-battery-unknown-bluetooth",
	"mdi mdi-battlenet",
	"mdi mdi-beach",
	"mdi mdi-beaker",
	"mdi mdi-beaker-alert",
	"mdi mdi-beaker-alert-outline",
	"mdi mdi-beaker-check",
	"mdi mdi-beaker-check-outline",
	"mdi mdi-beaker-minus",
	"mdi mdi-beaker-minus-outline",
	"mdi mdi-beaker-outline",
	"mdi mdi-beaker-plus",
	"mdi mdi-beaker-plus-outline",
	"mdi mdi-beaker-question",
	"mdi mdi-beaker-question-outline",
	"mdi mdi-beaker-remove",
	"mdi mdi-beaker-remove-outline",
	"mdi mdi-bed",
	"mdi mdi-bed-double",
	"mdi mdi-bed-double-outline",
	"mdi mdi-bed-empty",
	"mdi mdi-bed-king",
	"mdi mdi-bed-king-outline",
	"mdi mdi-bed-outline",
	"mdi mdi-bed-queen",
	"mdi mdi-bed-queen-outline",
	"mdi mdi-bed-single",
	"mdi mdi-bed-single-outline",
	"mdi mdi-bee",
	"mdi mdi-bee-flower",
	"mdi mdi-beehive-off-outline",
	"mdi mdi-beehive-outline",
	"mdi mdi-beekeeper",
	"mdi mdi-beer",
	"mdi mdi-beer-outline",
	"mdi mdi-bell",
	"mdi mdi-bell-alert",
	"mdi mdi-bell-alert-outline",
	"mdi mdi-bell-cancel",
	"mdi mdi-bell-cancel-outline",
	"mdi mdi-bell-check",
	"mdi mdi-bell-check-outline",
	"mdi mdi-bell-circle",
	"mdi mdi-bell-circle-outline",
	"mdi mdi-bell-minus",
	"mdi mdi-bell-minus-outline",
	"mdi mdi-bell-off",
	"mdi mdi-bell-off-outline",
	"mdi mdi-bell-outline",
	"mdi mdi-bell-plus",
	"mdi mdi-bell-plus-outline",
	"mdi mdi-bell-remove",
	"mdi mdi-bell-remove-outline",
	"mdi mdi-bell-ring",
	"mdi mdi-bell-ring-outline",
	"mdi mdi-bell-sleep",
	"mdi mdi-bell-sleep-outline",
	"mdi mdi-beta",
	"mdi mdi-betamax",
	"mdi mdi-biathlon",
	"mdi mdi-bicycle",
	"mdi mdi-bicycle-basket",
	"mdi mdi-bike",
	"mdi mdi-bike-fast",
	"mdi mdi-billboard",
	"mdi mdi-billiards",
	"mdi mdi-billiards-rack",
	"mdi mdi-binoculars",
	"mdi mdi-bio",
	"mdi mdi-biohazard",
	"mdi mdi-bitbucket",
	"mdi mdi-bitcoin",
	"mdi mdi-black-mesa",
	"mdi mdi-blender",
	"mdi mdi-blender-software",
	"mdi mdi-blinds",
	"mdi mdi-blinds-open",
	"mdi mdi-block-helper",
	"mdi mdi-blogger",
	"mdi mdi-blood-bag",
	"mdi mdi-bluetooth",
	"mdi mdi-bluetooth-audio",
	"mdi mdi-bluetooth-connect",
	"mdi mdi-bluetooth-off",
	"mdi mdi-bluetooth-settings",
	"mdi mdi-bluetooth-transfer",
	"mdi mdi-blur",
	"mdi mdi-blur-linear",
	"mdi mdi-blur-off",
	"mdi mdi-blur-radial",
	"mdi mdi-bolnisi-cross",
	"mdi mdi-bolt",
	"mdi mdi-bomb",
	"mdi mdi-bomb-off",
	"mdi mdi-bone",
	"mdi mdi-book",
	"mdi mdi-book-account",
	"mdi mdi-book-account-outline",
	"mdi mdi-book-alphabet",
	"mdi mdi-book-check",
	"mdi mdi-book-check-outline",
	"mdi mdi-book-cross",
	"mdi mdi-book-information-variant",
	"mdi mdi-book-lock",
	"mdi mdi-book-lock-open",
	"mdi mdi-book-minus",
	"mdi mdi-book-minus-multiple",
	"mdi mdi-book-minus-multiple-outline",
	"mdi mdi-book-multiple",
	"mdi mdi-book-multiple-outline",
	"mdi mdi-book-music",
	"mdi mdi-book-open",
	"mdi mdi-book-open-blank-variant",
	"mdi mdi-book-open-outline",
	"mdi mdi-book-open-page-variant",
	"mdi mdi-book-open-variant",
	"mdi mdi-book-outline",
	"mdi mdi-book-play",
	"mdi mdi-book-play-outline",
	"mdi mdi-book-plus",
	"mdi mdi-book-plus-multiple",
	"mdi mdi-book-plus-multiple-outline",
	"mdi mdi-book-remove",
	"mdi mdi-book-remove-multiple",
	"mdi mdi-book-remove-multiple-outline",
	"mdi mdi-book-search",
	"mdi mdi-book-search-outline",
	"mdi mdi-book-variant",
	"mdi mdi-book-variant-multiple",
	"mdi mdi-bookmark",
	"mdi mdi-bookmark-check",
	"mdi mdi-bookmark-check-outline",
	"mdi mdi-bookmark-minus",
	"mdi mdi-bookmark-minus-outline",
	"mdi mdi-bookmark-multiple",
	"mdi mdi-bookmark-multiple-outline",
	"mdi mdi-bookmark-music",
	"mdi mdi-bookmark-music-outline",
	"mdi mdi-bookmark-off",
	"mdi mdi-bookmark-off-outline",
	"mdi mdi-bookmark-outline",
	"mdi mdi-bookmark-plus",
	"mdi mdi-bookmark-plus-outline",
	"mdi mdi-bookmark-remove",
	"mdi mdi-bookmark-remove-outline",
	"mdi mdi-bookshelf",
	"mdi mdi-boom-gate",
	"mdi mdi-boom-gate-alert",
	"mdi mdi-boom-gate-alert-outline",
	"mdi mdi-boom-gate-down",
	"mdi mdi-boom-gate-down-outline",
	"mdi mdi-boom-gate-outline",
	"mdi mdi-boom-gate-up",
	"mdi mdi-boom-gate-up-outline",
	"mdi mdi-boombox",
	"mdi mdi-boomerang",
	"mdi mdi-bootstrap",
	"mdi mdi-border-all",
	"mdi mdi-border-all-variant",
	"mdi mdi-border-bottom",
	"mdi mdi-border-bottom-variant",
	"mdi mdi-border-color",
	"mdi mdi-border-horizontal",
	"mdi mdi-border-inside",
	"mdi mdi-border-left",
	"mdi mdi-border-left-variant",
	"mdi mdi-border-none",
	"mdi mdi-border-none-variant",
	"mdi mdi-border-outside",
	"mdi mdi-border-right",
	"mdi mdi-border-right-variant",
	"mdi mdi-border-style",
	"mdi mdi-border-top",
	"mdi mdi-border-top-variant",
	"mdi mdi-border-vertical",
	"mdi mdi-bottle-soda",
	"mdi mdi-bottle-soda-classic",
	"mdi mdi-bottle-soda-classic-outline",
	"mdi mdi-bottle-soda-outline",
	"mdi mdi-bottle-tonic",
	"mdi mdi-bottle-tonic-outline",
	"mdi mdi-bottle-tonic-plus",
	"mdi mdi-bottle-tonic-plus-outline",
	"mdi mdi-bottle-tonic-skull",
	"mdi mdi-bottle-tonic-skull-outline",
	"mdi mdi-bottle-wine",
	"mdi mdi-bottle-wine-outline",
	"mdi mdi-bow-tie",
	"mdi mdi-bowl",
	"mdi mdi-bowl-mix",
	"mdi mdi-bowl-mix-outline",
	"mdi mdi-bowl-outline",
	"mdi mdi-bowling",
	"mdi mdi-box",
	"mdi mdi-box-cutter",
	"mdi mdi-box-cutter-off",
	"mdi mdi-box-shadow",
	"mdi mdi-boxing-glove",
	"mdi mdi-braille",
	"mdi mdi-brain",
	"mdi mdi-bread-slice",
	"mdi mdi-bread-slice-outline",
	"mdi mdi-bridge",
	"mdi mdi-briefcase",
	"mdi mdi-briefcase-account",
	"mdi mdi-briefcase-account-outline",
	"mdi mdi-briefcase-check",
	"mdi mdi-briefcase-check-outline",
	"mdi mdi-briefcase-clock",
	"mdi mdi-briefcase-clock-outline",
	"mdi mdi-briefcase-download",
	"mdi mdi-briefcase-download-outline",
	"mdi mdi-briefcase-edit",
	"mdi mdi-briefcase-edit-outline",
	"mdi mdi-briefcase-minus",
	"mdi mdi-briefcase-minus-outline",
	"mdi mdi-briefcase-outline",
	"mdi mdi-briefcase-plus",
	"mdi mdi-briefcase-plus-outline",
	"mdi mdi-briefcase-remove",
	"mdi mdi-briefcase-remove-outline",
	"mdi mdi-briefcase-search",
	"mdi mdi-briefcase-search-outline",
	"mdi mdi-briefcase-upload",
	"mdi mdi-briefcase-upload-outline",
	"mdi mdi-briefcase-variant",
	"mdi mdi-briefcase-variant-outline",
	"mdi mdi-brightness-1",
	"mdi mdi-brightness-2",
	"mdi mdi-brightness-3",
	"mdi mdi-brightness-4",
	"mdi mdi-brightness-5",
	"mdi mdi-brightness-6",
	"mdi mdi-brightness-7",
	"mdi mdi-brightness-auto",
	"mdi mdi-brightness-percent",
	"mdi mdi-broom",
	"mdi mdi-brush",
	"mdi mdi-bucket",
	"mdi mdi-bucket-outline",
	"mdi mdi-buddhism",
	"mdi mdi-buffer",
	"mdi mdi-buffet",
	"mdi mdi-bug",
	"mdi mdi-bug-check",
	"mdi mdi-bug-check-outline",
	"mdi mdi-bug-outline",
	"mdi mdi-bugle",
	"mdi mdi-bulldozer",
	"mdi mdi-bullet",
	"mdi mdi-bulletin-board",
	"mdi mdi-bullhorn",
	"mdi mdi-bullhorn-outline",
	"mdi mdi-bullseye",
	"mdi mdi-bullseye-arrow",
	"mdi mdi-bulma",
	"mdi mdi-bunk-bed",
	"mdi mdi-bunk-bed-outline",
	"mdi mdi-bus",
	"mdi mdi-bus-alert",
	"mdi mdi-bus-articulated-end",
	"mdi mdi-bus-articulated-front",
	"mdi mdi-bus-clock",
	"mdi mdi-bus-double-decker",
	"mdi mdi-bus-marker",
	"mdi mdi-bus-multiple",
	"mdi mdi-bus-school",
	"mdi mdi-bus-side",
	"mdi mdi-bus-stop",
	"mdi mdi-bus-stop-covered",
	"mdi mdi-bus-stop-uncovered",
	"mdi mdi-butterfly",
	"mdi mdi-butterfly-outline",
	"mdi mdi-cable-data",
	"mdi mdi-cached",
	"mdi mdi-cactus",
	"mdi mdi-cake",
	"mdi mdi-cake-layered",
	"mdi mdi-cake-variant",
	"mdi mdi-calculator",
	"mdi mdi-calculator-variant",
	"mdi mdi-calculator-variant-outline",
	"mdi mdi-calendar",
	"mdi mdi-calendar-account",
	"mdi mdi-calendar-account-outline",
	"mdi mdi-calendar-alert",
	"mdi mdi-calendar-arrow-left",
	"mdi mdi-calendar-arrow-right",
	"mdi mdi-calendar-blank",
	"mdi mdi-calendar-blank-multiple",
	"mdi mdi-calendar-blank-outline",
	"mdi mdi-calendar-check",
	"mdi mdi-calendar-check-outline",
	"mdi mdi-calendar-clock",
	"mdi mdi-calendar-cursor",
	"mdi mdi-calendar-edit",
	"mdi mdi-calendar-export",
	"mdi mdi-calendar-heart",
	"mdi mdi-calendar-import",
	"mdi mdi-calendar-minus",
	"mdi mdi-calendar-month",
	"mdi mdi-calendar-month-outline",
	"mdi mdi-calendar-multiple",
	"mdi mdi-calendar-multiple-check",
	"mdi mdi-calendar-multiselect",
	"mdi mdi-calendar-outline",
	"mdi mdi-calendar-plus",
	"mdi mdi-calendar-question",
	"mdi mdi-calendar-range",
	"mdi mdi-calendar-range-outline",
	"mdi mdi-calendar-refresh",
	"mdi mdi-calendar-refresh-outline",
	"mdi mdi-calendar-remove",
	"mdi mdi-calendar-remove-outline",
	"mdi mdi-calendar-search",
	"mdi mdi-calendar-star",
	"mdi mdi-calendar-sync",
	"mdi mdi-calendar-sync-outline",
	"mdi mdi-calendar-text",
	"mdi mdi-calendar-text-outline",
	"mdi mdi-calendar-today",
	"mdi mdi-calendar-week",
	"mdi mdi-calendar-week-begin",
	"mdi mdi-calendar-weekend",
	"mdi mdi-calendar-weekend-outline",
	"mdi mdi-call-made",
	"mdi mdi-call-merge",
	"mdi mdi-call-missed",
	"mdi mdi-call-received",
	"mdi mdi-call-split",
	"mdi mdi-camcorder",
	"mdi mdi-camcorder-off",
	"mdi mdi-camera",
	"mdi mdi-camera-account",
	"mdi mdi-camera-burst",
	"mdi mdi-camera-control",
	"mdi mdi-camera-enhance",
	"mdi mdi-camera-enhance-outline",
	"mdi mdi-camera-front",
	"mdi mdi-camera-front-variant",
	"mdi mdi-camera-gopro",
	"mdi mdi-camera-image",
	"mdi mdi-camera-iris",
	"mdi mdi-camera-metering-center",
	"mdi mdi-camera-metering-matrix",
	"mdi mdi-camera-metering-partial",
	"mdi mdi-camera-metering-spot",
	"mdi mdi-camera-off",
	"mdi mdi-camera-outline",
	"mdi mdi-camera-party-mode",
	"mdi mdi-camera-plus",
	"mdi mdi-camera-plus-outline",
	"mdi mdi-camera-rear",
	"mdi mdi-camera-rear-variant",
	"mdi mdi-camera-retake",
	"mdi mdi-camera-retake-outline",
	"mdi mdi-camera-switch",
	"mdi mdi-camera-switch-outline",
	"mdi mdi-camera-timer",
	"mdi mdi-camera-wireless",
	"mdi mdi-camera-wireless-outline",
	"mdi mdi-campfire",
	"mdi mdi-cancel",
	"mdi mdi-candle",
	"mdi mdi-candycane",
	"mdi mdi-cannabis",
	"mdi mdi-caps-lock",
	"mdi mdi-car",
	"mdi mdi-car-2-plus",
	"mdi mdi-car-3-plus",
	"mdi mdi-car-arrow-left",
	"mdi mdi-car-arrow-right",
	"mdi mdi-car-back",
	"mdi mdi-car-battery",
	"mdi mdi-car-brake-abs",
	"mdi mdi-car-brake-alert",
	"mdi mdi-car-brake-hold",
	"mdi mdi-car-brake-parking",
	"mdi mdi-car-brake-retarder",
	"mdi mdi-car-child-seat",
	"mdi mdi-car-clutch",
	"mdi mdi-car-cog",
	"mdi mdi-car-connected",
	"mdi mdi-car-convertible",
	"mdi mdi-car-coolant-level",
	"mdi mdi-car-cruise-control",
	"mdi mdi-car-defrost-front",
	"mdi mdi-car-defrost-rear",
	"mdi mdi-car-door",
	"mdi mdi-car-door-lock",
	"mdi mdi-car-electric",
	"mdi mdi-car-esp",
	"mdi mdi-car-estate",
	"mdi mdi-car-hatchback",
	"mdi mdi-car-info",
	"mdi mdi-car-key",
	"mdi mdi-car-lifted-pickup",
	"mdi mdi-car-light-dimmed",
	"mdi mdi-car-light-fog",
	"mdi mdi-car-light-high",
	"mdi mdi-car-limousine",
	"mdi mdi-car-multiple",
	"mdi mdi-car-off",
	"mdi mdi-car-outline",
	"mdi mdi-car-parking-lights",
	"mdi mdi-car-pickup",
	"mdi mdi-car-seat",
	"mdi mdi-car-seat-cooler",
	"mdi mdi-car-seat-heater",
	"mdi mdi-car-settings",
	"mdi mdi-car-shift-pattern",
	"mdi mdi-car-side",
	"mdi mdi-car-sports",
	"mdi mdi-car-tire-alert",
	"mdi mdi-car-traction-control",
	"mdi mdi-car-turbocharger",
	"mdi mdi-car-wash",
	"mdi mdi-car-windshield",
	"mdi mdi-car-windshield-outline",
	"mdi mdi-carabiner",
	"mdi mdi-caravan",
	"mdi mdi-card",
	"mdi mdi-card-account-details",
	"mdi mdi-card-account-details-outline",
	"mdi mdi-card-account-details-star",
	"mdi mdi-card-account-details-star-outline",
	"mdi mdi-card-account-mail",
	"mdi mdi-card-account-mail-outline",
	"mdi mdi-card-account-phone",
	"mdi mdi-card-account-phone-outline",
	"mdi mdi-card-bulleted",
	"mdi mdi-card-bulleted-off",
	"mdi mdi-card-bulleted-off-outline",
	"mdi mdi-card-bulleted-outline",
	"mdi mdi-card-bulleted-settings",
	"mdi mdi-card-bulleted-settings-outline",
	"mdi mdi-card-outline",
	"mdi mdi-card-plus",
	"mdi mdi-card-plus-outline",
	"mdi mdi-card-search",
	"mdi mdi-card-search-outline",
	"mdi mdi-card-text",
	"mdi mdi-card-text-outline",
	"mdi mdi-cards",
	"mdi mdi-cards-club",
	"mdi mdi-cards-diamond",
	"mdi mdi-cards-diamond-outline",
	"mdi mdi-cards-heart",
	"mdi mdi-cards-outline",
	"mdi mdi-cards-playing-outline",
	"mdi mdi-cards-spade",
	"mdi mdi-cards-variant",
	"mdi mdi-carrot",
	"mdi mdi-cart",
	"mdi mdi-cart-arrow-down",
	"mdi mdi-cart-arrow-right",
	"mdi mdi-cart-arrow-up",
	"mdi mdi-cart-minus",
	"mdi mdi-cart-off",
	"mdi mdi-cart-outline",
	"mdi mdi-cart-plus",
	"mdi mdi-cart-remove",
	"mdi mdi-case-sensitive-alt",
	"mdi mdi-cash",
	"mdi mdi-cash-100",
	"mdi mdi-cash-check",
	"mdi mdi-cash-lock",
	"mdi mdi-cash-lock-open",
	"mdi mdi-cash-marker",
	"mdi mdi-cash-minus",
	"mdi mdi-cash-multiple",
	"mdi mdi-cash-plus",
	"mdi mdi-cash-refund",
	"mdi mdi-cash-register",
	"mdi mdi-cash-remove",
	"mdi mdi-cash-usd",
	"mdi mdi-cash-usd-outline",
	"mdi mdi-cassette",
	"mdi mdi-cast",
	"mdi mdi-cast-audio",
	"mdi mdi-cast-connected",
	"mdi mdi-cast-education",
	"mdi mdi-cast-off",
	"mdi mdi-castle",
	"mdi mdi-cat",
	"mdi mdi-cctv",
	"mdi mdi-ceiling-light",
	"mdi mdi-cellphone",
	"mdi mdi-cellphone-android",
	"mdi mdi-cellphone-arrow-down",
	"mdi mdi-cellphone-basic",
	"mdi mdi-cellphone-charging",
	"mdi mdi-cellphone-cog",
	"mdi mdi-cellphone-dock",
	"mdi mdi-cellphone-erase",
	"mdi mdi-cellphone-information",
	"mdi mdi-cellphone-iphone",
	"mdi mdi-cellphone-key",
	"mdi mdi-cellphone-link",
	"mdi mdi-cellphone-link-off",
	"mdi mdi-cellphone-lock",
	"mdi mdi-cellphone-message",
	"mdi mdi-cellphone-message-off",
	"mdi mdi-cellphone-nfc",
	"mdi mdi-cellphone-nfc-off",
	"mdi mdi-cellphone-off",
	"mdi mdi-cellphone-play",
	"mdi mdi-cellphone-screenshot",
	"mdi mdi-cellphone-settings",
	"mdi mdi-cellphone-sound",
	"mdi mdi-cellphone-text",
	"mdi mdi-cellphone-wireless",
	"mdi mdi-celtic-cross",
	"mdi mdi-centos",
	"mdi mdi-certificate",
	"mdi mdi-certificate-outline",
	"mdi mdi-chair-rolling",
	"mdi mdi-chair-school",
	"mdi mdi-charity",
	"mdi mdi-chart-arc",
	"mdi mdi-chart-areaspline",
	"mdi mdi-chart-areaspline-variant",
	"mdi mdi-chart-bar",
	"mdi mdi-chart-bar-stacked",
	"mdi mdi-chart-bell-curve",
	"mdi mdi-chart-bell-curve-cumulative",
	"mdi mdi-chart-box",
	"mdi mdi-chart-box-outline",
	"mdi mdi-chart-box-plus-outline",
	"mdi mdi-chart-bubble",
	"mdi mdi-chart-donut",
	"mdi mdi-chart-donut-variant",
	"mdi mdi-chart-gantt",
	"mdi mdi-chart-histogram",
	"mdi mdi-chart-line",
	"mdi mdi-chart-line-stacked",
	"mdi mdi-chart-line-variant",
	"mdi mdi-chart-multiline",
	"mdi mdi-chart-multiple",
	"mdi mdi-chart-pie",
	"mdi mdi-chart-ppf",
	"mdi mdi-chart-sankey",
	"mdi mdi-chart-sankey-variant",
	"mdi mdi-chart-scatter-plot",
	"mdi mdi-chart-scatter-plot-hexbin",
	"mdi mdi-chart-timeline",
	"mdi mdi-chart-timeline-variant",
	"mdi mdi-chart-tree",
	"mdi mdi-chat",
	"mdi mdi-chat-alert",
	"mdi mdi-chat-alert-outline",
	"mdi mdi-chat-minus",
	"mdi mdi-chat-minus-outline",
	"mdi mdi-chat-outline",
	"mdi mdi-chat-plus",
	"mdi mdi-chat-plus-outline",
	"mdi mdi-chat-processing",
	"mdi mdi-chat-processing-outline",
	"mdi mdi-chat-remove",
	"mdi mdi-chat-remove-outline",
	"mdi mdi-chat-sleep",
	"mdi mdi-chat-sleep-outline",
	"mdi mdi-check",
	"mdi mdi-check-all",
	"mdi mdi-check-bold",
	"mdi mdi-check-box-multiple-outline",
	"mdi mdi-check-box-outline",
	"mdi mdi-check-circle",
	"mdi mdi-check-circle-outline",
	"mdi mdi-check-decagram",
	"mdi mdi-check-network",
	"mdi mdi-check-network-outline",
	"mdi mdi-check-outline",
	"mdi mdi-check-underline",
	"mdi mdi-check-underline-circle",
	"mdi mdi-check-underline-circle-outline",
	"mdi mdi-checkbook",
	"mdi mdi-checkbox-blank",
	"mdi mdi-checkbox-blank-circle",
	"mdi mdi-checkbox-blank-circle-outline",
	"mdi mdi-checkbox-blank-off",
	"mdi mdi-checkbox-blank-off-outline",
	"mdi mdi-checkbox-blank-outline",
	"mdi mdi-checkbox-intermediate",
	"mdi mdi-checkbox-marked",
	"mdi mdi-checkbox-marked-circle",
	"mdi mdi-checkbox-marked-circle-outline",
	"mdi mdi-checkbox-marked-outline",
	"mdi mdi-checkbox-multiple-blank",
	"mdi mdi-checkbox-multiple-blank-circle",
	"mdi mdi-checkbox-multiple-blank-circle-outline",
	"mdi mdi-checkbox-multiple-blank-outline",
	"mdi mdi-checkbox-multiple-marked",
	"mdi mdi-checkbox-multiple-marked-circle",
	"mdi mdi-checkbox-multiple-marked-circle-outline",
	"mdi mdi-checkbox-multiple-marked-outline",
	"mdi mdi-checkerboard",
	"mdi mdi-checkerboard-minus",
	"mdi mdi-checkerboard-plus",
	"mdi mdi-checkerboard-remove",
	"mdi mdi-cheese",
	"mdi mdi-cheese-off",
	"mdi mdi-chef-hat",
	"mdi mdi-chemical-weapon",
	"mdi mdi-chess-bishop",
	"mdi mdi-chess-king",
	"mdi mdi-chess-knight",
	"mdi mdi-chess-pawn",
	"mdi mdi-chess-queen",
	"mdi mdi-chess-rook",
	"mdi mdi-chevron-double-down",
	"mdi mdi-chevron-double-left",
	"mdi mdi-chevron-double-right",
	"mdi mdi-chevron-double-up",
	"mdi mdi-chevron-down",
	"mdi mdi-chevron-down-box",
	"mdi mdi-chevron-down-box-outline",
	"mdi mdi-chevron-down-circle",
	"mdi mdi-chevron-down-circle-outline",
	"mdi mdi-chevron-left",
	"mdi mdi-chevron-left-box",
	"mdi mdi-chevron-left-box-outline",
	"mdi mdi-chevron-left-circle",
	"mdi mdi-chevron-left-circle-outline",
	"mdi mdi-chevron-right",
	"mdi mdi-chevron-right-box",
	"mdi mdi-chevron-right-box-outline",
	"mdi mdi-chevron-right-circle",
	"mdi mdi-chevron-right-circle-outline",
	"mdi mdi-chevron-triple-down",
	"mdi mdi-chevron-triple-left",
	"mdi mdi-chevron-triple-right",
	"mdi mdi-chevron-triple-up",
	"mdi mdi-chevron-up",
	"mdi mdi-chevron-up-box",
	"mdi mdi-chevron-up-box-outline",
	"mdi mdi-chevron-up-circle",
	"mdi mdi-chevron-up-circle-outline",
	"mdi mdi-chili-hot",
	"mdi mdi-chili-medium",
	"mdi mdi-chili-mild",
	"mdi mdi-chili-off",
	"mdi mdi-chip",
	"mdi mdi-christianity",
	"mdi mdi-christianity-outline",
	"mdi mdi-church",
	"mdi mdi-cigar",
	"mdi mdi-cigar-off",
	"mdi mdi-circle",
	"mdi mdi-circle-double",
	"mdi mdi-circle-edit-outline",
	"mdi mdi-circle-expand",
	"mdi mdi-circle-half",
	"mdi mdi-circle-half-full",
	"mdi mdi-circle-medium",
	"mdi mdi-circle-multiple",
	"mdi mdi-circle-multiple-outline",
	"mdi mdi-circle-off-outline",
	"mdi mdi-circle-outline",
	"mdi mdi-circle-slice-1",
	"mdi mdi-circle-slice-2",
	"mdi mdi-circle-slice-3",
	"mdi mdi-circle-slice-4",
	"mdi mdi-circle-slice-5",
	"mdi mdi-circle-slice-6",
	"mdi mdi-circle-slice-7",
	"mdi mdi-circle-slice-8",
	"mdi mdi-circle-small",
	"mdi mdi-circular-saw",
	"mdi mdi-city",
	"mdi mdi-city-variant",
	"mdi mdi-city-variant-outline",
	"mdi mdi-clipboard",
	"mdi mdi-clipboard-account",
	"mdi mdi-clipboard-account-outline",
	"mdi mdi-clipboard-alert",
	"mdi mdi-clipboard-alert-outline",
	"mdi mdi-clipboard-arrow-down",
	"mdi mdi-clipboard-arrow-down-outline",
	"mdi mdi-clipboard-arrow-left",
	"mdi mdi-clipboard-arrow-left-outline",
	"mdi mdi-clipboard-arrow-right",
	"mdi mdi-clipboard-arrow-right-outline",
	"mdi mdi-clipboard-arrow-up",
	"mdi mdi-clipboard-arrow-up-outline",
	"mdi mdi-clipboard-check",
	"mdi mdi-clipboard-check-multiple",
	"mdi mdi-clipboard-check-multiple-outline",
	"mdi mdi-clipboard-check-outline",
	"mdi mdi-clipboard-edit",
	"mdi mdi-clipboard-edit-outline",
	"mdi mdi-clipboard-file",
	"mdi mdi-clipboard-file-outline",
	"mdi mdi-clipboard-flow",
	"mdi mdi-clipboard-flow-outline",
	"mdi mdi-clipboard-list",
	"mdi mdi-clipboard-list-outline",
	"mdi mdi-clipboard-multiple",
	"mdi mdi-clipboard-multiple-outline",
	"mdi mdi-clipboard-outline",
	"mdi mdi-clipboard-play",
	"mdi mdi-clipboard-play-multiple",
	"mdi mdi-clipboard-play-multiple-outline",
	"mdi mdi-clipboard-play-outline",
	"mdi mdi-clipboard-plus",
	"mdi mdi-clipboard-plus-outline",
	"mdi mdi-clipboard-pulse",
	"mdi mdi-clipboard-pulse-outline",
	"mdi mdi-clipboard-text",
	"mdi mdi-clipboard-text-multiple",
	"mdi mdi-clipboard-text-multiple-outline",
	"mdi mdi-clipboard-text-outline",
	"mdi mdi-clipboard-text-play",
	"mdi mdi-clipboard-text-play-outline",
	"mdi mdi-clippy",
	"mdi mdi-clock",
	"mdi mdi-clock-alert",
	"mdi mdi-clock-alert-outline",
	"mdi mdi-clock-check",
	"mdi mdi-clock-check-outline",
	"mdi mdi-clock-digital",
	"mdi mdi-clock-end",
	"mdi mdi-clock-fast",
	"mdi mdi-clock-in",
	"mdi mdi-clock-out",
	"mdi mdi-clock-outline",
	"mdi mdi-clock-start",
	"mdi mdi-clock-time-eight",
	"mdi mdi-clock-time-eight-outline",
	"mdi mdi-clock-time-eleven",
	"mdi mdi-clock-time-eleven-outline",
	"mdi mdi-clock-time-five",
	"mdi mdi-clock-time-five-outline",
	"mdi mdi-clock-time-four",
	"mdi mdi-clock-time-four-outline",
	"mdi mdi-clock-time-nine",
	"mdi mdi-clock-time-nine-outline",
	"mdi mdi-clock-time-one",
	"mdi mdi-clock-time-one-outline",
	"mdi mdi-clock-time-seven",
	"mdi mdi-clock-time-seven-outline",
	"mdi mdi-clock-time-six",
	"mdi mdi-clock-time-six-outline",
	"mdi mdi-clock-time-ten",
	"mdi mdi-clock-time-ten-outline",
	"mdi mdi-clock-time-three",
	"mdi mdi-clock-time-three-outline",
	"mdi mdi-clock-time-twelve",
	"mdi mdi-clock-time-twelve-outline",
	"mdi mdi-clock-time-two",
	"mdi mdi-clock-time-two-outline",
	"mdi mdi-close",
	"mdi mdi-close-box",
	"mdi mdi-close-box-multiple",
	"mdi mdi-close-box-multiple-outline",
	"mdi mdi-close-box-outline",
	"mdi mdi-close-circle",
	"mdi mdi-close-circle-multiple",
	"mdi mdi-close-circle-multiple-outline",
	"mdi mdi-close-circle-outline",
	"mdi mdi-close-network",
	"mdi mdi-close-network-outline",
	"mdi mdi-close-octagon",
	"mdi mdi-close-octagon-outline",
	"mdi mdi-close-outline",
	"mdi mdi-close-thick",
	"mdi mdi-closed-caption",
	"mdi mdi-closed-caption-outline",
	"mdi mdi-cloud",
	"mdi mdi-cloud-alert",
	"mdi mdi-cloud-braces",
	"mdi mdi-cloud-check",
	"mdi mdi-cloud-check-outline",
	"mdi mdi-cloud-circle",
	"mdi mdi-cloud-download",
	"mdi mdi-cloud-download-outline",
	"mdi mdi-cloud-lock",
	"mdi mdi-cloud-lock-outline",
	"mdi mdi-cloud-off-outline",
	"mdi mdi-cloud-outline",
	"mdi mdi-cloud-print",
	"mdi mdi-cloud-print-outline",
	"mdi mdi-cloud-question",
	"mdi mdi-cloud-refresh",
	"mdi mdi-cloud-search",
	"mdi mdi-cloud-search-outline",
	"mdi mdi-cloud-sync",
	"mdi mdi-cloud-sync-outline",
	"mdi mdi-cloud-tags",
	"mdi mdi-cloud-upload",
	"mdi mdi-cloud-upload-outline",
	"mdi mdi-clover",
	"mdi mdi-coach-lamp",
	"mdi mdi-coat-rack",
	"mdi mdi-code-array",
	"mdi mdi-code-braces",
	"mdi mdi-code-braces-box",
	"mdi mdi-code-brackets",
	"mdi mdi-code-equal",
	"mdi mdi-code-greater-than",
	"mdi mdi-code-greater-than-or-equal",
	"mdi mdi-code-json",
	"mdi mdi-code-less-than",
	"mdi mdi-code-less-than-or-equal",
	"mdi mdi-code-not-equal",
	"mdi mdi-code-not-equal-variant",
	"mdi mdi-code-parentheses",
	"mdi mdi-code-parentheses-box",
	"mdi mdi-code-string",
	"mdi mdi-code-tags",
	"mdi mdi-code-tags-check",
	"mdi mdi-codepen",
	"mdi mdi-coffee",
	"mdi mdi-coffee-maker",
	"mdi mdi-coffee-off",
	"mdi mdi-coffee-off-outline",
	"mdi mdi-coffee-outline",
	"mdi mdi-coffee-to-go",
	"mdi mdi-coffee-to-go-outline",
	"mdi mdi-coffin",
	"mdi mdi-cog",
	"mdi mdi-cog-box",
	"mdi mdi-cog-clockwise",
	"mdi mdi-cog-counterclockwise",
	"mdi mdi-cog-off",
	"mdi mdi-cog-off-outline",
	"mdi mdi-cog-outline",
	"mdi mdi-cog-refresh",
	"mdi mdi-cog-refresh-outline",
	"mdi mdi-cog-sync",
	"mdi mdi-cog-sync-outline",
	"mdi mdi-cog-transfer",
	"mdi mdi-cog-transfer-outline",
	"mdi mdi-cogs",
	"mdi mdi-collage",
	"mdi mdi-collapse-all",
	"mdi mdi-collapse-all-outline",
	"mdi mdi-color-helper",
	"mdi mdi-comma",
	"mdi mdi-comma-box",
	"mdi mdi-comma-box-outline",
	"mdi mdi-comma-circle",
	"mdi mdi-comma-circle-outline",
	"mdi mdi-comment",
	"mdi mdi-comment-account",
	"mdi mdi-comment-account-outline",
	"mdi mdi-comment-alert",
	"mdi mdi-comment-alert-outline",
	"mdi mdi-comment-arrow-left",
	"mdi mdi-comment-arrow-left-outline",
	"mdi mdi-comment-arrow-right",
	"mdi mdi-comment-arrow-right-outline",
	"mdi mdi-comment-bookmark",
	"mdi mdi-comment-bookmark-outline",
	"mdi mdi-comment-check",
	"mdi mdi-comment-check-outline",
	"mdi mdi-comment-edit",
	"mdi mdi-comment-edit-outline",
	"mdi mdi-comment-eye",
	"mdi mdi-comment-eye-outline",
	"mdi mdi-comment-flash",
	"mdi mdi-comment-flash-outline",
	"mdi mdi-comment-multiple",
	"mdi mdi-comment-multiple-outline",
	"mdi mdi-comment-outline",
	"mdi mdi-comment-plus",
	"mdi mdi-comment-plus-outline",
	"mdi mdi-comment-processing",
	"mdi mdi-comment-processing-outline",
	"mdi mdi-comment-question",
	"mdi mdi-comment-question-outline",
	"mdi mdi-comment-quote",
	"mdi mdi-comment-quote-outline",
	"mdi mdi-comment-remove",
	"mdi mdi-comment-remove-outline",
	"mdi mdi-comment-search",
	"mdi mdi-comment-search-outline",
	"mdi mdi-comment-text",
	"mdi mdi-comment-text-multiple",
	"mdi mdi-comment-text-multiple-outline",
	"mdi mdi-comment-text-outline",
	"mdi mdi-compare",
	"mdi mdi-compare-horizontal",
	"mdi mdi-compare-vertical",
	"mdi mdi-compass",
	"mdi mdi-compass-off",
	"mdi mdi-compass-off-outline",
	"mdi mdi-compass-outline",
	"mdi mdi-compass-rose",
	"mdi mdi-concourse-ci",
	"mdi mdi-console",
	"mdi mdi-console-line",
	"mdi mdi-console-network",
	"mdi mdi-console-network-outline",
	"mdi mdi-consolidate",
	"mdi mdi-contactless-payment",
	"mdi mdi-contactless-payment-circle",
	"mdi mdi-contactless-payment-circle-outline",
	"mdi mdi-contacts",
	"mdi mdi-contacts-outline",
	"mdi mdi-contain",
	"mdi mdi-contain-end",
	"mdi mdi-contain-start",
	"mdi mdi-content-copy",
	"mdi mdi-content-cut",
	"mdi mdi-content-duplicate",
	"mdi mdi-content-paste",
	"mdi mdi-content-save",
	"mdi mdi-content-save-alert",
	"mdi mdi-content-save-alert-outline",
	"mdi mdi-content-save-all",
	"mdi mdi-content-save-all-outline",
	"mdi mdi-content-save-cog",
	"mdi mdi-content-save-cog-outline",
	"mdi mdi-content-save-edit",
	"mdi mdi-content-save-edit-outline",
	"mdi mdi-content-save-move",
	"mdi mdi-content-save-move-outline",
	"mdi mdi-content-save-outline",
	"mdi mdi-content-save-settings",
	"mdi mdi-content-save-settings-outline",
	"mdi mdi-contrast",
	"mdi mdi-contrast-box",
	"mdi mdi-contrast-circle",
	"mdi mdi-controller-classic",
	"mdi mdi-controller-classic-outline",
	"mdi mdi-cookie",
	"mdi mdi-coolant-temperature",
	"mdi mdi-copyright",
	"mdi mdi-cordova",
	"mdi mdi-corn",
	"mdi mdi-corn-off",
	"mdi mdi-cosine-wave",
	"mdi mdi-counter",
	"mdi mdi-cow",
	"mdi mdi-cpu-32-bit",
	"mdi mdi-cpu-64-bit",
	"mdi mdi-crane",
	"mdi mdi-creation",
	"mdi mdi-creative-commons",
	"mdi mdi-credit-card",
	"mdi mdi-credit-card-check",
	"mdi mdi-credit-card-check-outline",
	"mdi mdi-credit-card-clock",
	"mdi mdi-credit-card-clock-outline",
	"mdi mdi-credit-card-marker",
	"mdi mdi-credit-card-marker-outline",
	"mdi mdi-credit-card-minus",
	"mdi mdi-credit-card-minus-outline",
	"mdi mdi-credit-card-multiple",
	"mdi mdi-credit-card-multiple-outline",
	"mdi mdi-credit-card-off",
	"mdi mdi-credit-card-off-outline",
	"mdi mdi-credit-card-outline",
	"mdi mdi-credit-card-plus",
	"mdi mdi-credit-card-plus-outline",
	"mdi mdi-credit-card-refund",
	"mdi mdi-credit-card-refund-outline",
	"mdi mdi-credit-card-remove",
	"mdi mdi-credit-card-remove-outline",
	"mdi mdi-credit-card-scan",
	"mdi mdi-credit-card-scan-outline",
	"mdi mdi-credit-card-settings",
	"mdi mdi-credit-card-settings-outline",
	"mdi mdi-credit-card-wireless",
	"mdi mdi-credit-card-wireless-off",
	"mdi mdi-credit-card-wireless-off-outline",
	"mdi mdi-credit-card-wireless-outline",
	"mdi mdi-cricket",
	"mdi mdi-crop",
	"mdi mdi-crop-free",
	"mdi mdi-crop-landscape",
	"mdi mdi-crop-portrait",
	"mdi mdi-crop-rotate",
	"mdi mdi-crop-square",
	"mdi mdi-crosshairs",
	"mdi mdi-crosshairs-gps",
	"mdi mdi-crosshairs-off",
	"mdi mdi-crosshairs-question",
	"mdi mdi-crown",
	"mdi mdi-crown-outline",
	"mdi mdi-cryengine",
	"mdi mdi-crystal-ball",
	"mdi mdi-cube",
	"mdi mdi-cube-off",
	"mdi mdi-cube-off-outline",
	"mdi mdi-cube-outline",
	"mdi mdi-cube-scan",
	"mdi mdi-cube-send",
	"mdi mdi-cube-unfolded",
	"mdi mdi-cup",
	"mdi mdi-cup-off",
	"mdi mdi-cup-off-outline",
	"mdi mdi-cup-outline",
	"mdi mdi-cup-water",
	"mdi mdi-cupboard",
	"mdi mdi-cupboard-outline",
	"mdi mdi-cupcake",
	"mdi mdi-curling",
	"mdi mdi-currency-bdt",
	"mdi mdi-currency-brl",
	"mdi mdi-currency-btc",
	"mdi mdi-currency-cny",
	"mdi mdi-currency-eth",
	"mdi mdi-currency-eur",
	"mdi mdi-currency-eur-off",
	"mdi mdi-currency-gbp",
	"mdi mdi-currency-ils",
	"mdi mdi-currency-inr",
	"mdi mdi-currency-jpy",
	"mdi mdi-currency-krw",
	"mdi mdi-currency-kzt",
	"mdi mdi-currency-mnt",
	"mdi mdi-currency-ngn",
	"mdi mdi-currency-php",
	"mdi mdi-currency-rial",
	"mdi mdi-currency-rub",
	"mdi mdi-currency-sign",
	"mdi mdi-currency-try",
	"mdi mdi-currency-twd",
	"mdi mdi-currency-usd",
	"mdi mdi-currency-usd-circle",
	"mdi mdi-currency-usd-circle-outline",
	"mdi mdi-currency-usd-off",
	"mdi mdi-current-ac",
	"mdi mdi-current-dc",
	"mdi mdi-cursor-default",
	"mdi mdi-cursor-default-click",
	"mdi mdi-cursor-default-click-outline",
	"mdi mdi-cursor-default-gesture",
	"mdi mdi-cursor-default-gesture-outline",
	"mdi mdi-cursor-default-outline",
	"mdi mdi-cursor-move",
	"mdi mdi-cursor-pointer",
	"mdi mdi-cursor-text",
	"mdi mdi-dance-pole",
	"mdi mdi-data-matrix",
	"mdi mdi-data-matrix-edit",
	"mdi mdi-data-matrix-minus",
	"mdi mdi-data-matrix-plus",
	"mdi mdi-data-matrix-remove",
	"mdi mdi-data-matrix-scan",
	"mdi mdi-database",
	"mdi mdi-database-check",
	"mdi mdi-database-edit",
	"mdi mdi-database-export",
	"mdi mdi-database-import",
	"mdi mdi-database-lock",
	"mdi mdi-database-marker",
	"mdi mdi-database-minus",
	"mdi mdi-database-plus",
	"mdi mdi-database-refresh",
	"mdi mdi-database-remove",
	"mdi mdi-database-search",
	"mdi mdi-database-settings",
	"mdi mdi-database-sync",
	"mdi mdi-death-star",
	"mdi mdi-death-star-variant",
	"mdi mdi-deathly-hallows",
	"mdi mdi-debian",
	"mdi mdi-debug-step-into",
	"mdi mdi-debug-step-out",
	"mdi mdi-debug-step-over",
	"mdi mdi-decagram",
	"mdi mdi-decagram-outline",
	"mdi mdi-decimal",
	"mdi mdi-decimal-comma",
	"mdi mdi-decimal-comma-decrease",
	"mdi mdi-decimal-comma-increase",
	"mdi mdi-decimal-decrease",
	"mdi mdi-decimal-increase",
	"mdi mdi-delete",
	"mdi mdi-delete-alert",
	"mdi mdi-delete-alert-outline",
	"mdi mdi-delete-circle",
	"mdi mdi-delete-circle-outline",
	"mdi mdi-delete-clock",
	"mdi mdi-delete-clock-outline",
	"mdi mdi-delete-empty",
	"mdi mdi-delete-empty-outline",
	"mdi mdi-delete-forever",
	"mdi mdi-delete-forever-outline",
	"mdi mdi-delete-off",
	"mdi mdi-delete-off-outline",
	"mdi mdi-delete-outline",
	"mdi mdi-delete-restore",
	"mdi mdi-delete-sweep",
	"mdi mdi-delete-sweep-outline",
	"mdi mdi-delete-variant",
	"mdi mdi-delta",
	"mdi mdi-desk",
	"mdi mdi-desk-lamp",
	"mdi mdi-deskphone",
	"mdi mdi-desktop-classic",
	"mdi mdi-desktop-mac",
	"mdi mdi-desktop-mac-dashboard",
	"mdi mdi-desktop-tower",
	"mdi mdi-desktop-tower-monitor",
	"mdi mdi-details",
	"mdi mdi-dev-to",
	"mdi mdi-developer-board",
	"mdi mdi-deviantart",
	"mdi mdi-devices",
	"mdi mdi-diabetes",
	"mdi mdi-dialpad",
	"mdi mdi-diameter",
	"mdi mdi-diameter-outline",
	"mdi mdi-diameter-variant",
	"mdi mdi-diamond",
	"mdi mdi-diamond-outline",
	"mdi mdi-diamond-stone",
	"mdi mdi-dice-1",
	"mdi mdi-dice-1-outline",
	"mdi mdi-dice-2",
	"mdi mdi-dice-2-outline",
	"mdi mdi-dice-3",
	"mdi mdi-dice-3-outline",
	"mdi mdi-dice-4",
	"mdi mdi-dice-4-outline",
	"mdi mdi-dice-5",
	"mdi mdi-dice-5-outline",
	"mdi mdi-dice-6",
	"mdi mdi-dice-6-outline",
	"mdi mdi-dice-d10",
	"mdi mdi-dice-d10-outline",
	"mdi mdi-dice-d12",
	"mdi mdi-dice-d12-outline",
	"mdi mdi-dice-d20",
	"mdi mdi-dice-d20-outline",
	"mdi mdi-dice-d4",
	"mdi mdi-dice-d4-outline",
	"mdi mdi-dice-d6",
	"mdi mdi-dice-d6-outline",
	"mdi mdi-dice-d8",
	"mdi mdi-dice-d8-outline",
	"mdi mdi-dice-multiple",
	"mdi mdi-dice-multiple-outline",
	"mdi mdi-digital-ocean",
	"mdi mdi-dip-switch",
	"mdi mdi-directions",
	"mdi mdi-directions-fork",
	"mdi mdi-disc",
	"mdi mdi-disc-alert",
	"mdi mdi-disc-player",
	"mdi mdi-discord",
	"mdi mdi-dishwasher",
	"mdi mdi-dishwasher-alert",
	"mdi mdi-dishwasher-off",
	"mdi mdi-disqus",
	"mdi mdi-distribute-horizontal-center",
	"mdi mdi-distribute-horizontal-left",
	"mdi mdi-distribute-horizontal-right",
	"mdi mdi-distribute-vertical-bottom",
	"mdi mdi-distribute-vertical-center",
	"mdi mdi-distribute-vertical-top",
	"mdi mdi-diving-flippers",
	"mdi mdi-diving-helmet",
	"mdi mdi-diving-scuba",
	"mdi mdi-diving-scuba-flag",
	"mdi mdi-diving-scuba-tank",
	"mdi mdi-diving-scuba-tank-multiple",
	"mdi mdi-diving-snorkel",
	"mdi mdi-division",
	"mdi mdi-division-box",
	"mdi mdi-dlna",
	"mdi mdi-dna",
	"mdi mdi-dns",
	"mdi mdi-dns-outline",
	"mdi mdi-do-not-disturb",
	"mdi mdi-do-not-disturb-off",
	"mdi mdi-dock-bottom",
	"mdi mdi-dock-left",
	"mdi mdi-dock-right",
	"mdi mdi-dock-top",
	"mdi mdi-dock-window",
	"mdi mdi-docker",
	"mdi mdi-doctor",
	"mdi mdi-dog",
	"mdi mdi-dog-service",
	"mdi mdi-dog-side",
	"mdi mdi-dolby",
	"mdi mdi-dolly",
	"mdi mdi-domain",
	"mdi mdi-domain-off",
	"mdi mdi-domain-plus",
	"mdi mdi-domain-remove",
	"mdi mdi-dome-light",
	"mdi mdi-domino-mask",
	"mdi mdi-donkey",
	"mdi mdi-door",
	"mdi mdi-door-closed",
	"mdi mdi-door-closed-lock",
	"mdi mdi-door-open",
	"mdi mdi-doorbell",
	"mdi mdi-doorbell-video",
	"mdi mdi-dot-net",
	"mdi mdi-dots-horizontal",
	"mdi mdi-dots-horizontal-circle",
	"mdi mdi-dots-horizontal-circle-outline",
	"mdi mdi-dots-vertical",
	"mdi mdi-dots-vertical-circle",
	"mdi mdi-dots-vertical-circle-outline",
	"mdi mdi-douban",
	"mdi mdi-download",
	"mdi mdi-download-box",
	"mdi mdi-download-box-outline",
	"mdi mdi-download-circle",
	"mdi mdi-download-circle-outline",
	"mdi mdi-download-lock",
	"mdi mdi-download-lock-outline",
	"mdi mdi-download-multiple",
	"mdi mdi-download-network",
	"mdi mdi-download-network-outline",
	"mdi mdi-download-off",
	"mdi mdi-download-off-outline",
	"mdi mdi-download-outline",
	"mdi mdi-drag",
	"mdi mdi-drag-horizontal",
	"mdi mdi-drag-horizontal-variant",
	"mdi mdi-drag-variant",
	"mdi mdi-drag-vertical",
	"mdi mdi-drag-vertical-variant",
	"mdi mdi-drama-masks",
	"mdi mdi-draw",
	"mdi mdi-drawing",
	"mdi mdi-drawing-box",
	"mdi mdi-dresser",
	"mdi mdi-dresser-outline",
	"mdi mdi-drone",
	"mdi mdi-dropbox",
	"mdi mdi-drupal",
	"mdi mdi-duck",
	"mdi mdi-dumbbell",
	"mdi mdi-dump-truck",
	"mdi mdi-ear-hearing",
	"mdi mdi-ear-hearing-off",
	"mdi mdi-earth",
	"mdi mdi-earth-arrow-right",
	"mdi mdi-earth-box",
	"mdi mdi-earth-box-minus",
	"mdi mdi-earth-box-off",
	"mdi mdi-earth-box-plus",
	"mdi mdi-earth-box-remove",
	"mdi mdi-earth-minus",
	"mdi mdi-earth-off",
	"mdi mdi-earth-plus",
	"mdi mdi-earth-remove",
	"mdi mdi-egg",
	"mdi mdi-egg-easter",
	"mdi mdi-egg-off",
	"mdi mdi-egg-off-outline",
	"mdi mdi-egg-outline",
	"mdi mdi-eiffel-tower",
	"mdi mdi-eight-track",
	"mdi mdi-eject",
	"mdi mdi-eject-outline",
	"mdi mdi-electric-switch",
	"mdi mdi-electric-switch-closed",
	"mdi mdi-electron-framework",
	"mdi mdi-elephant",
	"mdi mdi-elevation-decline",
	"mdi mdi-elevation-rise",
	"mdi mdi-elevator",
	"mdi mdi-elevator-down",
	"mdi mdi-elevator-passenger",
	"mdi mdi-elevator-up",
	"mdi mdi-ellipse",
	"mdi mdi-ellipse-outline",
	"mdi mdi-email",
	"mdi mdi-email-alert",
	"mdi mdi-email-alert-outline",
	"mdi mdi-email-box",
	"mdi mdi-email-check",
	"mdi mdi-email-check-outline",
	"mdi mdi-email-edit",
	"mdi mdi-email-edit-outline",
	"mdi mdi-email-lock",
	"mdi mdi-email-mark-as-unread",
	"mdi mdi-email-minus",
	"mdi mdi-email-minus-outline",
	"mdi mdi-email-multiple",
	"mdi mdi-email-multiple-outline",
	"mdi mdi-email-newsletter",
	"mdi mdi-email-off",
	"mdi mdi-email-off-outline",
	"mdi mdi-email-open",
	"mdi mdi-email-open-multiple",
	"mdi mdi-email-open-multiple-outline",
	"mdi mdi-email-open-outline",
	"mdi mdi-email-outline",
	"mdi mdi-email-plus",
	"mdi mdi-email-plus-outline",
	"mdi mdi-email-receive",
	"mdi mdi-email-receive-outline",
	"mdi mdi-email-search",
	"mdi mdi-email-search-outline",
	"mdi mdi-email-send",
	"mdi mdi-email-send-outline",
	"mdi mdi-email-sync",
	"mdi mdi-email-sync-outline",
	"mdi mdi-email-variant",
	"mdi mdi-ember",
	"mdi mdi-emby",
	"mdi mdi-emoticon",
	"mdi mdi-emoticon-angry",
	"mdi mdi-emoticon-angry-outline",
	"mdi mdi-emoticon-confused",
	"mdi mdi-emoticon-confused-outline",
	"mdi mdi-emoticon-cool",
	"mdi mdi-emoticon-cool-outline",
	"mdi mdi-emoticon-cry",
	"mdi mdi-emoticon-cry-outline",
	"mdi mdi-emoticon-dead",
	"mdi mdi-emoticon-dead-outline",
	"mdi mdi-emoticon-devil",
	"mdi mdi-emoticon-devil-outline",
	"mdi mdi-emoticon-excited",
	"mdi mdi-emoticon-excited-outline",
	"mdi mdi-emoticon-frown",
	"mdi mdi-emoticon-frown-outline",
	"mdi mdi-emoticon-happy",
	"mdi mdi-emoticon-happy-outline",
	"mdi mdi-emoticon-kiss",
	"mdi mdi-emoticon-kiss-outline",
	"mdi mdi-emoticon-lol",
	"mdi mdi-emoticon-lol-outline",
	"mdi mdi-emoticon-neutral",
	"mdi mdi-emoticon-neutral-outline",
	"mdi mdi-emoticon-outline",
	"mdi mdi-emoticon-poop",
	"mdi mdi-emoticon-poop-outline",
	"mdi mdi-emoticon-sad",
	"mdi mdi-emoticon-sad-outline",
	"mdi mdi-emoticon-sick",
	"mdi mdi-emoticon-sick-outline",
	"mdi mdi-emoticon-tongue",
	"mdi mdi-emoticon-tongue-outline",
	"mdi mdi-emoticon-wink",
	"mdi mdi-emoticon-wink-outline",
	"mdi mdi-engine",
	"mdi mdi-engine-off",
	"mdi mdi-engine-off-outline",
	"mdi mdi-engine-outline",
	"mdi mdi-epsilon",
	"mdi mdi-equal",
	"mdi mdi-equal-box",
	"mdi mdi-equalizer",
	"mdi mdi-equalizer-outline",
	"mdi mdi-eraser",
	"mdi mdi-eraser-variant",
	"mdi mdi-escalator",
	"mdi mdi-escalator-box",
	"mdi mdi-escalator-down",
	"mdi mdi-escalator-up",
	"mdi mdi-eslint",
	"mdi mdi-et",
	"mdi mdi-ethereum",
	"mdi mdi-ethernet",
	"mdi mdi-ethernet-cable",
	"mdi mdi-ethernet-cable-off",
	"mdi mdi-ev-plug-ccs1",
	"mdi mdi-ev-plug-ccs2",
	"mdi mdi-ev-plug-chademo",
	"mdi mdi-ev-plug-tesla",
	"mdi mdi-ev-plug-type1",
	"mdi mdi-ev-plug-type2",
	"mdi mdi-ev-station",
	"mdi mdi-evernote",
	"mdi mdi-excavator",
	"mdi mdi-exclamation",
	"mdi mdi-exclamation-thick",
	"mdi mdi-exit-run",
	"mdi mdi-exit-to-app",
	"mdi mdi-expand-all",
	"mdi mdi-expand-all-outline",
	"mdi mdi-expansion-card",
	"mdi mdi-expansion-card-variant",
	"mdi mdi-exponent",
	"mdi mdi-exponent-box",
	"mdi mdi-export",
	"mdi mdi-export-variant",
	"mdi mdi-eye",
	"mdi mdi-eye-check",
	"mdi mdi-eye-check-outline",
	"mdi mdi-eye-circle",
	"mdi mdi-eye-circle-outline",
	"mdi mdi-eye-minus",
	"mdi mdi-eye-minus-outline",
	"mdi mdi-eye-off",
	"mdi mdi-eye-off-outline",
	"mdi mdi-eye-outline",
	"mdi mdi-eye-plus",
	"mdi mdi-eye-plus-outline",
	"mdi mdi-eye-settings",
	"mdi mdi-eye-settings-outline",
	"mdi mdi-eyedropper",
	"mdi mdi-eyedropper-minus",
	"mdi mdi-eyedropper-off",
	"mdi mdi-eyedropper-plus",
	"mdi mdi-eyedropper-remove",
	"mdi mdi-eyedropper-variant",
	"mdi mdi-face",
	"mdi mdi-face-agent",
	"mdi mdi-face-mask",
	"mdi mdi-face-mask-outline",
	"mdi mdi-face-outline",
	"mdi mdi-face-profile",
	"mdi mdi-face-profile-woman",
	"mdi mdi-face-recognition",
	"mdi mdi-face-woman",
	"mdi mdi-face-woman-outline",
	"mdi mdi-facebook",
	"mdi mdi-facebook-gaming",
	"mdi mdi-facebook-messenger",
	"mdi mdi-facebook-workplace",
	"mdi mdi-factory",
	"mdi mdi-fan",
	"mdi mdi-fan-alert",
	"mdi mdi-fan-chevron-down",
	"mdi mdi-fan-chevron-up",
	"mdi mdi-fan-minus",
	"mdi mdi-fan-off",
	"mdi mdi-fan-plus",
	"mdi mdi-fan-remove",
	"mdi mdi-fan-speed-1",
	"mdi mdi-fan-speed-2",
	"mdi mdi-fan-speed-3",
	"mdi mdi-fast-forward",
	"mdi mdi-fast-forward-10",
	"mdi mdi-fast-forward-30",
	"mdi mdi-fast-forward-5",
	"mdi mdi-fast-forward-outline",
	"mdi mdi-fax",
	"mdi mdi-feather",
	"mdi mdi-feature-search",
	"mdi mdi-feature-search-outline",
	"mdi mdi-fedora",
	"mdi mdi-fencing",
	"mdi mdi-ferris-wheel",
	"mdi mdi-ferry",
	"mdi mdi-file",
	"mdi mdi-file-account",
	"mdi mdi-file-account-outline",
	"mdi mdi-file-alert",
	"mdi mdi-file-alert-outline",
	"mdi mdi-file-cabinet",
	"mdi mdi-file-cad",
	"mdi mdi-file-cad-box",
	"mdi mdi-file-cancel",
	"mdi mdi-file-cancel-outline",
	"mdi mdi-file-certificate",
	"mdi mdi-file-certificate-outline",
	"mdi mdi-file-chart",
	"mdi mdi-file-chart-outline",
	"mdi mdi-file-check",
	"mdi mdi-file-check-outline",
	"mdi mdi-file-clock",
	"mdi mdi-file-clock-outline",
	"mdi mdi-file-cloud",
	"mdi mdi-file-cloud-outline",
	"mdi mdi-file-code",
	"mdi mdi-file-code-outline",
	"mdi mdi-file-cog",
	"mdi mdi-file-cog-outline",
	"mdi mdi-file-compare",
	"mdi mdi-file-delimited",
	"mdi mdi-file-delimited-outline",
	"mdi mdi-file-document",
	"mdi mdi-file-document-edit",
	"mdi mdi-file-document-edit-outline",
	"mdi mdi-file-document-multiple",
	"mdi mdi-file-document-multiple-outline",
	"mdi mdi-file-document-outline",
	"mdi mdi-file-download",
	"mdi mdi-file-download-outline",
	"mdi mdi-file-edit",
	"mdi mdi-file-edit-outline",
	"mdi mdi-file-excel",
	"mdi mdi-file-excel-box",
	"mdi mdi-file-excel-box-outline",
	"mdi mdi-file-excel-outline",
	"mdi mdi-file-export",
	"mdi mdi-file-export-outline",
	"mdi mdi-file-eye",
	"mdi mdi-file-eye-outline",
	"mdi mdi-file-find",
	"mdi mdi-file-find-outline",
	"mdi mdi-file-hidden",
	"mdi mdi-file-image",
	"mdi mdi-file-image-outline",
	"mdi mdi-file-import",
	"mdi mdi-file-import-outline",
	"mdi mdi-file-key",
	"mdi mdi-file-key-outline",
	"mdi mdi-file-link",
	"mdi mdi-file-link-outline",
	"mdi mdi-file-lock",
	"mdi mdi-file-lock-outline",
	"mdi mdi-file-move",
	"mdi mdi-file-move-outline",
	"mdi mdi-file-multiple",
	"mdi mdi-file-multiple-outline",
	"mdi mdi-file-music",
	"mdi mdi-file-music-outline",
	"mdi mdi-file-outline",
	"mdi mdi-file-pdf",
	"mdi mdi-file-pdf-box",
	"mdi mdi-file-pdf-box-outline",
	"mdi mdi-file-pdf-outline",
	"mdi mdi-file-percent",
	"mdi mdi-file-percent-outline",
	"mdi mdi-file-phone",
	"mdi mdi-file-phone-outline",
	"mdi mdi-file-plus",
	"mdi mdi-file-plus-outline",
	"mdi mdi-file-powerpoint",
	"mdi mdi-file-powerpoint-box",
	"mdi mdi-file-powerpoint-box-outline",
	"mdi mdi-file-powerpoint-outline",
	"mdi mdi-file-presentation-box",
	"mdi mdi-file-question",
	"mdi mdi-file-question-outline",
	"mdi mdi-file-refresh",
	"mdi mdi-file-refresh-outline",
	"mdi mdi-file-remove",
	"mdi mdi-file-remove-outline",
	"mdi mdi-file-replace",
	"mdi mdi-file-replace-outline",
	"mdi mdi-file-restore",
	"mdi mdi-file-restore-outline",
	"mdi mdi-file-search",
	"mdi mdi-file-search-outline",
	"mdi mdi-file-send",
	"mdi mdi-file-send-outline",
	"mdi mdi-file-settings",
	"mdi mdi-file-settings-outline",
	"mdi mdi-file-star",
	"mdi mdi-file-star-outline",
	"mdi mdi-file-swap",
	"mdi mdi-file-swap-outline",
	"mdi mdi-file-sync",
	"mdi mdi-file-sync-outline",
	"mdi mdi-file-table",
	"mdi mdi-file-table-box",
	"mdi mdi-file-table-box-multiple",
	"mdi mdi-file-table-box-multiple-outline",
	"mdi mdi-file-table-box-outline",
	"mdi mdi-file-table-outline",
	"mdi mdi-file-tree",
	"mdi mdi-file-tree-outline",
	"mdi mdi-file-undo",
	"mdi mdi-file-undo-outline",
	"mdi mdi-file-upload",
	"mdi mdi-file-upload-outline",
	"mdi mdi-file-video",
	"mdi mdi-file-video-outline",
	"mdi mdi-file-word",
	"mdi mdi-file-word-box",
	"mdi mdi-file-word-box-outline",
	"mdi mdi-file-word-outline",
	"mdi mdi-film",
	"mdi mdi-filmstrip",
	"mdi mdi-filmstrip-box",
	"mdi mdi-filmstrip-box-multiple",
	"mdi mdi-filmstrip-off",
	"mdi mdi-filter",
	"mdi mdi-filter-menu",
	"mdi mdi-filter-menu-outline",
	"mdi mdi-filter-minus",
	"mdi mdi-filter-minus-outline",
	"mdi mdi-filter-off",
	"mdi mdi-filter-off-outline",
	"mdi mdi-filter-outline",
	"mdi mdi-filter-plus",
	"mdi mdi-filter-plus-outline",
	"mdi mdi-filter-remove",
	"mdi mdi-filter-remove-outline",
	"mdi mdi-filter-variant",
	"mdi mdi-filter-variant-minus",
	"mdi mdi-filter-variant-plus",
	"mdi mdi-filter-variant-remove",
	"mdi mdi-finance",
	"mdi mdi-find-replace",
	"mdi mdi-fingerprint",
	"mdi mdi-fingerprint-off",
	"mdi mdi-fire",
	"mdi mdi-fire-extinguisher",
	"mdi mdi-fire-hydrant",
	"mdi mdi-fire-hydrant-alert",
	"mdi mdi-fire-hydrant-off",
	"mdi mdi-fire-truck",
	"mdi mdi-firebase",
	"mdi mdi-firefox",
	"mdi mdi-fireplace",
	"mdi mdi-fireplace-off",
	"mdi mdi-firework",
	"mdi mdi-fish",
	"mdi mdi-fish-off",
	"mdi mdi-fishbowl",
	"mdi mdi-fishbowl-outline",
	"mdi mdi-fit-to-page",
	"mdi mdi-fit-to-page-outline",
	"mdi mdi-flag",
	"mdi mdi-flag-checkered",
	"mdi mdi-flag-minus",
	"mdi mdi-flag-minus-outline",
	"mdi mdi-flag-outline",
	"mdi mdi-flag-plus",
	"mdi mdi-flag-plus-outline",
	"mdi mdi-flag-remove",
	"mdi mdi-flag-remove-outline",
	"mdi mdi-flag-triangle",
	"mdi mdi-flag-variant",
	"mdi mdi-flag-variant-outline",
	"mdi mdi-flare",
	"mdi mdi-flash",
	"mdi mdi-flash-alert",
	"mdi mdi-flash-alert-outline",
	"mdi mdi-flash-auto",
	"mdi mdi-flash-circle",
	"mdi mdi-flash-off",
	"mdi mdi-flash-outline",
	"mdi mdi-flash-red-eye",
	"mdi mdi-flashlight",
	"mdi mdi-flashlight-off",
	"mdi mdi-flask",
	"mdi mdi-flask-empty",
	"mdi mdi-flask-empty-minus",
	"mdi mdi-flask-empty-minus-outline",
	"mdi mdi-flask-empty-off",
	"mdi mdi-flask-empty-off-outline",
	"mdi mdi-flask-empty-outline",
	"mdi mdi-flask-empty-plus",
	"mdi mdi-flask-empty-plus-outline",
	"mdi mdi-flask-empty-remove",
	"mdi mdi-flask-empty-remove-outline",
	"mdi mdi-flask-minus",
	"mdi mdi-flask-minus-outline",
	"mdi mdi-flask-off",
	"mdi mdi-flask-off-outline",
	"mdi mdi-flask-outline",
	"mdi mdi-flask-plus",
	"mdi mdi-flask-plus-outline",
	"mdi mdi-flask-remove",
	"mdi mdi-flask-remove-outline",
	"mdi mdi-flask-round-bottom",
	"mdi mdi-flask-round-bottom-empty",
	"mdi mdi-flask-round-bottom-empty-outline",
	"mdi mdi-flask-round-bottom-outline",
	"mdi mdi-fleur-de-lis",
	"mdi mdi-flip-horizontal",
	"mdi mdi-flip-to-back",
	"mdi mdi-flip-to-front",
	"mdi mdi-flip-vertical",
	"mdi mdi-floor-lamp",
	"mdi mdi-floor-lamp-dual",
	"mdi mdi-floor-lamp-variant",
	"mdi mdi-floor-plan",
	"mdi mdi-floppy",
	"mdi mdi-floppy-variant",
	"mdi mdi-flower",
	"mdi mdi-flower-outline",
	"mdi mdi-flower-poppy",
	"mdi mdi-flower-tulip",
	"mdi mdi-flower-tulip-outline",
	"mdi mdi-focus-auto",
	"mdi mdi-focus-field",
	"mdi mdi-focus-field-horizontal",
	"mdi mdi-focus-field-vertical",
	"mdi mdi-folder",
	"mdi mdi-folder-account",
	"mdi mdi-folder-account-outline",
	"mdi mdi-folder-alert",
	"mdi mdi-folder-alert-outline",
	"mdi mdi-folder-clock",
	"mdi mdi-folder-clock-outline",
	"mdi mdi-folder-cog",
	"mdi mdi-folder-cog-outline",
	"mdi mdi-folder-download",
	"mdi mdi-folder-download-outline",
	"mdi mdi-folder-edit",
	"mdi mdi-folder-edit-outline",
	"mdi mdi-folder-google-drive",
	"mdi mdi-folder-heart",
	"mdi mdi-folder-heart-outline",
	"mdi mdi-folder-home",
	"mdi mdi-folder-home-outline",
	"mdi mdi-folder-image",
	"mdi mdi-folder-information",
	"mdi mdi-folder-information-outline",
	"mdi mdi-folder-key",
	"mdi mdi-folder-key-network",
	"mdi mdi-folder-key-network-outline",
	"mdi mdi-folder-key-outline",
	"mdi mdi-folder-lock",
	"mdi mdi-folder-lock-open",
	"mdi mdi-folder-marker",
	"mdi mdi-folder-marker-outline",
	"mdi mdi-folder-move",
	"mdi mdi-folder-move-outline",
	"mdi mdi-folder-multiple",
	"mdi mdi-folder-multiple-image",
	"mdi mdi-folder-multiple-outline",
	"mdi mdi-folder-multiple-plus",
	"mdi mdi-folder-multiple-plus-outline",
	"mdi mdi-folder-music",
	"mdi mdi-folder-music-outline",
	"mdi mdi-folder-network",
	"mdi mdi-folder-network-outline",
	"mdi mdi-folder-open",
	"mdi mdi-folder-open-outline",
	"mdi mdi-folder-outline",
	"mdi mdi-folder-plus",
	"mdi mdi-folder-plus-outline",
	"mdi mdi-folder-pound",
	"mdi mdi-folder-pound-outline",
	"mdi mdi-folder-refresh",
	"mdi mdi-folder-refresh-outline",
	"mdi mdi-folder-remove",
	"mdi mdi-folder-remove-outline",
	"mdi mdi-folder-search",
	"mdi mdi-folder-search-outline",
	"mdi mdi-folder-settings",
	"mdi mdi-folder-settings-outline",
	"mdi mdi-folder-star",
	"mdi mdi-folder-star-multiple",
	"mdi mdi-folder-star-multiple-outline",
	"mdi mdi-folder-star-outline",
	"mdi mdi-folder-swap",
	"mdi mdi-folder-swap-outline",
	"mdi mdi-folder-sync",
	"mdi mdi-folder-sync-outline",
	"mdi mdi-folder-table",
	"mdi mdi-folder-table-outline",
	"mdi mdi-folder-text",
	"mdi mdi-folder-text-outline",
	"mdi mdi-folder-upload",
	"mdi mdi-folder-upload-outline",
	"mdi mdi-folder-zip",
	"mdi mdi-folder-zip-outline",
	"mdi mdi-font-awesome",
	"mdi mdi-food",
	"mdi mdi-food-apple",
	"mdi mdi-food-apple-outline",
	"mdi mdi-food-croissant",
	"mdi mdi-food-drumstick",
	"mdi mdi-food-drumstick-off",
	"mdi mdi-food-drumstick-off-outline",
	"mdi mdi-food-drumstick-outline",
	"mdi mdi-food-fork-drink",
	"mdi mdi-food-halal",
	"mdi mdi-food-kosher",
	"mdi mdi-food-off",
	"mdi mdi-food-steak",
	"mdi mdi-food-steak-off",
	"mdi mdi-food-variant",
	"mdi mdi-food-variant-off",
	"mdi mdi-foot-print",
	"mdi mdi-football",
	"mdi mdi-football-australian",
	"mdi mdi-football-helmet",
	"mdi mdi-forklift",
	"mdi mdi-form-dropdown",
	"mdi mdi-form-select",
	"mdi mdi-form-textarea",
	"mdi mdi-form-textbox",
	"mdi mdi-form-textbox-lock",
	"mdi mdi-form-textbox-password",
	"mdi mdi-format-align-bottom",
	"mdi mdi-format-align-center",
	"mdi mdi-format-align-justify",
	"mdi mdi-format-align-left",
	"mdi mdi-format-align-middle",
	"mdi mdi-format-align-right",
	"mdi mdi-format-align-top",
	"mdi mdi-format-annotation-minus",
	"mdi mdi-format-annotation-plus",
	"mdi mdi-format-bold",
	"mdi mdi-format-clear",
	"mdi mdi-format-color-fill",
	"mdi mdi-format-color-highlight",
	"mdi mdi-format-color-marker-cancel",
	"mdi mdi-format-color-text",
	"mdi mdi-format-columns",
	"mdi mdi-format-float-center",
	"mdi mdi-format-float-left",
	"mdi mdi-format-float-none",
	"mdi mdi-format-float-right",
	"mdi mdi-format-font",
	"mdi mdi-format-font-size-decrease",
	"mdi mdi-format-font-size-increase",
	"mdi mdi-format-header-1",
	"mdi mdi-format-header-2",
	"mdi mdi-format-header-3",
	"mdi mdi-format-header-4",
	"mdi mdi-format-header-5",
	"mdi mdi-format-header-6",
	"mdi mdi-format-header-decrease",
	"mdi mdi-format-header-equal",
	"mdi mdi-format-header-increase",
	"mdi mdi-format-header-pound",
	"mdi mdi-format-horizontal-align-center",
	"mdi mdi-format-horizontal-align-left",
	"mdi mdi-format-horizontal-align-right",
	"mdi mdi-format-indent-decrease",
	"mdi mdi-format-indent-increase",
	"mdi mdi-format-italic",
	"mdi mdi-format-letter-case",
	"mdi mdi-format-letter-case-lower",
	"mdi mdi-format-letter-case-upper",
	"mdi mdi-format-letter-ends-with",
	"mdi mdi-format-letter-matches",
	"mdi mdi-format-letter-starts-with",
	"mdi mdi-format-line-spacing",
	"mdi mdi-format-line-style",
	"mdi mdi-format-line-weight",
	"mdi mdi-format-list-bulleted",
	"mdi mdi-format-list-bulleted-square",
	"mdi mdi-format-list-bulleted-triangle",
	"mdi mdi-format-list-bulleted-type",
	"mdi mdi-format-list-checkbox",
	"mdi mdi-format-list-checks",
	"mdi mdi-format-list-numbered",
	"mdi mdi-format-list-numbered-rtl",
	"mdi mdi-format-list-text",
	"mdi mdi-format-overline",
	"mdi mdi-format-page-break",
	"mdi mdi-format-paint",
	"mdi mdi-format-paragraph",
	"mdi mdi-format-pilcrow",
	"mdi mdi-format-quote-close",
	"mdi mdi-format-quote-close-outline",
	"mdi mdi-format-quote-open",
	"mdi mdi-format-quote-open-outline",
	"mdi mdi-format-rotate-90",
	"mdi mdi-format-section",
	"mdi mdi-format-size",
	"mdi mdi-format-strikethrough",
	"mdi mdi-format-strikethrough-variant",
	"mdi mdi-format-subscript",
	"mdi mdi-format-superscript",
	"mdi mdi-format-text",
	"mdi mdi-format-text-rotation-angle-down",
	"mdi mdi-format-text-rotation-angle-up",
	"mdi mdi-format-text-rotation-down",
	"mdi mdi-format-text-rotation-down-vertical",
	"mdi mdi-format-text-rotation-none",
	"mdi mdi-format-text-rotation-up",
	"mdi mdi-format-text-rotation-vertical",
	"mdi mdi-format-text-variant",
	"mdi mdi-format-text-variant-outline",
	"mdi mdi-format-text-wrapping-clip",
	"mdi mdi-format-text-wrapping-overflow",
	"mdi mdi-format-text-wrapping-wrap",
	"mdi mdi-format-textbox",
	"mdi mdi-format-textdirection-l-to-r",
	"mdi mdi-format-textdirection-r-to-l",
	"mdi mdi-format-title",
	"mdi mdi-format-underline",
	"mdi mdi-format-vertical-align-bottom",
	"mdi mdi-format-vertical-align-center",
	"mdi mdi-format-vertical-align-top",
	"mdi mdi-format-wrap-inline",
	"mdi mdi-format-wrap-square",
	"mdi mdi-format-wrap-tight",
	"mdi mdi-format-wrap-top-bottom",
	"mdi mdi-forum",
	"mdi mdi-forum-outline",
	"mdi mdi-forward",
	"mdi mdi-forwardburger",
	"mdi mdi-fountain",
	"mdi mdi-fountain-pen",
	"mdi mdi-fountain-pen-tip",
	"mdi mdi-freebsd",
	"mdi mdi-frequently-asked-questions",
	"mdi mdi-fridge",
	"mdi mdi-fridge-alert",
	"mdi mdi-fridge-alert-outline",
	"mdi mdi-fridge-bottom",
	"mdi mdi-fridge-off",
	"mdi mdi-fridge-off-outline",
	"mdi mdi-fridge-outline",
	"mdi mdi-fridge-top",
	"mdi mdi-fruit-cherries",
	"mdi mdi-fruit-cherries-off",
	"mdi mdi-fruit-citrus",
	"mdi mdi-fruit-citrus-off",
	"mdi mdi-fruit-grapes",
	"mdi mdi-fruit-grapes-outline",
	"mdi mdi-fruit-pineapple",
	"mdi mdi-fruit-watermelon",
	"mdi mdi-fuel",
	"mdi mdi-fullscreen",
	"mdi mdi-fullscreen-exit",
	"mdi mdi-function",
	"mdi mdi-function-variant",
	"mdi mdi-furigana-horizontal",
	"mdi mdi-furigana-vertical",
	"mdi mdi-fuse",
	"mdi mdi-fuse-alert",
	"mdi mdi-fuse-blade",
	"mdi mdi-fuse-off",
	"mdi mdi-gamepad",
	"mdi mdi-gamepad-circle",
	"mdi mdi-gamepad-circle-down",
	"mdi mdi-gamepad-circle-left",
	"mdi mdi-gamepad-circle-outline",
	"mdi mdi-gamepad-circle-right",
	"mdi mdi-gamepad-circle-up",
	"mdi mdi-gamepad-down",
	"mdi mdi-gamepad-left",
	"mdi mdi-gamepad-right",
	"mdi mdi-gamepad-round",
	"mdi mdi-gamepad-round-down",
	"mdi mdi-gamepad-round-left",
	"mdi mdi-gamepad-round-outline",
	"mdi mdi-gamepad-round-right",
	"mdi mdi-gamepad-round-up",
	"mdi mdi-gamepad-square",
	"mdi mdi-gamepad-square-outline",
	"mdi mdi-gamepad-up",
	"mdi mdi-gamepad-variant",
	"mdi mdi-gamepad-variant-outline",
	"mdi mdi-gamma",
	"mdi mdi-gantry-crane",
	"mdi mdi-garage",
	"mdi mdi-garage-alert",
	"mdi mdi-garage-alert-variant",
	"mdi mdi-garage-open",
	"mdi mdi-garage-open-variant",
	"mdi mdi-garage-variant",
	"mdi mdi-gas-cylinder",
	"mdi mdi-gas-station",
	"mdi mdi-gas-station-off",
	"mdi mdi-gas-station-off-outline",
	"mdi mdi-gas-station-outline",
	"mdi mdi-gate",
	"mdi mdi-gate-and",
	"mdi mdi-gate-arrow-right",
	"mdi mdi-gate-nand",
	"mdi mdi-gate-nor",
	"mdi mdi-gate-not",
	"mdi mdi-gate-open",
	"mdi mdi-gate-or",
	"mdi mdi-gate-xnor",
	"mdi mdi-gate-xor",
	"mdi mdi-gatsby",
	"mdi mdi-gauge",
	"mdi mdi-gauge-empty",
	"mdi mdi-gauge-full",
	"mdi mdi-gauge-low",
	"mdi mdi-gavel",
	"mdi mdi-gender-female",
	"mdi mdi-gender-male",
	"mdi mdi-gender-male-female",
	"mdi mdi-gender-male-female-variant",
	"mdi mdi-gender-non-binary",
	"mdi mdi-gender-transgender",
	"mdi mdi-gentoo",
	"mdi mdi-gesture",
	"mdi mdi-gesture-double-tap",
	"mdi mdi-gesture-pinch",
	"mdi mdi-gesture-spread",
	"mdi mdi-gesture-swipe",
	"mdi mdi-gesture-swipe-down",
	"mdi mdi-gesture-swipe-horizontal",
	"mdi mdi-gesture-swipe-left",
	"mdi mdi-gesture-swipe-right",
	"mdi mdi-gesture-swipe-up",
	"mdi mdi-gesture-swipe-vertical",
	"mdi mdi-gesture-tap",
	"mdi mdi-gesture-tap-box",
	"mdi mdi-gesture-tap-button",
	"mdi mdi-gesture-tap-hold",
	"mdi mdi-gesture-two-double-tap",
	"mdi mdi-gesture-two-tap",
	"mdi mdi-ghost",
	"mdi mdi-ghost-off",
	"mdi mdi-gif",
	"mdi mdi-gift",
	"mdi mdi-gift-outline",
	"mdi mdi-git",
	"mdi mdi-github",
	"mdi mdi-gitlab",
	"mdi mdi-glass-cocktail",
	"mdi mdi-glass-flute",
	"mdi mdi-glass-mug",
	"mdi mdi-glass-mug-variant",
	"mdi mdi-glass-pint-outline",
	"mdi mdi-glass-stange",
	"mdi mdi-glass-tulip",
	"mdi mdi-glass-wine",
	"mdi mdi-glasses",
	"mdi mdi-globe-light",
	"mdi mdi-globe-model",
	"mdi mdi-gmail",
	"mdi mdi-gnome",
	"mdi mdi-go-kart",
	"mdi mdi-go-kart-track",
	"mdi mdi-gog",
	"mdi mdi-gold",
	"mdi mdi-golf",
	"mdi mdi-golf-cart",
	"mdi mdi-golf-tee",
	"mdi mdi-gondola",
	"mdi mdi-goodreads",
	"mdi mdi-google",
	"mdi mdi-google-ads",
	"mdi mdi-google-analytics",
	"mdi mdi-google-assistant",
	"mdi mdi-google-cardboard",
	"mdi mdi-google-chrome",
	"mdi mdi-google-circles",
	"mdi mdi-google-circles-communities",
	"mdi mdi-google-circles-extended",
	"mdi mdi-google-circles-group",
	"mdi mdi-google-classroom",
	"mdi mdi-google-cloud",
	"mdi mdi-google-controller",
	"mdi mdi-google-controller-off",
	"mdi mdi-google-downasaur",
	"mdi mdi-google-drive",
	"mdi mdi-google-earth",
	"mdi mdi-google-fit",
	"mdi mdi-google-glass",
	"mdi mdi-google-hangouts",
	"mdi mdi-google-home",
	"mdi mdi-google-keep",
	"mdi mdi-google-lens",
	"mdi mdi-google-maps",
	"mdi mdi-google-my-business",
	"mdi mdi-google-nearby",
	"mdi mdi-google-photos",
	"mdi mdi-google-play",
	"mdi mdi-google-plus",
	"mdi mdi-google-podcast",
	"mdi mdi-google-spreadsheet",
	"mdi mdi-google-street-view",
	"mdi mdi-google-translate",
	"mdi mdi-gradient",
	"mdi mdi-grain",
	"mdi mdi-graph",
	"mdi mdi-graph-outline",
	"mdi mdi-graphql",
	"mdi mdi-grass",
	"mdi mdi-grave-stone",
	"mdi mdi-grease-pencil",
	"mdi mdi-greater-than",
	"mdi mdi-greater-than-or-equal",
	"mdi mdi-grid",
	"mdi mdi-grid-large",
	"mdi mdi-grid-off",
	"mdi mdi-grill",
	"mdi mdi-grill-outline",
	"mdi mdi-group",
	"mdi mdi-guitar-acoustic",
	"mdi mdi-guitar-electric",
	"mdi mdi-guitar-pick",
	"mdi mdi-guitar-pick-outline",
	"mdi mdi-guy-fawkes-mask",
	"mdi mdi-hail",
	"mdi mdi-hair-dryer",
	"mdi mdi-hair-dryer-outline",
	"mdi mdi-halloween",
	"mdi mdi-hamburger",
	"mdi mdi-hammer",
	"mdi mdi-hammer-screwdriver",
	"mdi mdi-hammer-wrench",
	"mdi mdi-hand",
	"mdi mdi-hand-heart",
	"mdi mdi-hand-heart-outline",
	"mdi mdi-hand-left",
	"mdi mdi-hand-okay",
	"mdi mdi-hand-peace",
	"mdi mdi-hand-peace-variant",
	"mdi mdi-hand-pointing-down",
	"mdi mdi-hand-pointing-left",
	"mdi mdi-hand-pointing-right",
	"mdi mdi-hand-pointing-up",
	"mdi mdi-hand-right",
	"mdi mdi-hand-saw",
	"mdi mdi-hand-wash",
	"mdi mdi-hand-wash-outline",
	"mdi mdi-hand-water",
	"mdi mdi-handball",
	"mdi mdi-handcuffs",
	"mdi mdi-handshake",
	"mdi mdi-handshake-outline",
	"mdi mdi-hanger",
	"mdi mdi-hard-hat",
	"mdi mdi-harddisk",
	"mdi mdi-harddisk-plus",
	"mdi mdi-harddisk-remove",
	"mdi mdi-hat-fedora",
	"mdi mdi-hazard-lights",
	"mdi mdi-hdr",
	"mdi mdi-hdr-off",
	"mdi mdi-head",
	"mdi mdi-head-alert",
	"mdi mdi-head-alert-outline",
	"mdi mdi-head-check",
	"mdi mdi-head-check-outline",
	"mdi mdi-head-cog",
	"mdi mdi-head-cog-outline",
	"mdi mdi-head-dots-horizontal",
	"mdi mdi-head-dots-horizontal-outline",
	"mdi mdi-head-flash",
	"mdi mdi-head-flash-outline",
	"mdi mdi-head-heart",
	"mdi mdi-head-heart-outline",
	"mdi mdi-head-lightbulb",
	"mdi mdi-head-lightbulb-outline",
	"mdi mdi-head-minus",
	"mdi mdi-head-minus-outline",
	"mdi mdi-head-outline",
	"mdi mdi-head-plus",
	"mdi mdi-head-plus-outline",
	"mdi mdi-head-question",
	"mdi mdi-head-question-outline",
	"mdi mdi-head-remove",
	"mdi mdi-head-remove-outline",
	"mdi mdi-head-snowflake",
	"mdi mdi-head-snowflake-outline",
	"mdi mdi-head-sync",
	"mdi mdi-head-sync-outline",
	"mdi mdi-headphones",
	"mdi mdi-headphones-bluetooth",
	"mdi mdi-headphones-box",
	"mdi mdi-headphones-off",
	"mdi mdi-headphones-settings",
	"mdi mdi-headset",
	"mdi mdi-headset-dock",
	"mdi mdi-headset-off",
	"mdi mdi-heart",
	"mdi mdi-heart-box",
	"mdi mdi-heart-box-outline",
	"mdi mdi-heart-broken",
	"mdi mdi-heart-broken-outline",
	"mdi mdi-heart-circle",
	"mdi mdi-heart-circle-outline",
	"mdi mdi-heart-flash",
	"mdi mdi-heart-half",
	"mdi mdi-heart-half-full",
	"mdi mdi-heart-half-outline",
	"mdi mdi-heart-minus",
	"mdi mdi-heart-minus-outline",
	"mdi mdi-heart-multiple",
	"mdi mdi-heart-multiple-outline",
	"mdi mdi-heart-off",
	"mdi mdi-heart-off-outline",
	"mdi mdi-heart-outline",
	"mdi mdi-heart-plus",
	"mdi mdi-heart-plus-outline",
	"mdi mdi-heart-pulse",
	"mdi mdi-heart-remove",
	"mdi mdi-heart-remove-outline",
	"mdi mdi-helicopter",
	"mdi mdi-help",
	"mdi mdi-help-box",
	"mdi mdi-help-circle",
	"mdi mdi-help-circle-outline",
	"mdi mdi-help-network",
	"mdi mdi-help-network-outline",
	"mdi mdi-help-rhombus",
	"mdi mdi-help-rhombus-outline",
	"mdi mdi-hexadecimal",
	"mdi mdi-hexagon",
	"mdi mdi-hexagon-multiple",
	"mdi mdi-hexagon-multiple-outline",
	"mdi mdi-hexagon-outline",
	"mdi mdi-hexagon-slice-1",
	"mdi mdi-hexagon-slice-2",
	"mdi mdi-hexagon-slice-3",
	"mdi mdi-hexagon-slice-4",
	"mdi mdi-hexagon-slice-5",
	"mdi mdi-hexagon-slice-6",
	"mdi mdi-hexagram",
	"mdi mdi-hexagram-outline",
	"mdi mdi-high-definition",
	"mdi mdi-high-definition-box",
	"mdi mdi-highway",
	"mdi mdi-hiking",
	"mdi mdi-hinduism",
	"mdi mdi-history",
	"mdi mdi-hockey-puck",
	"mdi mdi-hockey-sticks",
	"mdi mdi-hololens",
	"mdi mdi-home",
	"mdi mdi-home-account",
	"mdi mdi-home-alert",
	"mdi mdi-home-analytics",
	"mdi mdi-home-assistant",
	"mdi mdi-home-automation",
	"mdi mdi-home-circle",
	"mdi mdi-home-circle-outline",
	"mdi mdi-home-city",
	"mdi mdi-home-city-outline",
	"mdi mdi-home-currency-usd",
	"mdi mdi-home-edit",
	"mdi mdi-home-edit-outline",
	"mdi mdi-home-export-outline",
	"mdi mdi-home-flood",
	"mdi mdi-home-floor-0",
	"mdi mdi-home-floor-1",
	"mdi mdi-home-floor-2",
	"mdi mdi-home-floor-3",
	"mdi mdi-home-floor-a",
	"mdi mdi-home-floor-b",
	"mdi mdi-home-floor-g",
	"mdi mdi-home-floor-l",
	"mdi mdi-home-floor-negative-1",
	"mdi mdi-home-group",
	"mdi mdi-home-heart",
	"mdi mdi-home-import-outline",
	"mdi mdi-home-lightbulb",
	"mdi mdi-home-lightbulb-outline",
	"mdi mdi-home-lock",
	"mdi mdi-home-lock-open",
	"mdi mdi-home-map-marker",
	"mdi mdi-home-minus",
	"mdi mdi-home-minus-outline",
	"mdi mdi-home-modern",
	"mdi mdi-home-outline",
	"mdi mdi-home-plus",
	"mdi mdi-home-plus-outline",
	"mdi mdi-home-remove",
	"mdi mdi-home-remove-outline",
	"mdi mdi-home-roof",
	"mdi mdi-home-search",
	"mdi mdi-home-search-outline",
	"mdi mdi-home-thermometer",
	"mdi mdi-home-thermometer-outline",
	"mdi mdi-home-variant",
	"mdi mdi-home-variant-outline",
	"mdi mdi-hook",
	"mdi mdi-hook-off",
	"mdi mdi-hops",
	"mdi mdi-horizontal-rotate-clockwise",
	"mdi mdi-horizontal-rotate-counterclockwise",
	"mdi mdi-horseshoe",
	"mdi mdi-hospital",
	"mdi mdi-hospital-box",
	"mdi mdi-hospital-box-outline",
	"mdi mdi-hospital-building",
	"mdi mdi-hospital-marker",
	"mdi mdi-hot-tub",
	"mdi mdi-hours-24",
	"mdi mdi-hubspot",
	"mdi mdi-hulu",
	"mdi mdi-human",
	"mdi mdi-human-baby-changing-table",
	"mdi mdi-human-cane",
	"mdi mdi-human-capacity-decrease",
	"mdi mdi-human-capacity-increase",
	"mdi mdi-human-child",
	"mdi mdi-human-edit",
	"mdi mdi-human-female",
	"mdi mdi-human-female-boy",
	"mdi mdi-human-female-female",
	"mdi mdi-human-female-girl",
	"mdi mdi-human-greeting",
	"mdi mdi-human-greeting-proximity",
	"mdi mdi-human-handsdown",
	"mdi mdi-human-handsup",
	"mdi mdi-human-male",
	"mdi mdi-human-male-boy",
	"mdi mdi-human-male-child",
	"mdi mdi-human-male-female",
	"mdi mdi-human-male-girl",
	"mdi mdi-human-male-height",
	"mdi mdi-human-male-height-variant",
	"mdi mdi-human-male-male",
	"mdi mdi-human-pregnant",
	"mdi mdi-human-queue",
	"mdi mdi-human-scooter",
	"mdi mdi-human-wheelchair",
	"mdi mdi-humble-bundle",
	"mdi mdi-hvac",
	"mdi mdi-hvac-off",
	"mdi mdi-hydraulic-oil-level",
	"mdi mdi-hydraulic-oil-temperature",
	"mdi mdi-hydro-power",
	"mdi mdi-ice-cream",
	"mdi mdi-ice-cream-off",
	"mdi mdi-ice-pop",
	"mdi mdi-id-card",
	"mdi mdi-identifier",
	"mdi mdi-ideogram-cjk",
	"mdi mdi-ideogram-cjk-variant",
	"mdi mdi-iframe",
	"mdi mdi-iframe-array",
	"mdi mdi-iframe-array-outline",
	"mdi mdi-iframe-braces",
	"mdi mdi-iframe-braces-outline",
	"mdi mdi-iframe-outline",
	"mdi mdi-iframe-parentheses",
	"mdi mdi-iframe-parentheses-outline",
	"mdi mdi-iframe-variable",
	"mdi mdi-iframe-variable-outline",
	"mdi mdi-image",
	"mdi mdi-image-album",
	"mdi mdi-image-area",
	"mdi mdi-image-area-close",
	"mdi mdi-image-auto-adjust",
	"mdi mdi-image-broken",
	"mdi mdi-image-broken-variant",
	"mdi mdi-image-edit",
	"mdi mdi-image-edit-outline",
	"mdi mdi-image-filter-black-white",
	"mdi mdi-image-filter-center-focus",
	"mdi mdi-image-filter-center-focus-strong",
	"mdi mdi-image-filter-center-focus-strong-outline",
	"mdi mdi-image-filter-center-focus-weak",
	"mdi mdi-image-filter-drama",
	"mdi mdi-image-filter-frames",
	"mdi mdi-image-filter-hdr",
	"mdi mdi-image-filter-none",
	"mdi mdi-image-filter-tilt-shift",
	"mdi mdi-image-filter-vintage",
	"mdi mdi-image-frame",
	"mdi mdi-image-minus",
	"mdi mdi-image-move",
	"mdi mdi-image-multiple",
	"mdi mdi-image-multiple-outline",
	"mdi mdi-image-off",
	"mdi mdi-image-off-outline",
	"mdi mdi-image-outline",
	"mdi mdi-image-plus",
	"mdi mdi-image-remove",
	"mdi mdi-image-search",
	"mdi mdi-image-search-outline",
	"mdi mdi-image-size-select-actual",
	"mdi mdi-image-size-select-large",
	"mdi mdi-image-size-select-small",
	"mdi mdi-import",
	"mdi mdi-inbox",
	"mdi mdi-inbox-arrow-down",
	"mdi mdi-inbox-arrow-down-outline",
	"mdi mdi-inbox-arrow-up",
	"mdi mdi-inbox-arrow-up-outline",
	"mdi mdi-inbox-full",
	"mdi mdi-inbox-full-outline",
	"mdi mdi-inbox-multiple",
	"mdi mdi-inbox-multiple-outline",
	"mdi mdi-inbox-outline",
	"mdi mdi-inbox-remove",
	"mdi mdi-inbox-remove-outline",
	"mdi mdi-incognito",
	"mdi mdi-incognito-circle",
	"mdi mdi-incognito-circle-off",
	"mdi mdi-incognito-off",
	"mdi mdi-infinity",
	"mdi mdi-information",
	"mdi mdi-information-outline",
	"mdi mdi-information-variant",
	"mdi mdi-instagram",
	"mdi mdi-instrument-triangle",
	"mdi mdi-invert-colors",
	"mdi mdi-invert-colors-off",
	"mdi mdi-iobroker",
	"mdi mdi-ip",
	"mdi mdi-ip-network",
	"mdi mdi-ip-network-outline",
	"mdi mdi-ipod",
	"mdi mdi-islam",
	"mdi mdi-island",
	"mdi mdi-iv-bag",
	"mdi mdi-jabber",
	"mdi mdi-jeepney",
	"mdi mdi-jellyfish",
	"mdi mdi-jellyfish-outline",
	"mdi mdi-jira",
	"mdi mdi-jquery",
	"mdi mdi-jsfiddle",
	"mdi mdi-judaism",
	"mdi mdi-jump-rope",
	"mdi mdi-kabaddi",
	"mdi mdi-kangaroo",
	"mdi mdi-karate",
	"mdi mdi-keg",
	"mdi mdi-kettle",
	"mdi mdi-kettle-alert",
	"mdi mdi-kettle-alert-outline",
	"mdi mdi-kettle-off",
	"mdi mdi-kettle-off-outline",
	"mdi mdi-kettle-outline",
	"mdi mdi-kettle-steam",
	"mdi mdi-kettle-steam-outline",
	"mdi mdi-kettlebell",
	"mdi mdi-key",
	"mdi mdi-key-arrow-right",
	"mdi mdi-key-chain",
	"mdi mdi-key-chain-variant",
	"mdi mdi-key-change",
	"mdi mdi-key-link",
	"mdi mdi-key-minus",
	"mdi mdi-key-outline",
	"mdi mdi-key-plus",
	"mdi mdi-key-remove",
	"mdi mdi-key-star",
	"mdi mdi-key-variant",
	"mdi mdi-key-wireless",
	"mdi mdi-keyboard",
	"mdi mdi-keyboard-backspace",
	"mdi mdi-keyboard-caps",
	"mdi mdi-keyboard-close",
	"mdi mdi-keyboard-esc",
	"mdi mdi-keyboard-f1",
	"mdi mdi-keyboard-f10",
	"mdi mdi-keyboard-f11",
	"mdi mdi-keyboard-f12",
	"mdi mdi-keyboard-f2",
	"mdi mdi-keyboard-f3",
	"mdi mdi-keyboard-f4",
	"mdi mdi-keyboard-f5",
	"mdi mdi-keyboard-f6",
	"mdi mdi-keyboard-f7",
	"mdi mdi-keyboard-f8",
	"mdi mdi-keyboard-f9",
	"mdi mdi-keyboard-off",
	"mdi mdi-keyboard-off-outline",
	"mdi mdi-keyboard-outline",
	"mdi mdi-keyboard-return",
	"mdi mdi-keyboard-settings",
	"mdi mdi-keyboard-settings-outline",
	"mdi mdi-keyboard-space",
	"mdi mdi-keyboard-tab",
	"mdi mdi-keyboard-variant",
	"mdi mdi-khanda",
	"mdi mdi-kickstarter",
	"mdi mdi-klingon",
	"mdi mdi-knife",
	"mdi mdi-knife-military",
	"mdi mdi-kodi",
	"mdi mdi-kubernetes",
	"mdi mdi-label",
	"mdi mdi-label-multiple",
	"mdi mdi-label-multiple-outline",
	"mdi mdi-label-off",
	"mdi mdi-label-off-outline",
	"mdi mdi-label-outline",
	"mdi mdi-label-percent",
	"mdi mdi-label-percent-outline",
	"mdi mdi-label-variant",
	"mdi mdi-label-variant-outline",
	"mdi mdi-ladder",
	"mdi mdi-ladybug",
	"mdi mdi-lambda",
	"mdi mdi-lamp",
	"mdi mdi-lamps",
	"mdi mdi-lan",
	"mdi mdi-lan-check",
	"mdi mdi-lan-connect",
	"mdi mdi-lan-disconnect",
	"mdi mdi-lan-pending",
	"mdi mdi-language-c",
	"mdi mdi-language-cpp",
	"mdi mdi-language-csharp",
	"mdi mdi-language-css3",
	"mdi mdi-language-fortran",
	"mdi mdi-language-go",
	"mdi mdi-language-haskell",
	"mdi mdi-language-html5",
	"mdi mdi-language-java",
	"mdi mdi-language-javascript",
	"mdi mdi-language-kotlin",
	"mdi mdi-language-lua",
	"mdi mdi-language-markdown",
	"mdi mdi-language-markdown-outline",
	"mdi mdi-language-php",
	"mdi mdi-language-python",
	"mdi mdi-language-r",
	"mdi mdi-language-ruby",
	"mdi mdi-language-ruby-on-rails",
	"mdi mdi-language-swift",
	"mdi mdi-language-typescript",
	"mdi mdi-language-xaml",
	"mdi mdi-laptop",
	"mdi mdi-laptop-chromebook",
	"mdi mdi-laptop-mac",
	"mdi mdi-laptop-off",
	"mdi mdi-laptop-windows",
	"mdi mdi-laravel",
	"mdi mdi-laser-pointer",
	"mdi mdi-lasso",
	"mdi mdi-lastpass",
	"mdi mdi-latitude",
	"mdi mdi-launch",
	"mdi mdi-lava-lamp",
	"mdi mdi-layers",
	"mdi mdi-layers-minus",
	"mdi mdi-layers-off",
	"mdi mdi-layers-off-outline",
	"mdi mdi-layers-outline",
	"mdi mdi-layers-plus",
	"mdi mdi-layers-remove",
	"mdi mdi-layers-search",
	"mdi mdi-layers-search-outline",
	"mdi mdi-layers-triple",
	"mdi mdi-layers-triple-outline",
	"mdi mdi-lead-pencil",
	"mdi mdi-leaf",
	"mdi mdi-leaf-maple",
	"mdi mdi-leaf-maple-off",
	"mdi mdi-leaf-off",
	"mdi mdi-leak",
	"mdi mdi-leak-off",
	"mdi mdi-led-off",
	"mdi mdi-led-on",
	"mdi mdi-led-outline",
	"mdi mdi-led-strip",
	"mdi mdi-led-strip-variant",
	"mdi mdi-led-variant-off",
	"mdi mdi-led-variant-on",
	"mdi mdi-led-variant-outline",
	"mdi mdi-leek",
	"mdi mdi-less-than",
	"mdi mdi-less-than-or-equal",
	"mdi mdi-library",
	"mdi mdi-library-shelves",
	"mdi mdi-license",
	"mdi mdi-lifebuoy",
	"mdi mdi-light-switch",
	"mdi mdi-lightbulb",
	"mdi mdi-lightbulb-cfl",
	"mdi mdi-lightbulb-cfl-off",
	"mdi mdi-lightbulb-cfl-spiral",
	"mdi mdi-lightbulb-cfl-spiral-off",
	"mdi mdi-lightbulb-group",
	"mdi mdi-lightbulb-group-off",
	"mdi mdi-lightbulb-group-off-outline",
	"mdi mdi-lightbulb-group-outline",
	"mdi mdi-lightbulb-multiple",
	"mdi mdi-lightbulb-multiple-off",
	"mdi mdi-lightbulb-multiple-off-outline",
	"mdi mdi-lightbulb-multiple-outline",
	"mdi mdi-lightbulb-off",
	"mdi mdi-lightbulb-off-outline",
	"mdi mdi-lightbulb-on",
	"mdi mdi-lightbulb-on-outline",
	"mdi mdi-lightbulb-outline",
	"mdi mdi-lighthouse",
	"mdi mdi-lighthouse-on",
	"mdi mdi-lightning-bolt",
	"mdi mdi-lightning-bolt-outline",
	"mdi mdi-lingerie",
	"mdi mdi-link",
	"mdi mdi-link-box",
	"mdi mdi-link-box-outline",
	"mdi mdi-link-box-variant",
	"mdi mdi-link-box-variant-outline",
	"mdi mdi-link-lock",
	"mdi mdi-link-off",
	"mdi mdi-link-plus",
	"mdi mdi-link-variant",
	"mdi mdi-link-variant-minus",
	"mdi mdi-link-variant-off",
	"mdi mdi-link-variant-plus",
	"mdi mdi-link-variant-remove",
	"mdi mdi-linkedin",
	"mdi mdi-linux",
	"mdi mdi-linux-mint",
	"mdi mdi-lipstick",
	"mdi mdi-list-status",
	"mdi mdi-litecoin",
	"mdi mdi-loading",
	"mdi mdi-location-enter",
	"mdi mdi-location-exit",
	"mdi mdi-lock",
	"mdi mdi-lock-alert",
	"mdi mdi-lock-check",
	"mdi mdi-lock-clock",
	"mdi mdi-lock-open",
	"mdi mdi-lock-open-alert",
	"mdi mdi-lock-open-check",
	"mdi mdi-lock-open-outline",
	"mdi mdi-lock-open-variant",
	"mdi mdi-lock-open-variant-outline",
	"mdi mdi-lock-outline",
	"mdi mdi-lock-pattern",
	"mdi mdi-lock-plus",
	"mdi mdi-lock-question",
	"mdi mdi-lock-reset",
	"mdi mdi-lock-smart",
	"mdi mdi-locker",
	"mdi mdi-locker-multiple",
	"mdi mdi-login",
	"mdi mdi-login-variant",
	"mdi mdi-logout",
	"mdi mdi-logout-variant",
	"mdi mdi-longitude",
	"mdi mdi-looks",
	"mdi mdi-lotion",
	"mdi mdi-lotion-outline",
	"mdi mdi-lotion-plus",
	"mdi mdi-lotion-plus-outline",
	"mdi mdi-loupe",
	"mdi mdi-lumx",
	"mdi mdi-lungs",
	"mdi mdi-magnet",
	"mdi mdi-magnet-on",
	"mdi mdi-magnify",
	"mdi mdi-magnify-close",
	"mdi mdi-magnify-minus",
	"mdi mdi-magnify-minus-cursor",
	"mdi mdi-magnify-minus-outline",
	"mdi mdi-magnify-plus",
	"mdi mdi-magnify-plus-cursor",
	"mdi mdi-magnify-plus-outline",
	"mdi mdi-magnify-remove-cursor",
	"mdi mdi-magnify-remove-outline",
	"mdi mdi-magnify-scan",
	"mdi mdi-mail",
	"mdi mdi-mailbox",
	"mdi mdi-mailbox-open",
	"mdi mdi-mailbox-open-outline",
	"mdi mdi-mailbox-open-up",
	"mdi mdi-mailbox-open-up-outline",
	"mdi mdi-mailbox-outline",
	"mdi mdi-mailbox-up",
	"mdi mdi-mailbox-up-outline",
	"mdi mdi-map",
	"mdi mdi-map-check",
	"mdi mdi-map-check-outline",
	"mdi mdi-map-clock",
	"mdi mdi-map-clock-outline",
	"mdi mdi-map-legend",
	"mdi mdi-map-marker",
	"mdi mdi-map-marker-alert",
	"mdi mdi-map-marker-alert-outline",
	"mdi mdi-map-marker-check",
	"mdi mdi-map-marker-check-outline",
	"mdi mdi-map-marker-circle",
	"mdi mdi-map-marker-distance",
	"mdi mdi-map-marker-down",
	"mdi mdi-map-marker-left",
	"mdi mdi-map-marker-left-outline",
	"mdi mdi-map-marker-minus",
	"mdi mdi-map-marker-minus-outline",
	"mdi mdi-map-marker-multiple",
	"mdi mdi-map-marker-multiple-outline",
	"mdi mdi-map-marker-off",
	"mdi mdi-map-marker-off-outline",
	"mdi mdi-map-marker-outline",
	"mdi mdi-map-marker-path",
	"mdi mdi-map-marker-plus",
	"mdi mdi-map-marker-plus-outline",
	"mdi mdi-map-marker-question",
	"mdi mdi-map-marker-question-outline",
	"mdi mdi-map-marker-radius",
	"mdi mdi-map-marker-radius-outline",
	"mdi mdi-map-marker-remove",
	"mdi mdi-map-marker-remove-outline",
	"mdi mdi-map-marker-remove-variant",
	"mdi mdi-map-marker-right",
	"mdi mdi-map-marker-right-outline",
	"mdi mdi-map-marker-up",
	"mdi mdi-map-minus",
	"mdi mdi-map-outline",
	"mdi mdi-map-plus",
	"mdi mdi-map-search",
	"mdi mdi-map-search-outline",
	"mdi mdi-mapbox",
	"mdi mdi-margin",
	"mdi mdi-marker",
	"mdi mdi-marker-cancel",
	"mdi mdi-marker-check",
	"mdi mdi-mastodon",
	"mdi mdi-material-design",
	"mdi mdi-material-ui",
	"mdi mdi-math-compass",
	"mdi mdi-math-cos",
	"mdi mdi-math-integral",
	"mdi mdi-math-integral-box",
	"mdi mdi-math-log",
	"mdi mdi-math-norm",
	"mdi mdi-math-norm-box",
	"mdi mdi-math-sin",
	"mdi mdi-math-tan",
	"mdi mdi-matrix",
	"mdi mdi-medal",
	"mdi mdi-medal-outline",
	"mdi mdi-medical-bag",
	"mdi mdi-meditation",
	"mdi mdi-memory",
	"mdi mdi-menu",
	"mdi mdi-menu-down",
	"mdi mdi-menu-down-outline",
	"mdi mdi-menu-left",
	"mdi mdi-menu-left-outline",
	"mdi mdi-menu-open",
	"mdi mdi-menu-right",
	"mdi mdi-menu-right-outline",
	"mdi mdi-menu-swap",
	"mdi mdi-menu-swap-outline",
	"mdi mdi-menu-up",
	"mdi mdi-menu-up-outline",
	"mdi mdi-merge",
	"mdi mdi-message",
	"mdi mdi-message-alert",
	"mdi mdi-message-alert-outline",
	"mdi mdi-message-arrow-left",
	"mdi mdi-message-arrow-left-outline",
	"mdi mdi-message-arrow-right",
	"mdi mdi-message-arrow-right-outline",
	"mdi mdi-message-bookmark",
	"mdi mdi-message-bookmark-outline",
	"mdi mdi-message-bulleted",
	"mdi mdi-message-bulleted-off",
	"mdi mdi-message-cog",
	"mdi mdi-message-cog-outline",
	"mdi mdi-message-draw",
	"mdi mdi-message-flash",
	"mdi mdi-message-flash-outline",
	"mdi mdi-message-image",
	"mdi mdi-message-image-outline",
	"mdi mdi-message-lock",
	"mdi mdi-message-lock-outline",
	"mdi mdi-message-minus",
	"mdi mdi-message-minus-outline",
	"mdi mdi-message-outline",
	"mdi mdi-message-plus",
	"mdi mdi-message-plus-outline",
	"mdi mdi-message-processing",
	"mdi mdi-message-processing-outline",
	"mdi mdi-message-reply",
	"mdi mdi-message-reply-text",
	"mdi mdi-message-settings",
	"mdi mdi-message-settings-outline",
	"mdi mdi-message-text",
	"mdi mdi-message-text-clock",
	"mdi mdi-message-text-clock-outline",
	"mdi mdi-message-text-lock",
	"mdi mdi-message-text-lock-outline",
	"mdi mdi-message-text-outline",
	"mdi mdi-message-video",
	"mdi mdi-meteor",
	"mdi mdi-metronome",
	"mdi mdi-metronome-tick",
	"mdi mdi-micro-sd",
	"mdi mdi-microphone",
	"mdi mdi-microphone-minus",
	"mdi mdi-microphone-off",
	"mdi mdi-microphone-outline",
	"mdi mdi-microphone-plus",
	"mdi mdi-microphone-settings",
	"mdi mdi-microphone-variant",
	"mdi mdi-microphone-variant-off",
	"mdi mdi-microscope",
	"mdi mdi-microsoft",
	"mdi mdi-microsoft-access",
	"mdi mdi-microsoft-azure",
	"mdi mdi-microsoft-azure-devops",
	"mdi mdi-microsoft-bing",
	"mdi mdi-microsoft-dynamics-365",
	"mdi mdi-microsoft-edge",
	"mdi mdi-microsoft-edge-legacy",
	"mdi mdi-microsoft-excel",
	"mdi mdi-microsoft-internet-explorer",
	"mdi mdi-microsoft-office",
	"mdi mdi-microsoft-onedrive",
	"mdi mdi-microsoft-onenote",
	"mdi mdi-microsoft-outlook",
	"mdi mdi-microsoft-powerpoint",
	"mdi mdi-microsoft-sharepoint",
	"mdi mdi-microsoft-teams",
	"mdi mdi-microsoft-visual-studio",
	"mdi mdi-microsoft-visual-studio-code",
	"mdi mdi-microsoft-windows",
	"mdi mdi-microsoft-windows-classic",
	"mdi mdi-microsoft-word",
	"mdi mdi-microsoft-xbox",
	"mdi mdi-microsoft-xbox-controller",
	"mdi mdi-microsoft-xbox-controller-battery-alert",
	"mdi mdi-microsoft-xbox-controller-battery-charging",
	"mdi mdi-microsoft-xbox-controller-battery-empty",
	"mdi mdi-microsoft-xbox-controller-battery-full",
	"mdi mdi-microsoft-xbox-controller-battery-low",
	"mdi mdi-microsoft-xbox-controller-battery-medium",
	"mdi mdi-microsoft-xbox-controller-battery-unknown",
	"mdi mdi-microsoft-xbox-controller-menu",
	"mdi mdi-microsoft-xbox-controller-off",
	"mdi mdi-microsoft-xbox-controller-view",
	"mdi mdi-microsoft-yammer",
	"mdi mdi-microwave",
	"mdi mdi-microwave-off",
	"mdi mdi-middleware",
	"mdi mdi-middleware-outline",
	"mdi mdi-midi",
	"mdi mdi-midi-port",
	"mdi mdi-mine",
	"mdi mdi-minecraft",
	"mdi mdi-mini-sd",
	"mdi mdi-minidisc",
	"mdi mdi-minus",
	"mdi mdi-minus-box",
	"mdi mdi-minus-box-multiple",
	"mdi mdi-minus-box-multiple-outline",
	"mdi mdi-minus-box-outline",
	"mdi mdi-minus-circle",
	"mdi mdi-minus-circle-multiple",
	"mdi mdi-minus-circle-multiple-outline",
	"mdi mdi-minus-circle-off",
	"mdi mdi-minus-circle-off-outline",
	"mdi mdi-minus-circle-outline",
	"mdi mdi-minus-network",
	"mdi mdi-minus-network-outline",
	"mdi mdi-mirror",
	"mdi mdi-mixed-martial-arts",
	"mdi mdi-mixed-reality",
	"mdi mdi-molecule",
	"mdi mdi-molecule-co",
	"mdi mdi-molecule-co2",
	"mdi mdi-monitor",
	"mdi mdi-monitor-cellphone",
	"mdi mdi-monitor-cellphone-star",
	"mdi mdi-monitor-clean",
	"mdi mdi-monitor-dashboard",
	"mdi mdi-monitor-edit",
	"mdi mdi-monitor-eye",
	"mdi mdi-monitor-lock",
	"mdi mdi-monitor-multiple",
	"mdi mdi-monitor-off",
	"mdi mdi-monitor-screenshot",
	"mdi mdi-monitor-share",
	"mdi mdi-monitor-speaker",
	"mdi mdi-monitor-speaker-off",
	"mdi mdi-monitor-star",
	"mdi mdi-moon-first-quarter",
	"mdi mdi-moon-full",
	"mdi mdi-moon-last-quarter",
	"mdi mdi-moon-new",
	"mdi mdi-moon-waning-crescent",
	"mdi mdi-moon-waning-gibbous",
	"mdi mdi-moon-waxing-crescent",
	"mdi mdi-moon-waxing-gibbous",
	"mdi mdi-moped",
	"mdi mdi-more",
	"mdi mdi-mother-heart",
	"mdi mdi-mother-nurse",
	"mdi mdi-motion",
	"mdi mdi-motion-outline",
	"mdi mdi-motion-pause",
	"mdi mdi-motion-pause-outline",
	"mdi mdi-motion-play",
	"mdi mdi-motion-play-outline",
	"mdi mdi-motion-sensor",
	"mdi mdi-motion-sensor-off",
	"mdi mdi-motorbike",
	"mdi mdi-mouse",
	"mdi mdi-mouse-bluetooth",
	"mdi mdi-mouse-move-down",
	"mdi mdi-mouse-move-up",
	"mdi mdi-mouse-move-vertical",
	"mdi mdi-mouse-off",
	"mdi mdi-mouse-variant",
	"mdi mdi-mouse-variant-off",
	"mdi mdi-move-resize",
	"mdi mdi-move-resize-variant",
	"mdi mdi-movie",
	"mdi mdi-movie-edit",
	"mdi mdi-movie-edit-outline",
	"mdi mdi-movie-filter",
	"mdi mdi-movie-filter-outline",
	"mdi mdi-movie-open",
	"mdi mdi-movie-open-outline",
	"mdi mdi-movie-outline",
	"mdi mdi-movie-roll",
	"mdi mdi-movie-search",
	"mdi mdi-movie-search-outline",
	"mdi mdi-muffin",
	"mdi mdi-multiplication",
	"mdi mdi-multiplication-box",
	"mdi mdi-mushroom",
	"mdi mdi-mushroom-off",
	"mdi mdi-mushroom-off-outline",
	"mdi mdi-mushroom-outline",
	"mdi mdi-music",
	"mdi mdi-music-accidental-double-flat",
	"mdi mdi-music-accidental-double-sharp",
	"mdi mdi-music-accidental-flat",
	"mdi mdi-music-accidental-natural",
	"mdi mdi-music-accidental-sharp",
	"mdi mdi-music-box",
	"mdi mdi-music-box-multiple",
	"mdi mdi-music-box-multiple-outline",
	"mdi mdi-music-box-outline",
	"mdi mdi-music-circle",
	"mdi mdi-music-circle-outline",
	"mdi mdi-music-clef-alto",
	"mdi mdi-music-clef-bass",
	"mdi mdi-music-clef-treble",
	"mdi mdi-music-note",
	"mdi mdi-music-note-bluetooth",
	"mdi mdi-music-note-bluetooth-off",
	"mdi mdi-music-note-eighth",
	"mdi mdi-music-note-eighth-dotted",
	"mdi mdi-music-note-half",
	"mdi mdi-music-note-half-dotted",
	"mdi mdi-music-note-off",
	"mdi mdi-music-note-off-outline",
	"mdi mdi-music-note-outline",
	"mdi mdi-music-note-plus",
	"mdi mdi-music-note-quarter",
	"mdi mdi-music-note-quarter-dotted",
	"mdi mdi-music-note-sixteenth",
	"mdi mdi-music-note-sixteenth-dotted",
	"mdi mdi-music-note-whole",
	"mdi mdi-music-note-whole-dotted",
	"mdi mdi-music-off",
	"mdi mdi-music-rest-eighth",
	"mdi mdi-music-rest-half",
	"mdi mdi-music-rest-quarter",
	"mdi mdi-music-rest-sixteenth",
	"mdi mdi-music-rest-whole",
	"mdi mdi-nail",
	"mdi mdi-nas",
	"mdi mdi-nativescript",
	"mdi mdi-nature",
	"mdi mdi-nature-people",
	"mdi mdi-navigation",
	"mdi mdi-near-me",
	"mdi mdi-necklace",
	"mdi mdi-needle",
	"mdi mdi-netflix",
	"mdi mdi-network",
	"mdi mdi-network-off",
	"mdi mdi-network-off-outline",
	"mdi mdi-network-outline",
	"mdi mdi-network-strength-1",
	"mdi mdi-network-strength-1-alert",
	"mdi mdi-network-strength-2",
	"mdi mdi-network-strength-2-alert",
	"mdi mdi-network-strength-3",
	"mdi mdi-network-strength-3-alert",
	"mdi mdi-network-strength-4",
	"mdi mdi-network-strength-4-alert",
	"mdi mdi-network-strength-off",
	"mdi mdi-network-strength-off-outline",
	"mdi mdi-network-strength-outline",
	"mdi mdi-new-box",
	"mdi mdi-newspaper",
	"mdi mdi-newspaper-minus",
	"mdi mdi-newspaper-plus",
	"mdi mdi-newspaper-variant",
	"mdi mdi-newspaper-variant-multiple",
	"mdi mdi-newspaper-variant-multiple-outline",
	"mdi mdi-newspaper-variant-outline",
	"mdi mdi-nfc",
	"mdi mdi-nfc-search-variant",
	"mdi mdi-nfc-tap",
	"mdi mdi-nfc-variant",
	"mdi mdi-nfc-variant-off",
	"mdi mdi-ninja",
	"mdi mdi-nintendo-game-boy",
	"mdi mdi-nintendo-switch",
	"mdi mdi-nintendo-wii",
	"mdi mdi-nintendo-wiiu",
	"mdi mdi-nix",
	"mdi mdi-nodejs",
	"mdi mdi-noodles",
	"mdi mdi-not-equal",
	"mdi mdi-not-equal-variant",
	"mdi mdi-note",
	"mdi mdi-note-multiple",
	"mdi mdi-note-multiple-outline",
	"mdi mdi-note-outline",
	"mdi mdi-note-plus",
	"mdi mdi-note-plus-outline",
	"mdi mdi-note-text",
	"mdi mdi-note-text-outline",
	"mdi mdi-notebook",
	"mdi mdi-notebook-check",
	"mdi mdi-notebook-check-outline",
	"mdi mdi-notebook-edit",
	"mdi mdi-notebook-edit-outline",
	"mdi mdi-notebook-multiple",
	"mdi mdi-notebook-outline",
	"mdi mdi-notification-clear-all",
	"mdi mdi-npm",
	"mdi mdi-nuke",
	"mdi mdi-null",
	"mdi mdi-numeric",
	"mdi mdi-numeric-0",
	"mdi mdi-numeric-0-box",
	"mdi mdi-numeric-0-box-multiple",
	"mdi mdi-numeric-0-box-multiple-outline",
	"mdi mdi-numeric-0-box-outline",
	"mdi mdi-numeric-0-circle",
	"mdi mdi-numeric-0-circle-outline",
	"mdi mdi-numeric-1",
	"mdi mdi-numeric-1-box",
	"mdi mdi-numeric-1-box-multiple",
	"mdi mdi-numeric-1-box-multiple-outline",
	"mdi mdi-numeric-1-box-outline",
	"mdi mdi-numeric-1-circle",
	"mdi mdi-numeric-1-circle-outline",
	"mdi mdi-numeric-10",
	"mdi mdi-numeric-10-box",
	"mdi mdi-numeric-10-box-multiple",
	"mdi mdi-numeric-10-box-multiple-outline",
	"mdi mdi-numeric-10-box-outline",
	"mdi mdi-numeric-10-circle",
	"mdi mdi-numeric-10-circle-outline",
	"mdi mdi-numeric-2",
	"mdi mdi-numeric-2-box",
	"mdi mdi-numeric-2-box-multiple",
	"mdi mdi-numeric-2-box-multiple-outline",
	"mdi mdi-numeric-2-box-outline",
	"mdi mdi-numeric-2-circle",
	"mdi mdi-numeric-2-circle-outline",
	"mdi mdi-numeric-3",
	"mdi mdi-numeric-3-box",
	"mdi mdi-numeric-3-box-multiple",
	"mdi mdi-numeric-3-box-multiple-outline",
	"mdi mdi-numeric-3-box-outline",
	"mdi mdi-numeric-3-circle",
	"mdi mdi-numeric-3-circle-outline",
	"mdi mdi-numeric-4",
	"mdi mdi-numeric-4-box",
	"mdi mdi-numeric-4-box-multiple",
	"mdi mdi-numeric-4-box-multiple-outline",
	"mdi mdi-numeric-4-box-outline",
	"mdi mdi-numeric-4-circle",
	"mdi mdi-numeric-4-circle-outline",
	"mdi mdi-numeric-5",
	"mdi mdi-numeric-5-box",
	"mdi mdi-numeric-5-box-multiple",
	"mdi mdi-numeric-5-box-multiple-outline",
	"mdi mdi-numeric-5-box-outline",
	"mdi mdi-numeric-5-circle",
	"mdi mdi-numeric-5-circle-outline",
	"mdi mdi-numeric-6",
	"mdi mdi-numeric-6-box",
	"mdi mdi-numeric-6-box-multiple",
	"mdi mdi-numeric-6-box-multiple-outline",
	"mdi mdi-numeric-6-box-outline",
	"mdi mdi-numeric-6-circle",
	"mdi mdi-numeric-6-circle-outline",
	"mdi mdi-numeric-7",
	"mdi mdi-numeric-7-box",
	"mdi mdi-numeric-7-box-multiple",
	"mdi mdi-numeric-7-box-multiple-outline",
	"mdi mdi-numeric-7-box-outline",
	"mdi mdi-numeric-7-circle",
	"mdi mdi-numeric-7-circle-outline",
	"mdi mdi-numeric-8",
	"mdi mdi-numeric-8-box",
	"mdi mdi-numeric-8-box-multiple",
	"mdi mdi-numeric-8-box-multiple-outline",
	"mdi mdi-numeric-8-box-outline",
	"mdi mdi-numeric-8-circle",
	"mdi mdi-numeric-8-circle-outline",
	"mdi mdi-numeric-9",
	"mdi mdi-numeric-9-box",
	"mdi mdi-numeric-9-box-multiple",
	"mdi mdi-numeric-9-box-multiple-outline",
	"mdi mdi-numeric-9-box-outline",
	"mdi mdi-numeric-9-circle",
	"mdi mdi-numeric-9-circle-outline",
	"mdi mdi-numeric-9-plus",
	"mdi mdi-numeric-9-plus-box",
	"mdi mdi-numeric-9-plus-box-multiple",
	"mdi mdi-numeric-9-plus-box-multiple-outline",
	"mdi mdi-numeric-9-plus-box-outline",
	"mdi mdi-numeric-9-plus-circle",
	"mdi mdi-numeric-9-plus-circle-outline",
	"mdi mdi-numeric-negative-1",
	"mdi mdi-nut",
	"mdi mdi-nutrition",
	"mdi mdi-nuxt",
	"mdi mdi-oar",
	"mdi mdi-ocarina",
	"mdi mdi-oci",
	"mdi mdi-ocr",
	"mdi mdi-octagon",
	"mdi mdi-octagon-outline",
	"mdi mdi-octagram",
	"mdi mdi-octagram-outline",
	"mdi mdi-odnoklassniki",
	"mdi mdi-offer",
	"mdi mdi-office-building",
	"mdi mdi-office-building-marker",
	"mdi mdi-office-building-marker-outline",
	"mdi mdi-office-building-outline",
	"mdi mdi-oil",
	"mdi mdi-oil-lamp",
	"mdi mdi-oil-level",
	"mdi mdi-oil-temperature",
	"mdi mdi-omega",
	"mdi mdi-one-up",
	"mdi mdi-onepassword",
	"mdi mdi-opacity",
	"mdi mdi-open-in-app",
	"mdi mdi-open-in-new",
	"mdi mdi-open-source-initiative",
	"mdi mdi-openid",
	"mdi mdi-opera",
	"mdi mdi-orbit",
	"mdi mdi-order-alphabetical-ascending",
	"mdi mdi-order-alphabetical-descending",
	"mdi mdi-order-bool-ascending",
	"mdi mdi-order-bool-ascending-variant",
	"mdi mdi-order-bool-descending",
	"mdi mdi-order-bool-descending-variant",
	"mdi mdi-order-numeric-ascending",
	"mdi mdi-order-numeric-descending",
	"mdi mdi-origin",
	"mdi mdi-ornament",
	"mdi mdi-ornament-variant",
	"mdi mdi-outdoor-lamp",
	"mdi mdi-overscan",
	"mdi mdi-owl",
	"mdi mdi-pac-man",
	"mdi mdi-package",
	"mdi mdi-package-down",
	"mdi mdi-package-up",
	"mdi mdi-package-variant",
	"mdi mdi-package-variant-closed",
	"mdi mdi-page-first",
	"mdi mdi-page-last",
	"mdi mdi-page-layout-body",
	"mdi mdi-page-layout-footer",
	"mdi mdi-page-layout-header",
	"mdi mdi-page-layout-header-footer",
	"mdi mdi-page-layout-sidebar-left",
	"mdi mdi-page-layout-sidebar-right",
	"mdi mdi-page-next",
	"mdi mdi-page-next-outline",
	"mdi mdi-page-previous",
	"mdi mdi-page-previous-outline",
	"mdi mdi-pail",
	"mdi mdi-pail-minus",
	"mdi mdi-pail-minus-outline",
	"mdi mdi-pail-off",
	"mdi mdi-pail-off-outline",
	"mdi mdi-pail-outline",
	"mdi mdi-pail-plus",
	"mdi mdi-pail-plus-outline",
	"mdi mdi-pail-remove",
	"mdi mdi-pail-remove-outline",
	"mdi mdi-palette",
	"mdi mdi-palette-advanced",
	"mdi mdi-palette-outline",
	"mdi mdi-palette-swatch",
	"mdi mdi-palette-swatch-outline",
	"mdi mdi-palm-tree",
	"mdi mdi-pan",
	"mdi mdi-pan-bottom-left",
	"mdi mdi-pan-bottom-right",
	"mdi mdi-pan-down",
	"mdi mdi-pan-horizontal",
	"mdi mdi-pan-left",
	"mdi mdi-pan-right",
	"mdi mdi-pan-top-left",
	"mdi mdi-pan-top-right",
	"mdi mdi-pan-up",
	"mdi mdi-pan-vertical",
	"mdi mdi-panda",
	"mdi mdi-pandora",
	"mdi mdi-panorama",
	"mdi mdi-panorama-fisheye",
	"mdi mdi-panorama-horizontal",
	"mdi mdi-panorama-vertical",
	"mdi mdi-panorama-wide-angle",
	"mdi mdi-paper-cut-vertical",
	"mdi mdi-paper-roll",
	"mdi mdi-paper-roll-outline",
	"mdi mdi-paperclip",
	"mdi mdi-parachute",
	"mdi mdi-parachute-outline",
	"mdi mdi-parking",
	"mdi mdi-party-popper",
	"mdi mdi-passport",
	"mdi mdi-passport-biometric",
	"mdi mdi-pasta",
	"mdi mdi-patio-heater",
	"mdi mdi-patreon",
	"mdi mdi-pause",
	"mdi mdi-pause-circle",
	"mdi mdi-pause-circle-outline",
	"mdi mdi-pause-octagon",
	"mdi mdi-pause-octagon-outline",
	"mdi mdi-paw",
	"mdi mdi-paw-off",
	"mdi mdi-pdf-box",
	"mdi mdi-peace",
	"mdi mdi-peanut",
	"mdi mdi-peanut-off",
	"mdi mdi-peanut-off-outline",
	"mdi mdi-peanut-outline",
	"mdi mdi-pen",
	"mdi mdi-pen-lock",
	"mdi mdi-pen-minus",
	"mdi mdi-pen-off",
	"mdi mdi-pen-plus",
	"mdi mdi-pen-remove",
	"mdi mdi-pencil",
	"mdi mdi-pencil-box",
	"mdi mdi-pencil-box-multiple",
	"mdi mdi-pencil-box-multiple-outline",
	"mdi mdi-pencil-box-outline",
	"mdi mdi-pencil-circle",
	"mdi mdi-pencil-circle-outline",
	"mdi mdi-pencil-lock",
	"mdi mdi-pencil-lock-outline",
	"mdi mdi-pencil-minus",
	"mdi mdi-pencil-minus-outline",
	"mdi mdi-pencil-off",
	"mdi mdi-pencil-off-outline",
	"mdi mdi-pencil-outline",
	"mdi mdi-pencil-plus",
	"mdi mdi-pencil-plus-outline",
	"mdi mdi-pencil-remove",
	"mdi mdi-pencil-remove-outline",
	"mdi mdi-pencil-ruler",
	"mdi mdi-penguin",
	"mdi mdi-pentagon",
	"mdi mdi-pentagon-outline",
	"mdi mdi-percent",
	"mdi mdi-percent-outline",
	"mdi mdi-periodic-table",
	"mdi mdi-perspective-less",
	"mdi mdi-perspective-more",
	"mdi mdi-pharmacy",
	"mdi mdi-phone",
	"mdi mdi-phone-alert",
	"mdi mdi-phone-alert-outline",
	"mdi mdi-phone-bluetooth",
	"mdi mdi-phone-bluetooth-outline",
	"mdi mdi-phone-cancel",
	"mdi mdi-phone-cancel-outline",
	"mdi mdi-phone-check",
	"mdi mdi-phone-check-outline",
	"mdi mdi-phone-classic",
	"mdi mdi-phone-classic-off",
	"mdi mdi-phone-dial",
	"mdi mdi-phone-dial-outline",
	"mdi mdi-phone-forward",
	"mdi mdi-phone-forward-outline",
	"mdi mdi-phone-hangup",
	"mdi mdi-phone-hangup-outline",
	"mdi mdi-phone-in-talk",
	"mdi mdi-phone-in-talk-outline",
	"mdi mdi-phone-incoming",
	"mdi mdi-phone-incoming-outline",
	"mdi mdi-phone-lock",
	"mdi mdi-phone-lock-outline",
	"mdi mdi-phone-log",
	"mdi mdi-phone-log-outline",
	"mdi mdi-phone-message",
	"mdi mdi-phone-message-outline",
	"mdi mdi-phone-minus",
	"mdi mdi-phone-minus-outline",
	"mdi mdi-phone-missed",
	"mdi mdi-phone-missed-outline",
	"mdi mdi-phone-off",
	"mdi mdi-phone-off-outline",
	"mdi mdi-phone-outgoing",
	"mdi mdi-phone-outgoing-outline",
	"mdi mdi-phone-outline",
	"mdi mdi-phone-paused",
	"mdi mdi-phone-paused-outline",
	"mdi mdi-phone-plus",
	"mdi mdi-phone-plus-outline",
	"mdi mdi-phone-remove",
	"mdi mdi-phone-remove-outline",
	"mdi mdi-phone-return",
	"mdi mdi-phone-return-outline",
	"mdi mdi-phone-ring",
	"mdi mdi-phone-ring-outline",
	"mdi mdi-phone-rotate-landscape",
	"mdi mdi-phone-rotate-portrait",
	"mdi mdi-phone-settings",
	"mdi mdi-phone-settings-outline",
	"mdi mdi-phone-voip",
	"mdi mdi-pi",
	"mdi mdi-pi-box",
	"mdi mdi-pi-hole",
	"mdi mdi-piano",
	"mdi mdi-pickaxe",
	"mdi mdi-picture-in-picture-bottom-right",
	"mdi mdi-picture-in-picture-bottom-right-outline",
	"mdi mdi-picture-in-picture-top-right",
	"mdi mdi-picture-in-picture-top-right-outline",
	"mdi mdi-pier",
	"mdi mdi-pier-crane",
	"mdi mdi-pig",
	"mdi mdi-pig-variant",
	"mdi mdi-piggy-bank",
	"mdi mdi-pill",
	"mdi mdi-pillar",
	"mdi mdi-pin",
	"mdi mdi-pin-off",
	"mdi mdi-pin-off-outline",
	"mdi mdi-pin-outline",
	"mdi mdi-pine-tree",
	"mdi mdi-pine-tree-box",
	"mdi mdi-pine-tree-fire",
	"mdi mdi-pinterest",
	"mdi mdi-pinwheel",
	"mdi mdi-pinwheel-outline",
	"mdi mdi-pipe",
	"mdi mdi-pipe-disconnected",
	"mdi mdi-pipe-leak",
	"mdi mdi-pipe-wrench",
	"mdi mdi-pirate",
	"mdi mdi-pistol",
	"mdi mdi-piston",
	"mdi mdi-pitchfork",
	"mdi mdi-pizza",
	"mdi mdi-play",
	"mdi mdi-play-box",
	"mdi mdi-play-box-multiple",
	"mdi mdi-play-box-multiple-outline",
	"mdi mdi-play-box-outline",
	"mdi mdi-play-circle",
	"mdi mdi-play-circle-outline",
	"mdi mdi-play-network",
	"mdi mdi-play-network-outline",
	"mdi mdi-play-outline",
	"mdi mdi-play-pause",
	"mdi mdi-play-protected-content",
	"mdi mdi-play-speed",
	"mdi mdi-playlist-check",
	"mdi mdi-playlist-edit",
	"mdi mdi-playlist-minus",
	"mdi mdi-playlist-music",
	"mdi mdi-playlist-music-outline",
	"mdi mdi-playlist-play",
	"mdi mdi-playlist-plus",
	"mdi mdi-playlist-remove",
	"mdi mdi-playlist-star",
	"mdi mdi-plex",
	"mdi mdi-plus",
	"mdi mdi-plus-box",
	"mdi mdi-plus-box-multiple",
	"mdi mdi-plus-box-multiple-outline",
	"mdi mdi-plus-box-outline",
	"mdi mdi-plus-circle",
	"mdi mdi-plus-circle-multiple",
	"mdi mdi-plus-circle-multiple-outline",
	"mdi mdi-plus-circle-outline",
	"mdi mdi-plus-minus",
	"mdi mdi-plus-minus-box",
	"mdi mdi-plus-minus-variant",
	"mdi mdi-plus-network",
	"mdi mdi-plus-network-outline",
	"mdi mdi-plus-one",
	"mdi mdi-plus-outline",
	"mdi mdi-plus-thick",
	"mdi mdi-podcast",
	"mdi mdi-podium",
	"mdi mdi-podium-bronze",
	"mdi mdi-podium-gold",
	"mdi mdi-podium-silver",
	"mdi mdi-point-of-sale",
	"mdi mdi-pokeball",
	"mdi mdi-pokemon-go",
	"mdi mdi-poker-chip",
	"mdi mdi-polaroid",
	"mdi mdi-police-badge",
	"mdi mdi-police-badge-outline",
	"mdi mdi-poll",
	"mdi mdi-poll-box",
	"mdi mdi-poll-box-outline",
	"mdi mdi-polo",
	"mdi mdi-polymer",
	"mdi mdi-pool",
	"mdi mdi-popcorn",
	"mdi mdi-post",
	"mdi mdi-post-outline",
	"mdi mdi-postage-stamp",
	"mdi mdi-pot",
	"mdi mdi-pot-mix",
	"mdi mdi-pot-mix-outline",
	"mdi mdi-pot-outline",
	"mdi mdi-pot-steam",
	"mdi mdi-pot-steam-outline",
	"mdi mdi-pound",
	"mdi mdi-pound-box",
	"mdi mdi-pound-box-outline",
	"mdi mdi-power",
	"mdi mdi-power-cycle",
	"mdi mdi-power-off",
	"mdi mdi-power-on",
	"mdi mdi-power-plug",
	"mdi mdi-power-plug-off",
	"mdi mdi-power-plug-off-outline",
	"mdi mdi-power-plug-outline",
	"mdi mdi-power-settings",
	"mdi mdi-power-sleep",
	"mdi mdi-power-socket",
	"mdi mdi-power-socket-au",
	"mdi mdi-power-socket-de",
	"mdi mdi-power-socket-eu",
	"mdi mdi-power-socket-fr",
	"mdi mdi-power-socket-it",
	"mdi mdi-power-socket-jp",
	"mdi mdi-power-socket-uk",
	"mdi mdi-power-socket-us",
	"mdi mdi-power-standby",
	"mdi mdi-powershell",
	"mdi mdi-prescription",
	"mdi mdi-presentation",
	"mdi mdi-presentation-play",
	"mdi mdi-pretzel",
	"mdi mdi-printer",
	"mdi mdi-printer-3d",
	"mdi mdi-printer-3d-nozzle",
	"mdi mdi-printer-3d-nozzle-alert",
	"mdi mdi-printer-3d-nozzle-alert-outline",
	"mdi mdi-printer-3d-nozzle-outline",
	"mdi mdi-printer-alert",
	"mdi mdi-printer-check",
	"mdi mdi-printer-eye",
	"mdi mdi-printer-off",
	"mdi mdi-printer-pos",
	"mdi mdi-printer-search",
	"mdi mdi-printer-settings",
	"mdi mdi-printer-wireless",
	"mdi mdi-priority-high",
	"mdi mdi-priority-low",
	"mdi mdi-professional-hexagon",
	"mdi mdi-progress-alert",
	"mdi mdi-progress-check",
	"mdi mdi-progress-clock",
	"mdi mdi-progress-close",
	"mdi mdi-progress-download",
	"mdi mdi-progress-question",
	"mdi mdi-progress-upload",
	"mdi mdi-progress-wrench",
	"mdi mdi-projector",
	"mdi mdi-projector-screen",
	"mdi mdi-propane-tank",
	"mdi mdi-propane-tank-outline",
	"mdi mdi-protocol",
	"mdi mdi-publish",
	"mdi mdi-pulse",
	"mdi mdi-pump",
	"mdi mdi-pumpkin",
	"mdi mdi-purse",
	"mdi mdi-purse-outline",
	"mdi mdi-puzzle",
	"mdi mdi-puzzle-check",
	"mdi mdi-puzzle-check-outline",
	"mdi mdi-puzzle-edit",
	"mdi mdi-puzzle-edit-outline",
	"mdi mdi-puzzle-heart",
	"mdi mdi-puzzle-heart-outline",
	"mdi mdi-puzzle-minus",
	"mdi mdi-puzzle-minus-outline",
	"mdi mdi-puzzle-outline",
	"mdi mdi-puzzle-plus",
	"mdi mdi-puzzle-plus-outline",
	"mdi mdi-puzzle-remove",
	"mdi mdi-puzzle-remove-outline",
	"mdi mdi-puzzle-star",
	"mdi mdi-puzzle-star-outline",
	"mdi mdi-qi",
	"mdi mdi-qqchat",
	"mdi mdi-qrcode",
	"mdi mdi-qrcode-edit",
	"mdi mdi-qrcode-minus",
	"mdi mdi-qrcode-plus",
	"mdi mdi-qrcode-remove",
	"mdi mdi-qrcode-scan",
	"mdi mdi-quadcopter",
	"mdi mdi-quality-high",
	"mdi mdi-quality-low",
	"mdi mdi-quality-medium",
	"mdi mdi-quora",
	"mdi mdi-rabbit",
	"mdi mdi-racing-helmet",
	"mdi mdi-racquetball",
	"mdi mdi-radar",
	"mdi mdi-radiator",
	"mdi mdi-radiator-disabled",
	"mdi mdi-radiator-off",
	"mdi mdi-radio",
	"mdi mdi-radio-am",
	"mdi mdi-radio-fm",
	"mdi mdi-radio-handheld",
	"mdi mdi-radio-off",
	"mdi mdi-radio-tower",
	"mdi mdi-radioactive",
	"mdi mdi-radioactive-off",
	"mdi mdi-radiobox-blank",
	"mdi mdi-radiobox-marked",
	"mdi mdi-radiology-box",
	"mdi mdi-radiology-box-outline",
	"mdi mdi-radius",
	"mdi mdi-radius-outline",
	"mdi mdi-railroad-light",
	"mdi mdi-rake",
	"mdi mdi-raspberry-pi",
	"mdi mdi-ray-end",
	"mdi mdi-ray-end-arrow",
	"mdi mdi-ray-start",
	"mdi mdi-ray-start-arrow",
	"mdi mdi-ray-start-end",
	"mdi mdi-ray-vertex",
	"mdi mdi-react",
	"mdi mdi-read",
	"mdi mdi-receipt",
	"mdi mdi-record",
	"mdi mdi-record-circle",
	"mdi mdi-record-circle-outline",
	"mdi mdi-record-player",
	"mdi mdi-record-rec",
	"mdi mdi-rectangle",
	"mdi mdi-rectangle-outline",
	"mdi mdi-recycle",
	"mdi mdi-recycle-variant",
	"mdi mdi-reddit",
	"mdi mdi-redhat",
	"mdi mdi-redo",
	"mdi mdi-redo-variant",
	"mdi mdi-reflect-horizontal",
	"mdi mdi-reflect-vertical",
	"mdi mdi-refresh",
	"mdi mdi-refresh-circle",
	"mdi mdi-regex",
	"mdi mdi-registered-trademark",
	"mdi mdi-reiterate",
	"mdi mdi-relation-many-to-many",
	"mdi mdi-relation-many-to-one",
	"mdi mdi-relation-many-to-one-or-many",
	"mdi mdi-relation-many-to-only-one",
	"mdi mdi-relation-many-to-zero-or-many",
	"mdi mdi-relation-many-to-zero-or-one",
	"mdi mdi-relation-one-or-many-to-many",
	"mdi mdi-relation-one-or-many-to-one",
	"mdi mdi-relation-one-or-many-to-one-or-many",
	"mdi mdi-relation-one-or-many-to-only-one",
	"mdi mdi-relation-one-or-many-to-zero-or-many",
	"mdi mdi-relation-one-or-many-to-zero-or-one",
	"mdi mdi-relation-one-to-many",
	"mdi mdi-relation-one-to-one",
	"mdi mdi-relation-one-to-one-or-many",
	"mdi mdi-relation-one-to-only-one",
	"mdi mdi-relation-one-to-zero-or-many",
	"mdi mdi-relation-one-to-zero-or-one",
	"mdi mdi-relation-only-one-to-many",
	"mdi mdi-relation-only-one-to-one",
	"mdi mdi-relation-only-one-to-one-or-many",
	"mdi mdi-relation-only-one-to-only-one",
	"mdi mdi-relation-only-one-to-zero-or-many",
	"mdi mdi-relation-only-one-to-zero-or-one",
	"mdi mdi-relation-zero-or-many-to-many",
	"mdi mdi-relation-zero-or-many-to-one",
	"mdi mdi-relation-zero-or-many-to-one-or-many",
	"mdi mdi-relation-zero-or-many-to-only-one",
	"mdi mdi-relation-zero-or-many-to-zero-or-many",
	"mdi mdi-relation-zero-or-many-to-zero-or-one",
	"mdi mdi-relation-zero-or-one-to-many",
	"mdi mdi-relation-zero-or-one-to-one",
	"mdi mdi-relation-zero-or-one-to-one-or-many",
	"mdi mdi-relation-zero-or-one-to-only-one",
	"mdi mdi-relation-zero-or-one-to-zero-or-many",
	"mdi mdi-relation-zero-or-one-to-zero-or-one",
	"mdi mdi-relative-scale",
	"mdi mdi-reload",
	"mdi mdi-reload-alert",
	"mdi mdi-reminder",
	"mdi mdi-remote",
	"mdi mdi-remote-desktop",
	"mdi mdi-remote-off",
	"mdi mdi-remote-tv",
	"mdi mdi-remote-tv-off",
	"mdi mdi-rename-box",
	"mdi mdi-reorder-horizontal",
	"mdi mdi-reorder-vertical",
	"mdi mdi-repeat",
	"mdi mdi-repeat-off",
	"mdi mdi-repeat-once",
	"mdi mdi-replay",
	"mdi mdi-reply",
	"mdi mdi-reply-all",
	"mdi mdi-reply-all-outline",
	"mdi mdi-reply-circle",
	"mdi mdi-reply-outline",
	"mdi mdi-reproduction",
	"mdi mdi-resistor",
	"mdi mdi-resistor-nodes",
	"mdi mdi-resize",
	"mdi mdi-resize-bottom-right",
	"mdi mdi-responsive",
	"mdi mdi-restart",
	"mdi mdi-restart-alert",
	"mdi mdi-restart-off",
	"mdi mdi-restore",
	"mdi mdi-restore-alert",
	"mdi mdi-rewind",
	"mdi mdi-rewind-10",
	"mdi mdi-rewind-30",
	"mdi mdi-rewind-5",
	"mdi mdi-rewind-outline",
	"mdi mdi-rhombus",
	"mdi mdi-rhombus-medium",
	"mdi mdi-rhombus-medium-outline",
	"mdi mdi-rhombus-outline",
	"mdi mdi-rhombus-split",
	"mdi mdi-rhombus-split-outline",
	"mdi mdi-ribbon",
	"mdi mdi-rice",
	"mdi mdi-ring",
	"mdi mdi-rivet",
	"mdi mdi-road",
	"mdi mdi-road-variant",
	"mdi mdi-robber",
	"mdi mdi-robot",
	"mdi mdi-robot-industrial",
	"mdi mdi-robot-mower",
	"mdi mdi-robot-mower-outline",
	"mdi mdi-robot-vacuum",
	"mdi mdi-robot-vacuum-variant",
	"mdi mdi-rocket",
	"mdi mdi-rocket-launch",
	"mdi mdi-rocket-launch-outline",
	"mdi mdi-rocket-outline",
	"mdi mdi-rodent",
	"mdi mdi-roller-skate",
	"mdi mdi-roller-skate-off",
	"mdi mdi-rollerblade",
	"mdi mdi-rollerblade-off",
	"mdi mdi-rollupjs",
	"mdi mdi-roman-numeral-1",
	"mdi mdi-roman-numeral-10",
	"mdi mdi-roman-numeral-2",
	"mdi mdi-roman-numeral-3",
	"mdi mdi-roman-numeral-4",
	"mdi mdi-roman-numeral-5",
	"mdi mdi-roman-numeral-6",
	"mdi mdi-roman-numeral-7",
	"mdi mdi-roman-numeral-8",
	"mdi mdi-roman-numeral-9",
	"mdi mdi-room-service",
	"mdi mdi-room-service-outline",
	"mdi mdi-rotate-3d",
	"mdi mdi-rotate-3d-variant",
	"mdi mdi-rotate-left",
	"mdi mdi-rotate-left-variant",
	"mdi mdi-rotate-orbit",
	"mdi mdi-rotate-right",
	"mdi mdi-rotate-right-variant",
	"mdi mdi-rounded-corner",
	"mdi mdi-router",
	"mdi mdi-router-network",
	"mdi mdi-router-wireless",
	"mdi mdi-router-wireless-off",
	"mdi mdi-router-wireless-settings",
	"mdi mdi-routes",
	"mdi mdi-routes-clock",
	"mdi mdi-rowing",
	"mdi mdi-rss",
	"mdi mdi-rss-box",
	"mdi mdi-rss-off",
	"mdi mdi-rug",
	"mdi mdi-rugby",
	"mdi mdi-ruler",
	"mdi mdi-ruler-square",
	"mdi mdi-ruler-square-compass",
	"mdi mdi-run",
	"mdi mdi-run-fast",
	"mdi mdi-rv-truck",
	"mdi mdi-sack",
	"mdi mdi-sack-percent",
	"mdi mdi-safe",
	"mdi mdi-safe-square",
	"mdi mdi-safe-square-outline",
	"mdi mdi-safety-goggles",
	"mdi mdi-sail-boat",
	"mdi mdi-sale",
	"mdi mdi-salesforce",
	"mdi mdi-sass",
	"mdi mdi-satellite",
	"mdi mdi-satellite-uplink",
	"mdi mdi-satellite-variant",
	"mdi mdi-sausage",
	"mdi mdi-saw-blade",
	"mdi mdi-sawtooth-wave",
	"mdi mdi-saxophone",
	"mdi mdi-scale",
	"mdi mdi-scale-balance",
	"mdi mdi-scale-bathroom",
	"mdi mdi-scale-off",
	"mdi mdi-scan-helper",
	"mdi mdi-scanner",
	"mdi mdi-scanner-off",
	"mdi mdi-scatter-plot",
	"mdi mdi-scatter-plot-outline",
	"mdi mdi-school",
	"mdi mdi-school-outline",
	"mdi mdi-scissors-cutting",
	"mdi mdi-scoreboard",
	"mdi mdi-scoreboard-outline",
	"mdi mdi-screen-rotation",
	"mdi mdi-screen-rotation-lock",
	"mdi mdi-screw-flat-top",
	"mdi mdi-screw-lag",
	"mdi mdi-screw-machine-flat-top",
	"mdi mdi-screw-machine-round-top",
	"mdi mdi-screw-round-top",
	"mdi mdi-screwdriver",
	"mdi mdi-script",
	"mdi mdi-script-outline",
	"mdi mdi-script-text",
	"mdi mdi-script-text-outline",
	"mdi mdi-sd",
	"mdi mdi-seal",
	"mdi mdi-seal-variant",
	"mdi mdi-search-web",
	"mdi mdi-seat",
	"mdi mdi-seat-flat",
	"mdi mdi-seat-flat-angled",
	"mdi mdi-seat-individual-suite",
	"mdi mdi-seat-legroom-extra",
	"mdi mdi-seat-legroom-normal",
	"mdi mdi-seat-legroom-reduced",
	"mdi mdi-seat-outline",
	"mdi mdi-seat-passenger",
	"mdi mdi-seat-recline-extra",
	"mdi mdi-seat-recline-normal",
	"mdi mdi-seatbelt",
	"mdi mdi-security",
	"mdi mdi-security-network",
	"mdi mdi-seed",
	"mdi mdi-seed-off",
	"mdi mdi-seed-off-outline",
	"mdi mdi-seed-outline",
	"mdi mdi-seesaw",
	"mdi mdi-segment",
	"mdi mdi-select",
	"mdi mdi-select-all",
	"mdi mdi-select-color",
	"mdi mdi-select-compare",
	"mdi mdi-select-drag",
	"mdi mdi-select-group",
	"mdi mdi-select-inverse",
	"mdi mdi-select-marker",
	"mdi mdi-select-multiple",
	"mdi mdi-select-multiple-marker",
	"mdi mdi-select-off",
	"mdi mdi-select-place",
	"mdi mdi-select-search",
	"mdi mdi-selection",
	"mdi mdi-selection-drag",
	"mdi mdi-selection-ellipse",
	"mdi mdi-selection-ellipse-arrow-inside",
	"mdi mdi-selection-marker",
	"mdi mdi-selection-multiple",
	"mdi mdi-selection-multiple-marker",
	"mdi mdi-selection-off",
	"mdi mdi-selection-search",
	"mdi mdi-semantic-web",
	"mdi mdi-send",
	"mdi mdi-send-check",
	"mdi mdi-send-check-outline",
	"mdi mdi-send-circle",
	"mdi mdi-send-circle-outline",
	"mdi mdi-send-clock",
	"mdi mdi-send-clock-outline",
	"mdi mdi-send-lock",
	"mdi mdi-send-lock-outline",
	"mdi mdi-send-outline",
	"mdi mdi-serial-port",
	"mdi mdi-server",
	"mdi mdi-server-minus",
	"mdi mdi-server-network",
	"mdi mdi-server-network-off",
	"mdi mdi-server-off",
	"mdi mdi-server-plus",
	"mdi mdi-server-remove",
	"mdi mdi-server-security",
	"mdi mdi-set-all",
	"mdi mdi-set-center",
	"mdi mdi-set-center-right",
	"mdi mdi-set-left",
	"mdi mdi-set-left-center",
	"mdi mdi-set-left-right",
	"mdi mdi-set-merge",
	"mdi mdi-set-none",
	"mdi mdi-set-right",
	"mdi mdi-set-split",
	"mdi mdi-set-square",
	"mdi mdi-set-top-box",
	"mdi mdi-settings-helper",
	"mdi mdi-shaker",
	"mdi mdi-shaker-outline",
	"mdi mdi-shape",
	"mdi mdi-shape-circle-plus",
	"mdi mdi-shape-outline",
	"mdi mdi-shape-oval-plus",
	"mdi mdi-shape-plus",
	"mdi mdi-shape-polygon-plus",
	"mdi mdi-shape-rectangle-plus",
	"mdi mdi-shape-square-plus",
	"mdi mdi-shape-square-rounded-plus",
	"mdi mdi-share",
	"mdi mdi-share-all",
	"mdi mdi-share-all-outline",
	"mdi mdi-share-circle",
	"mdi mdi-share-off",
	"mdi mdi-share-off-outline",
	"mdi mdi-share-outline",
	"mdi mdi-share-variant",
	"mdi mdi-share-variant-outline",
	"mdi mdi-sheep",
	"mdi mdi-shield",
	"mdi mdi-shield-account",
	"mdi mdi-shield-account-outline",
	"mdi mdi-shield-account-variant",
	"mdi mdi-shield-account-variant-outline",
	"mdi mdi-shield-airplane",
	"mdi mdi-shield-airplane-outline",
	"mdi mdi-shield-alert",
	"mdi mdi-shield-alert-outline",
	"mdi mdi-shield-bug",
	"mdi mdi-shield-bug-outline",
	"mdi mdi-shield-car",
	"mdi mdi-shield-check",
	"mdi mdi-shield-check-outline",
	"mdi mdi-shield-cross",
	"mdi mdi-shield-cross-outline",
	"mdi mdi-shield-edit",
	"mdi mdi-shield-edit-outline",
	"mdi mdi-shield-half",
	"mdi mdi-shield-half-full",
	"mdi mdi-shield-home",
	"mdi mdi-shield-home-outline",
	"mdi mdi-shield-key",
	"mdi mdi-shield-key-outline",
	"mdi mdi-shield-link-variant",
	"mdi mdi-shield-link-variant-outline",
	"mdi mdi-shield-lock",
	"mdi mdi-shield-lock-outline",
	"mdi mdi-shield-off",
	"mdi mdi-shield-off-outline",
	"mdi mdi-shield-outline",
	"mdi mdi-shield-plus",
	"mdi mdi-shield-plus-outline",
	"mdi mdi-shield-refresh",
	"mdi mdi-shield-refresh-outline",
	"mdi mdi-shield-remove",
	"mdi mdi-shield-remove-outline",
	"mdi mdi-shield-search",
	"mdi mdi-shield-star",
	"mdi mdi-shield-star-outline",
	"mdi mdi-shield-sun",
	"mdi mdi-shield-sun-outline",
	"mdi mdi-shield-sync",
	"mdi mdi-shield-sync-outline",
	"mdi mdi-ship-wheel",
	"mdi mdi-shoe-formal",
	"mdi mdi-shoe-heel",
	"mdi mdi-shoe-print",
	"mdi mdi-shopping",
	"mdi mdi-shopping-music",
	"mdi mdi-shopping-outline",
	"mdi mdi-shopping-search",
	"mdi mdi-shore",
	"mdi mdi-shovel",
	"mdi mdi-shovel-off",
	"mdi mdi-shower",
	"mdi mdi-shower-head",
	"mdi mdi-shredder",
	"mdi mdi-shuffle",
	"mdi mdi-shuffle-disabled",
	"mdi mdi-shuffle-variant",
	"mdi mdi-shuriken",
	"mdi mdi-sigma",
	"mdi mdi-sigma-lower",
	"mdi mdi-sign-caution",
	"mdi mdi-sign-direction",
	"mdi mdi-sign-direction-minus",
	"mdi mdi-sign-direction-plus",
	"mdi mdi-sign-direction-remove",
	"mdi mdi-sign-pole",
	"mdi mdi-sign-real-estate",
	"mdi mdi-sign-text",
	"mdi mdi-signal",
	"mdi mdi-signal-2g",
	"mdi mdi-signal-3g",
	"mdi mdi-signal-4g",
	"mdi mdi-signal-5g",
	"mdi mdi-signal-cellular-1",
	"mdi mdi-signal-cellular-2",
	"mdi mdi-signal-cellular-3",
	"mdi mdi-signal-cellular-outline",
	"mdi mdi-signal-distance-variant",
	"mdi mdi-signal-hspa",
	"mdi mdi-signal-hspa-plus",
	"mdi mdi-signal-off",
	"mdi mdi-signal-variant",
	"mdi mdi-signature",
	"mdi mdi-signature-freehand",
	"mdi mdi-signature-image",
	"mdi mdi-signature-text",
	"mdi mdi-silo",
	"mdi mdi-silverware",
	"mdi mdi-silverware-clean",
	"mdi mdi-silverware-fork",
	"mdi mdi-silverware-fork-knife",
	"mdi mdi-silverware-spoon",
	"mdi mdi-silverware-variant",
	"mdi mdi-sim",
	"mdi mdi-sim-alert",
	"mdi mdi-sim-off",
	"mdi mdi-simple-icons",
	"mdi mdi-sina-weibo",
	"mdi mdi-sine-wave",
	"mdi mdi-sitemap",
	"mdi mdi-size-l",
	"mdi mdi-size-m",
	"mdi mdi-size-s",
	"mdi mdi-size-xl",
	"mdi mdi-size-xs",
	"mdi mdi-size-xxl",
	"mdi mdi-size-xxs",
	"mdi mdi-size-xxxl",
	"mdi mdi-skate",
	"mdi mdi-skateboard",
	"mdi mdi-skew-less",
	"mdi mdi-skew-more",
	"mdi mdi-ski",
	"mdi mdi-ski-cross-country",
	"mdi mdi-ski-water",
	"mdi mdi-skip-backward",
	"mdi mdi-skip-backward-outline",
	"mdi mdi-skip-forward",
	"mdi mdi-skip-forward-outline",
	"mdi mdi-skip-next",
	"mdi mdi-skip-next-circle",
	"mdi mdi-skip-next-circle-outline",
	"mdi mdi-skip-next-outline",
	"mdi mdi-skip-previous",
	"mdi mdi-skip-previous-circle",
	"mdi mdi-skip-previous-circle-outline",
	"mdi mdi-skip-previous-outline",
	"mdi mdi-skull",
	"mdi mdi-skull-crossbones",
	"mdi mdi-skull-crossbones-outline",
	"mdi mdi-skull-outline",
	"mdi mdi-skull-scan",
	"mdi mdi-skull-scan-outline",
	"mdi mdi-skype",
	"mdi mdi-skype-business",
	"mdi mdi-slack",
	"mdi mdi-slash-forward",
	"mdi mdi-slash-forward-box",
	"mdi mdi-sleep",
	"mdi mdi-sleep-off",
	"mdi mdi-slide",
	"mdi mdi-slope-downhill",
	"mdi mdi-slope-uphill",
	"mdi mdi-slot-machine",
	"mdi mdi-slot-machine-outline",
	"mdi mdi-smart-card",
	"mdi mdi-smart-card-outline",
	"mdi mdi-smart-card-reader",
	"mdi mdi-smart-card-reader-outline",
	"mdi mdi-smog",
	"mdi mdi-smoke-detector",
	"mdi mdi-smoking",
	"mdi mdi-smoking-off",
	"mdi mdi-smoking-pipe",
	"mdi mdi-smoking-pipe-off",
	"mdi mdi-snake",
	"mdi mdi-snapchat",
	"mdi mdi-snowboard",
	"mdi mdi-snowflake",
	"mdi mdi-snowflake-alert",
	"mdi mdi-snowflake-melt",
	"mdi mdi-snowflake-off",
	"mdi mdi-snowflake-variant",
	"mdi mdi-snowman",
	"mdi mdi-soccer",
	"mdi mdi-soccer-field",
	"mdi mdi-social-distance-2-meters",
	"mdi mdi-social-distance-6-feet",
	"mdi mdi-sofa",
	"mdi mdi-sofa-outline",
	"mdi mdi-sofa-single",
	"mdi mdi-sofa-single-outline",
	"mdi mdi-solar-panel",
	"mdi mdi-solar-panel-large",
	"mdi mdi-solar-power",
	"mdi mdi-soldering-iron",
	"mdi mdi-solid",
	"mdi mdi-sony-playstation",
	"mdi mdi-sort",
	"mdi mdi-sort-alphabetical-ascending",
	"mdi mdi-sort-alphabetical-ascending-variant",
	"mdi mdi-sort-alphabetical-descending",
	"mdi mdi-sort-alphabetical-descending-variant",
	"mdi mdi-sort-alphabetical-variant",
	"mdi mdi-sort-ascending",
	"mdi mdi-sort-bool-ascending",
	"mdi mdi-sort-bool-ascending-variant",
	"mdi mdi-sort-bool-descending",
	"mdi mdi-sort-bool-descending-variant",
	"mdi mdi-sort-calendar-ascending",
	"mdi mdi-sort-calendar-descending",
	"mdi mdi-sort-clock-ascending",
	"mdi mdi-sort-clock-ascending-outline",
	"mdi mdi-sort-clock-descending",
	"mdi mdi-sort-clock-descending-outline",
	"mdi mdi-sort-descending",
	"mdi mdi-sort-numeric-ascending",
	"mdi mdi-sort-numeric-ascending-variant",
	"mdi mdi-sort-numeric-descending",
	"mdi mdi-sort-numeric-descending-variant",
	"mdi mdi-sort-numeric-variant",
	"mdi mdi-sort-reverse-variant",
	"mdi mdi-sort-variant",
	"mdi mdi-sort-variant-lock",
	"mdi mdi-sort-variant-lock-open",
	"mdi mdi-sort-variant-remove",
	"mdi mdi-soundcloud",
	"mdi mdi-source-branch",
	"mdi mdi-source-branch-check",
	"mdi mdi-source-branch-minus",
	"mdi mdi-source-branch-plus",
	"mdi mdi-source-branch-refresh",
	"mdi mdi-source-branch-remove",
	"mdi mdi-source-branch-sync",
	"mdi mdi-source-commit",
	"mdi mdi-source-commit-end",
	"mdi mdi-source-commit-end-local",
	"mdi mdi-source-commit-local",
	"mdi mdi-source-commit-next-local",
	"mdi mdi-source-commit-start",
	"mdi mdi-source-commit-start-next-local",
	"mdi mdi-source-fork",
	"mdi mdi-source-merge",
	"mdi mdi-source-pull",
	"mdi mdi-source-repository",
	"mdi mdi-source-repository-multiple",
	"mdi mdi-soy-sauce",
	"mdi mdi-soy-sauce-off",
	"mdi mdi-spa",
	"mdi mdi-spa-outline",
	"mdi mdi-space-invaders",
	"mdi mdi-space-station",
	"mdi mdi-spade",
	"mdi mdi-sparkles",
	"mdi mdi-speaker",
	"mdi mdi-speaker-bluetooth",
	"mdi mdi-speaker-multiple",
	"mdi mdi-speaker-off",
	"mdi mdi-speaker-wireless",
	"mdi mdi-speedometer",
	"mdi mdi-speedometer-medium",
	"mdi mdi-speedometer-slow",
	"mdi mdi-spellcheck",
	"mdi mdi-spider",
	"mdi mdi-spider-thread",
	"mdi mdi-spider-web",
	"mdi mdi-spirit-level",
	"mdi mdi-spoon-sugar",
	"mdi mdi-spotify",
	"mdi mdi-spotlight",
	"mdi mdi-spotlight-beam",
	"mdi mdi-spray",
	"mdi mdi-spray-bottle",
	"mdi mdi-sprinkler",
	"mdi mdi-sprinkler-variant",
	"mdi mdi-sprout",
	"mdi mdi-sprout-outline",
	"mdi mdi-square",
	"mdi mdi-square-circle",
	"mdi mdi-square-edit-outline",
	"mdi mdi-square-medium",
	"mdi mdi-square-medium-outline",
	"mdi mdi-square-off",
	"mdi mdi-square-off-outline",
	"mdi mdi-square-outline",
	"mdi mdi-square-root",
	"mdi mdi-square-root-box",
	"mdi mdi-square-rounded",
	"mdi mdi-square-rounded-outline",
	"mdi mdi-square-small",
	"mdi mdi-square-wave",
	"mdi mdi-squeegee",
	"mdi mdi-ssh",
	"mdi mdi-stack-exchange",
	"mdi mdi-stack-overflow",
	"mdi mdi-stackpath",
	"mdi mdi-stadium",
	"mdi mdi-stadium-variant",
	"mdi mdi-stairs",
	"mdi mdi-stairs-box",
	"mdi mdi-stairs-down",
	"mdi mdi-stairs-up",
	"mdi mdi-stamper",
	"mdi mdi-standard-definition",
	"mdi mdi-star",
	"mdi mdi-star-box",
	"mdi mdi-star-box-multiple",
	"mdi mdi-star-box-multiple-outline",
	"mdi mdi-star-box-outline",
	"mdi mdi-star-check",
	"mdi mdi-star-check-outline",
	"mdi mdi-star-circle",
	"mdi mdi-star-circle-outline",
	"mdi mdi-star-face",
	"mdi mdi-star-four-points",
	"mdi mdi-star-four-points-outline",
	"mdi mdi-star-half",
	"mdi mdi-star-half-full",
	"mdi mdi-star-minus",
	"mdi mdi-star-minus-outline",
	"mdi mdi-star-off",
	"mdi mdi-star-off-outline",
	"mdi mdi-star-outline",
	"mdi mdi-star-plus",
	"mdi mdi-star-plus-outline",
	"mdi mdi-star-remove",
	"mdi mdi-star-remove-outline",
	"mdi mdi-star-three-points",
	"mdi mdi-star-three-points-outline",
	"mdi mdi-state-machine",
	"mdi mdi-steam",
	"mdi mdi-steering",
	"mdi mdi-steering-off",
	"mdi mdi-step-backward",
	"mdi mdi-step-backward-2",
	"mdi mdi-step-forward",
	"mdi mdi-step-forward-2",
	"mdi mdi-stethoscope",
	"mdi mdi-sticker",
	"mdi mdi-sticker-alert",
	"mdi mdi-sticker-alert-outline",
	"mdi mdi-sticker-check",
	"mdi mdi-sticker-check-outline",
	"mdi mdi-sticker-circle-outline",
	"mdi mdi-sticker-emoji",
	"mdi mdi-sticker-minus",
	"mdi mdi-sticker-minus-outline",
	"mdi mdi-sticker-outline",
	"mdi mdi-sticker-plus",
	"mdi mdi-sticker-plus-outline",
	"mdi mdi-sticker-remove",
	"mdi mdi-sticker-remove-outline",
	"mdi mdi-stocking",
	"mdi mdi-stomach",
	"mdi mdi-stop",
	"mdi mdi-stop-circle",
	"mdi mdi-stop-circle-outline",
	"mdi mdi-store",
	"mdi mdi-store-24-hour",
	"mdi mdi-store-outline",
	"mdi mdi-storefront",
	"mdi mdi-storefront-outline",
	"mdi mdi-stove",
	"mdi mdi-strategy",
	"mdi mdi-stretch-to-page",
	"mdi mdi-stretch-to-page-outline",
	"mdi mdi-string-lights",
	"mdi mdi-string-lights-off",
	"mdi mdi-subdirectory-arrow-left",
	"mdi mdi-subdirectory-arrow-right",
	"mdi mdi-submarine",
	"mdi mdi-subtitles",
	"mdi mdi-subtitles-outline",
	"mdi mdi-subway",
	"mdi mdi-subway-alert-variant",
	"mdi mdi-subway-variant",
	"mdi mdi-summit",
	"mdi mdi-sunglasses",
	"mdi mdi-surround-sound",
	"mdi mdi-surround-sound-2-0",
	"mdi mdi-surround-sound-3-1",
	"mdi mdi-surround-sound-5-1",
	"mdi mdi-surround-sound-7-1",
	"mdi mdi-svg",
	"mdi mdi-swap-horizontal",
	"mdi mdi-swap-horizontal-bold",
	"mdi mdi-swap-horizontal-circle",
	"mdi mdi-swap-horizontal-circle-outline",
	"mdi mdi-swap-horizontal-variant",
	"mdi mdi-swap-vertical",
	"mdi mdi-swap-vertical-bold",
	"mdi mdi-swap-vertical-circle",
	"mdi mdi-swap-vertical-circle-outline",
	"mdi mdi-swap-vertical-variant",
	"mdi mdi-swim",
	"mdi mdi-switch",
	"mdi mdi-sword",
	"mdi mdi-sword-cross",
	"mdi mdi-syllabary-hangul",
	"mdi mdi-syllabary-hiragana",
	"mdi mdi-syllabary-katakana",
	"mdi mdi-syllabary-katakana-halfwidth",
	"mdi mdi-symbol",
	"mdi mdi-symfony",
	"mdi mdi-sync",
	"mdi mdi-sync-alert",
	"mdi mdi-sync-circle",
	"mdi mdi-sync-off",
	"mdi mdi-tab",
	"mdi mdi-tab-minus",
	"mdi mdi-tab-plus",
	"mdi mdi-tab-remove",
	"mdi mdi-tab-unselected",
	"mdi mdi-table",
	"mdi mdi-table-account",
	"mdi mdi-table-alert",
	"mdi mdi-table-arrow-down",
	"mdi mdi-table-arrow-left",
	"mdi mdi-table-arrow-right",
	"mdi mdi-table-arrow-up",
	"mdi mdi-table-border",
	"mdi mdi-table-cancel",
	"mdi mdi-table-chair",
	"mdi mdi-table-check",
	"mdi mdi-table-clock",
	"mdi mdi-table-cog",
	"mdi mdi-table-column",
	"mdi mdi-table-column-plus-after",
	"mdi mdi-table-column-plus-before",
	"mdi mdi-table-column-remove",
	"mdi mdi-table-column-width",
	"mdi mdi-table-edit",
	"mdi mdi-table-eye",
	"mdi mdi-table-eye-off",
	"mdi mdi-table-furniture",
	"mdi mdi-table-headers-eye",
	"mdi mdi-table-headers-eye-off",
	"mdi mdi-table-heart",
	"mdi mdi-table-key",
	"mdi mdi-table-large",
	"mdi mdi-table-large-plus",
	"mdi mdi-table-large-remove",
	"mdi mdi-table-lock",
	"mdi mdi-table-merge-cells",
	"mdi mdi-table-minus",
	"mdi mdi-table-multiple",
	"mdi mdi-table-network",
	"mdi mdi-table-of-contents",
	"mdi mdi-table-off",
	"mdi mdi-table-plus",
	"mdi mdi-table-refresh",
	"mdi mdi-table-remove",
	"mdi mdi-table-row",
	"mdi mdi-table-row-height",
	"mdi mdi-table-row-plus-after",
	"mdi mdi-table-row-plus-before",
	"mdi mdi-table-row-remove",
	"mdi mdi-table-search",
	"mdi mdi-table-settings",
	"mdi mdi-table-split-cell",
	"mdi mdi-table-star",
	"mdi mdi-table-sync",
	"mdi mdi-table-tennis",
	"mdi mdi-tablet",
	"mdi mdi-tablet-android",
	"mdi mdi-tablet-cellphone",
	"mdi mdi-tablet-dashboard",
	"mdi mdi-tablet-ipad",
	"mdi mdi-taco",
	"mdi mdi-tag",
	"mdi mdi-tag-faces",
	"mdi mdi-tag-heart",
	"mdi mdi-tag-heart-outline",
	"mdi mdi-tag-minus",
	"mdi mdi-tag-minus-outline",
	"mdi mdi-tag-multiple",
	"mdi mdi-tag-multiple-outline",
	"mdi mdi-tag-off",
	"mdi mdi-tag-off-outline",
	"mdi mdi-tag-outline",
	"mdi mdi-tag-plus",
	"mdi mdi-tag-plus-outline",
	"mdi mdi-tag-remove",
	"mdi mdi-tag-remove-outline",
	"mdi mdi-tag-text",
	"mdi mdi-tag-text-outline",
	"mdi mdi-tailwind",
	"mdi mdi-tank",
	"mdi mdi-tanker-truck",
	"mdi mdi-tape-measure",
	"mdi mdi-target",
	"mdi mdi-target-account",
	"mdi mdi-target-variant",
	"mdi mdi-taxi",
	"mdi mdi-tea",
	"mdi mdi-tea-outline",
	"mdi mdi-teach",
	"mdi mdi-teamviewer",
	"mdi mdi-telegram",
	"mdi mdi-telescope",
	"mdi mdi-television",
	"mdi mdi-television-ambient-light",
	"mdi mdi-television-box",
	"mdi mdi-television-classic",
	"mdi mdi-television-classic-off",
	"mdi mdi-television-clean",
	"mdi mdi-television-guide",
	"mdi mdi-television-off",
	"mdi mdi-television-pause",
	"mdi mdi-television-play",
	"mdi mdi-television-stop",
	"mdi mdi-temperature-celsius",
	"mdi mdi-temperature-fahrenheit",
	"mdi mdi-temperature-kelvin",
	"mdi mdi-tennis",
	"mdi mdi-tennis-ball",
	"mdi mdi-tent",
	"mdi mdi-terraform",
	"mdi mdi-terrain",
	"mdi mdi-test-tube",
	"mdi mdi-test-tube-empty",
	"mdi mdi-test-tube-off",
	"mdi mdi-text",
	"mdi mdi-text-account",
	"mdi mdi-text-box",
	"mdi mdi-text-box-check",
	"mdi mdi-text-box-check-outline",
	"mdi mdi-text-box-minus",
	"mdi mdi-text-box-minus-outline",
	"mdi mdi-text-box-multiple",
	"mdi mdi-text-box-multiple-outline",
	"mdi mdi-text-box-outline",
	"mdi mdi-text-box-plus",
	"mdi mdi-text-box-plus-outline",
	"mdi mdi-text-box-remove",
	"mdi mdi-text-box-remove-outline",
	"mdi mdi-text-box-search",
	"mdi mdi-text-box-search-outline",
	"mdi mdi-text-recognition",
	"mdi mdi-text-search",
	"mdi mdi-text-shadow",
	"mdi mdi-text-short",
	"mdi mdi-text-subject",
	"mdi mdi-text-to-speech",
	"mdi mdi-text-to-speech-off",
	"mdi mdi-texture",
	"mdi mdi-texture-box",
	"mdi mdi-theater",
	"mdi mdi-theme-light-dark",
	"mdi mdi-thermometer",
	"mdi mdi-thermometer-alert",
	"mdi mdi-thermometer-chevron-down",
	"mdi mdi-thermometer-chevron-up",
	"mdi mdi-thermometer-high",
	"mdi mdi-thermometer-lines",
	"mdi mdi-thermometer-low",
	"mdi mdi-thermometer-minus",
	"mdi mdi-thermometer-off",
	"mdi mdi-thermometer-plus",
	"mdi mdi-thermostat",
	"mdi mdi-thermostat-box",
	"mdi mdi-thought-bubble",
	"mdi mdi-thought-bubble-outline",
	"mdi mdi-thumb-down",
	"mdi mdi-thumb-down-outline",
	"mdi mdi-thumb-up",
	"mdi mdi-thumb-up-outline",
	"mdi mdi-thumbs-up-down",
	"mdi mdi-ticket",
	"mdi mdi-ticket-account",
	"mdi mdi-ticket-confirmation",
	"mdi mdi-ticket-confirmation-outline",
	"mdi mdi-ticket-outline",
	"mdi mdi-ticket-percent",
	"mdi mdi-ticket-percent-outline",
	"mdi mdi-tie",
	"mdi mdi-tilde",
	"mdi mdi-timelapse",
	"mdi mdi-timeline",
	"mdi mdi-timeline-alert",
	"mdi mdi-timeline-alert-outline",
	"mdi mdi-timeline-check",
	"mdi mdi-timeline-check-outline",
	"mdi mdi-timeline-clock",
	"mdi mdi-timeline-clock-outline",
	"mdi mdi-timeline-help",
	"mdi mdi-timeline-help-outline",
	"mdi mdi-timeline-minus",
	"mdi mdi-timeline-minus-outline",
	"mdi mdi-timeline-outline",
	"mdi mdi-timeline-plus",
	"mdi mdi-timeline-plus-outline",
	"mdi mdi-timeline-remove",
	"mdi mdi-timeline-remove-outline",
	"mdi mdi-timeline-text",
	"mdi mdi-timeline-text-outline",
	"mdi mdi-timer",
	"mdi mdi-timer-10",
	"mdi mdi-timer-3",
	"mdi mdi-timer-off",
	"mdi mdi-timer-off-outline",
	"mdi mdi-timer-outline",
	"mdi mdi-timer-sand",
	"mdi mdi-timer-sand-empty",
	"mdi mdi-timer-sand-full",
	"mdi mdi-timetable",
	"mdi mdi-toaster",
	"mdi mdi-toaster-off",
	"mdi mdi-toaster-oven",
	"mdi mdi-toggle-switch",
	"mdi mdi-toggle-switch-off",
	"mdi mdi-toggle-switch-off-outline",
	"mdi mdi-toggle-switch-outline",
	"mdi mdi-toilet",
	"mdi mdi-toolbox",
	"mdi mdi-toolbox-outline",
	"mdi mdi-tools",
	"mdi mdi-tooltip",
	"mdi mdi-tooltip-account",
	"mdi mdi-tooltip-check",
	"mdi mdi-tooltip-check-outline",
	"mdi mdi-tooltip-edit",
	"mdi mdi-tooltip-edit-outline",
	"mdi mdi-tooltip-image",
	"mdi mdi-tooltip-image-outline",
	"mdi mdi-tooltip-minus",
	"mdi mdi-tooltip-minus-outline",
	"mdi mdi-tooltip-outline",
	"mdi mdi-tooltip-plus",
	"mdi mdi-tooltip-plus-outline",
	"mdi mdi-tooltip-remove",
	"mdi mdi-tooltip-remove-outline",
	"mdi mdi-tooltip-text",
	"mdi mdi-tooltip-text-outline",
	"mdi mdi-tooth",
	"mdi mdi-tooth-outline",
	"mdi mdi-toothbrush",
	"mdi mdi-toothbrush-electric",
	"mdi mdi-toothbrush-paste",
	"mdi mdi-tortoise",
	"mdi mdi-toslink",
	"mdi mdi-tournament",
	"mdi mdi-tow-truck",
	"mdi mdi-tower-beach",
	"mdi mdi-tower-fire",
	"mdi mdi-toy-brick",
	"mdi mdi-toy-brick-marker",
	"mdi mdi-toy-brick-marker-outline",
	"mdi mdi-toy-brick-minus",
	"mdi mdi-toy-brick-minus-outline",
	"mdi mdi-toy-brick-outline",
	"mdi mdi-toy-brick-plus",
	"mdi mdi-toy-brick-plus-outline",
	"mdi mdi-toy-brick-remove",
	"mdi mdi-toy-brick-remove-outline",
	"mdi mdi-toy-brick-search",
	"mdi mdi-toy-brick-search-outline",
	"mdi mdi-track-light",
	"mdi mdi-trackpad",
	"mdi mdi-trackpad-lock",
	"mdi mdi-tractor",
	"mdi mdi-tractor-variant",
	"mdi mdi-trademark",
	"mdi mdi-traffic-cone",
	"mdi mdi-traffic-light",
	"mdi mdi-train",
	"mdi mdi-train-car",
	"mdi mdi-train-variant",
	"mdi mdi-tram",
	"mdi mdi-tram-side",
	"mdi mdi-transcribe",
	"mdi mdi-transcribe-close",
	"mdi mdi-transfer",
	"mdi mdi-transfer-down",
	"mdi mdi-transfer-left",
	"mdi mdi-transfer-right",
	"mdi mdi-transfer-up",
	"mdi mdi-transit-connection",
	"mdi mdi-transit-connection-horizontal",
	"mdi mdi-transit-connection-variant",
	"mdi mdi-transit-detour",
	"mdi mdi-transit-skip",
	"mdi mdi-transit-transfer",
	"mdi mdi-transition",
	"mdi mdi-transition-masked",
	"mdi mdi-translate",
	"mdi mdi-translate-off",
	"mdi mdi-transmission-tower",
	"mdi mdi-trash-can",
	"mdi mdi-trash-can-outline",
	"mdi mdi-tray",
	"mdi mdi-tray-alert",
	"mdi mdi-tray-full",
	"mdi mdi-tray-minus",
	"mdi mdi-tray-plus",
	"mdi mdi-tray-remove",
	"mdi mdi-treasure-chest",
	"mdi mdi-tree",
	"mdi mdi-tree-outline",
	"mdi mdi-trello",
	"mdi mdi-trending-down",
	"mdi mdi-trending-neutral",
	"mdi mdi-trending-up",
	"mdi mdi-triangle",
	"mdi mdi-triangle-outline",
	"mdi mdi-triangle-wave",
	"mdi mdi-triforce",
	"mdi mdi-trophy",
	"mdi mdi-trophy-award",
	"mdi mdi-trophy-broken",
	"mdi mdi-trophy-outline",
	"mdi mdi-trophy-variant",
	"mdi mdi-trophy-variant-outline",
	"mdi mdi-truck",
	"mdi mdi-truck-check",
	"mdi mdi-truck-check-outline",
	"mdi mdi-truck-delivery",
	"mdi mdi-truck-delivery-outline",
	"mdi mdi-truck-fast",
	"mdi mdi-truck-fast-outline",
	"mdi mdi-truck-outline",
	"mdi mdi-truck-trailer",
	"mdi mdi-trumpet",
	"mdi mdi-tshirt-crew",
	"mdi mdi-tshirt-crew-outline",
	"mdi mdi-tshirt-v",
	"mdi mdi-tshirt-v-outline",
	"mdi mdi-tumble-dryer",
	"mdi mdi-tumble-dryer-alert",
	"mdi mdi-tumble-dryer-off",
	"mdi mdi-tune",
	"mdi mdi-tune-variant",
	"mdi mdi-tune-vertical",
	"mdi mdi-tune-vertical-variant",
	"mdi mdi-turnstile",
	"mdi mdi-turnstile-outline",
	"mdi mdi-turtle",
	"mdi mdi-twitch",
	"mdi mdi-twitter",
	"mdi mdi-twitter-retweet",
	"mdi mdi-two-factor-authentication",
	"mdi mdi-typewriter",
	"mdi mdi-ubisoft",
	"mdi mdi-ubuntu",
	"mdi mdi-ufo",
	"mdi mdi-ufo-outline",
	"mdi mdi-ultra-high-definition",
	"mdi mdi-umbraco",
	"mdi mdi-umbrella",
	"mdi mdi-umbrella-closed",
	"mdi mdi-umbrella-closed-outline",
	"mdi mdi-umbrella-closed-variant",
	"mdi mdi-umbrella-outline",
	"mdi mdi-undo",
	"mdi mdi-undo-variant",
	"mdi mdi-unfold-less-horizontal",
	"mdi mdi-unfold-less-vertical",
	"mdi mdi-unfold-more-horizontal",
	"mdi mdi-unfold-more-vertical",
	"mdi mdi-ungroup",
	"mdi mdi-unicode",
	"mdi mdi-unity",
	"mdi mdi-unreal",
	"mdi mdi-untappd",
	"mdi mdi-update",
	"mdi mdi-upload",
	"mdi mdi-upload-lock",
	"mdi mdi-upload-lock-outline",
	"mdi mdi-upload-multiple",
	"mdi mdi-upload-network",
	"mdi mdi-upload-network-outline",
	"mdi mdi-upload-off",
	"mdi mdi-upload-off-outline",
	"mdi mdi-upload-outline",
	"mdi mdi-usb",
	"mdi mdi-usb-flash-drive",
	"mdi mdi-usb-flash-drive-outline",
	"mdi mdi-usb-port",
	"mdi mdi-valve",
	"mdi mdi-valve-closed",
	"mdi mdi-valve-open",
	"mdi mdi-van-passenger",
	"mdi mdi-van-utility",
	"mdi mdi-vanish",
	"mdi mdi-vanish-quarter",
	"mdi mdi-vanity-light",
	"mdi mdi-variable",
	"mdi mdi-variable-box",
	"mdi mdi-vector-arrange-above",
	"mdi mdi-vector-arrange-below",
	"mdi mdi-vector-bezier",
	"mdi mdi-vector-circle",
	"mdi mdi-vector-circle-variant",
	"mdi mdi-vector-combine",
	"mdi mdi-vector-curve",
	"mdi mdi-vector-difference",
	"mdi mdi-vector-difference-ab",
	"mdi mdi-vector-difference-ba",
	"mdi mdi-vector-ellipse",
	"mdi mdi-vector-intersection",
	"mdi mdi-vector-line",
	"mdi mdi-vector-link",
	"mdi mdi-vector-point",
	"mdi mdi-vector-polygon",
	"mdi mdi-vector-polyline",
	"mdi mdi-vector-polyline-edit",
	"mdi mdi-vector-polyline-minus",
	"mdi mdi-vector-polyline-plus",
	"mdi mdi-vector-polyline-remove",
	"mdi mdi-vector-radius",
	"mdi mdi-vector-rectangle",
	"mdi mdi-vector-selection",
	"mdi mdi-vector-square",
	"mdi mdi-vector-triangle",
	"mdi mdi-vector-union",
	"mdi mdi-vhs",
	"mdi mdi-vibrate",
	"mdi mdi-vibrate-off",
	"mdi mdi-video",
	"mdi mdi-video-3d",
	"mdi mdi-video-3d-off",
	"mdi mdi-video-3d-variant",
	"mdi mdi-video-4k-box",
	"mdi mdi-video-account",
	"mdi mdi-video-box",
	"mdi mdi-video-box-off",
	"mdi mdi-video-check",
	"mdi mdi-video-check-outline",
	"mdi mdi-video-high-definition",
	"mdi mdi-video-image",
	"mdi mdi-video-input-antenna",
	"mdi mdi-video-input-component",
	"mdi mdi-video-input-hdmi",
	"mdi mdi-video-input-scart",
	"mdi mdi-video-input-svideo",
	"mdi mdi-video-minus",
	"mdi mdi-video-minus-outline",
	"mdi mdi-video-off",
	"mdi mdi-video-off-outline",
	"mdi mdi-video-outline",
	"mdi mdi-video-plus",
	"mdi mdi-video-plus-outline",
	"mdi mdi-video-stabilization",
	"mdi mdi-video-switch",
	"mdi mdi-video-switch-outline",
	"mdi mdi-video-vintage",
	"mdi mdi-video-wireless",
	"mdi mdi-video-wireless-outline",
	"mdi mdi-view-agenda",
	"mdi mdi-view-agenda-outline",
	"mdi mdi-view-array",
	"mdi mdi-view-array-outline",
	"mdi mdi-view-carousel",
	"mdi mdi-view-carousel-outline",
	"mdi mdi-view-column",
	"mdi mdi-view-column-outline",
	"mdi mdi-view-comfy",
	"mdi mdi-view-comfy-outline",
	"mdi mdi-view-compact",
	"mdi mdi-view-compact-outline",
	"mdi mdi-view-dashboard",
	"mdi mdi-view-dashboard-outline",
	"mdi mdi-view-dashboard-variant",
	"mdi mdi-view-dashboard-variant-outline",
	"mdi mdi-view-day",
	"mdi mdi-view-day-outline",
	"mdi mdi-view-grid",
	"mdi mdi-view-grid-outline",
	"mdi mdi-view-grid-plus",
	"mdi mdi-view-grid-plus-outline",
	"mdi mdi-view-headline",
	"mdi mdi-view-list",
	"mdi mdi-view-list-outline",
	"mdi mdi-view-module",
	"mdi mdi-view-module-outline",
	"mdi mdi-view-parallel",
	"mdi mdi-view-parallel-outline",
	"mdi mdi-view-quilt",
	"mdi mdi-view-quilt-outline",
	"mdi mdi-view-sequential",
	"mdi mdi-view-sequential-outline",
	"mdi mdi-view-split-horizontal",
	"mdi mdi-view-split-vertical",
	"mdi mdi-view-stream",
	"mdi mdi-view-stream-outline",
	"mdi mdi-view-week",
	"mdi mdi-view-week-outline",
	"mdi mdi-vimeo",
	"mdi mdi-violin",
	"mdi mdi-virtual-reality",
	"mdi mdi-virus",
	"mdi mdi-virus-outline",
	"mdi mdi-vk",
	"mdi mdi-vlc",
	"mdi mdi-voice-off",
	"mdi mdi-voicemail",
	"mdi mdi-volleyball",
	"mdi mdi-volume-high",
	"mdi mdi-volume-low",
	"mdi mdi-volume-medium",
	"mdi mdi-volume-minus",
	"mdi mdi-volume-mute",
	"mdi mdi-volume-off",
	"mdi mdi-volume-plus",
	"mdi mdi-volume-source",
	"mdi mdi-volume-variant-off",
	"mdi mdi-volume-vibrate",
	"mdi mdi-vote",
	"mdi mdi-vote-outline",
	"mdi mdi-vpn",
	"mdi mdi-vuejs",
	"mdi mdi-vuetify",
	"mdi mdi-walk",
	"mdi mdi-wall",
	"mdi mdi-wall-sconce",
	"mdi mdi-wall-sconce-flat",
	"mdi mdi-wall-sconce-flat-variant",
	"mdi mdi-wall-sconce-round",
	"mdi mdi-wall-sconce-round-variant",
	"mdi mdi-wallet",
	"mdi mdi-wallet-giftcard",
	"mdi mdi-wallet-membership",
	"mdi mdi-wallet-outline",
	"mdi mdi-wallet-plus",
	"mdi mdi-wallet-plus-outline",
	"mdi mdi-wallet-travel",
	"mdi mdi-wallpaper",
	"mdi mdi-wan",
	"mdi mdi-wardrobe",
	"mdi mdi-wardrobe-outline",
	"mdi mdi-warehouse",
	"mdi mdi-washing-machine",
	"mdi mdi-washing-machine-alert",
	"mdi mdi-washing-machine-off",
	"mdi mdi-watch",
	"mdi mdi-watch-export",
	"mdi mdi-watch-export-variant",
	"mdi mdi-watch-import",
	"mdi mdi-watch-import-variant",
	"mdi mdi-watch-variant",
	"mdi mdi-watch-vibrate",
	"mdi mdi-watch-vibrate-off",
	"mdi mdi-water",
	"mdi mdi-water-alert",
	"mdi mdi-water-alert-outline",
	"mdi mdi-water-boiler",
	"mdi mdi-water-boiler-alert",
	"mdi mdi-water-boiler-off",
	"mdi mdi-water-check",
	"mdi mdi-water-check-outline",
	"mdi mdi-water-minus",
	"mdi mdi-water-minus-outline",
	"mdi mdi-water-off",
	"mdi mdi-water-off-outline",
	"mdi mdi-water-outline",
	"mdi mdi-water-percent",
	"mdi mdi-water-percent-alert",
	"mdi mdi-water-plus",
	"mdi mdi-water-plus-outline",
	"mdi mdi-water-polo",
	"mdi mdi-water-pump",
	"mdi mdi-water-pump-off",
	"mdi mdi-water-remove",
	"mdi mdi-water-remove-outline",
	"mdi mdi-water-well",
	"mdi mdi-water-well-outline",
	"mdi mdi-watering-can",
	"mdi mdi-watering-can-outline",
	"mdi mdi-watermark",
	"mdi mdi-wave",
	"mdi mdi-waveform",
	"mdi mdi-waves",
	"mdi mdi-waze",
	"mdi mdi-weather-cloudy",
	"mdi mdi-weather-cloudy-alert",
	"mdi mdi-weather-cloudy-arrow-right",
	"mdi mdi-weather-fog",
	"mdi mdi-weather-hail",
	"mdi mdi-weather-hazy",
	"mdi mdi-weather-hurricane",
	"mdi mdi-weather-lightning",
	"mdi mdi-weather-lightning-rainy",
	"mdi mdi-weather-night",
	"mdi mdi-weather-night-partly-cloudy",
	"mdi mdi-weather-partly-cloudy",
	"mdi mdi-weather-partly-lightning",
	"mdi mdi-weather-partly-rainy",
	"mdi mdi-weather-partly-snowy",
	"mdi mdi-weather-partly-snowy-rainy",
	"mdi mdi-weather-pouring",
	"mdi mdi-weather-rainy",
	"mdi mdi-weather-snowy",
	"mdi mdi-weather-snowy-heavy",
	"mdi mdi-weather-snowy-rainy",
	"mdi mdi-weather-sunny",
	"mdi mdi-weather-sunny-alert",
	"mdi mdi-weather-sunny-off",
	"mdi mdi-weather-sunset",
	"mdi mdi-weather-sunset-down",
	"mdi mdi-weather-sunset-up",
	"mdi mdi-weather-tornado",
	"mdi mdi-weather-windy",
	"mdi mdi-weather-windy-variant",
	"mdi mdi-web",
	"mdi mdi-web-box",
	"mdi mdi-web-clock",
	"mdi mdi-webcam",
	"mdi mdi-webhook",
	"mdi mdi-webpack",
	"mdi mdi-webrtc",
	"mdi mdi-wechat",
	"mdi mdi-weight",
	"mdi mdi-weight-gram",
	"mdi mdi-weight-kilogram",
	"mdi mdi-weight-lifter",
	"mdi mdi-weight-pound",
	"mdi mdi-whatsapp",
	"mdi mdi-wheel-barrow",
	"mdi mdi-wheelchair-accessibility",
	"mdi mdi-whistle",
	"mdi mdi-whistle-outline",
	"mdi mdi-white-balance-auto",
	"mdi mdi-white-balance-incandescent",
	"mdi mdi-white-balance-iridescent",
	"mdi mdi-white-balance-sunny",
	"mdi mdi-widgets",
	"mdi mdi-widgets-outline",
	"mdi mdi-wifi",
	"mdi mdi-wifi-off",
	"mdi mdi-wifi-star",
	"mdi mdi-wifi-strength-1",
	"mdi mdi-wifi-strength-1-alert",
	"mdi mdi-wifi-strength-1-lock",
	"mdi mdi-wifi-strength-2",
	"mdi mdi-wifi-strength-2-alert",
	"mdi mdi-wifi-strength-2-lock",
	"mdi mdi-wifi-strength-3",
	"mdi mdi-wifi-strength-3-alert",
	"mdi mdi-wifi-strength-3-lock",
	"mdi mdi-wifi-strength-4",
	"mdi mdi-wifi-strength-4-alert",
	"mdi mdi-wifi-strength-4-lock",
	"mdi mdi-wifi-strength-alert-outline",
	"mdi mdi-wifi-strength-lock-outline",
	"mdi mdi-wifi-strength-off",
	"mdi mdi-wifi-strength-off-outline",
	"mdi mdi-wifi-strength-outline",
	"mdi mdi-wikipedia",
	"mdi mdi-wind-turbine",
	"mdi mdi-window-close",
	"mdi mdi-window-closed",
	"mdi mdi-window-closed-variant",
	"mdi mdi-window-maximize",
	"mdi mdi-window-minimize",
	"mdi mdi-window-open",
	"mdi mdi-window-open-variant",
	"mdi mdi-window-restore",
	"mdi mdi-window-shutter",
	"mdi mdi-window-shutter-alert",
	"mdi mdi-window-shutter-open",
	"mdi mdi-wiper",
	"mdi mdi-wiper-wash",
	"mdi mdi-wizard-hat",
	"mdi mdi-wordpress",
	"mdi mdi-wrap",
	"mdi mdi-wrap-disabled",
	"mdi mdi-wrench",
	"mdi mdi-wrench-outline",
	"mdi mdi-xamarin",
	"mdi mdi-xamarin-outline",
	"mdi mdi-xing",
	"mdi mdi-xml",
	"mdi mdi-xmpp",
	"mdi mdi-y-combinator",
	"mdi mdi-yahoo",
	"mdi mdi-yeast",
	"mdi mdi-yin-yang",
	"mdi mdi-yoga",
	"mdi mdi-youtube",
	"mdi mdi-youtube-gaming",
	"mdi mdi-youtube-studio",
	"mdi mdi-youtube-subscription",
	"mdi mdi-youtube-tv",
	"mdi mdi-yurt",
	"mdi mdi-z-wave",
	"mdi mdi-zend",
	"mdi mdi-zigbee",
	"mdi mdi-zip-box",
	"mdi mdi-zip-box-outline",
	"mdi mdi-zip-disk",
	"mdi mdi-zodiac-aquarius",
	"mdi mdi-zodiac-aries",
	"mdi mdi-zodiac-cancer",
	"mdi mdi-zodiac-capricorn",
	"mdi mdi-zodiac-gemini",
	"mdi mdi-zodiac-leo",
	"mdi mdi-zodiac-libra",
	"mdi mdi-zodiac-pisces",
	"mdi mdi-zodiac-sagittarius",
	"mdi mdi-zodiac-scorpio",
	"mdi mdi-zodiac-taurus",
	"mdi mdi-zodiac-virgo",
	"mdi mdi-blank",
	"mdi mdi-cellphone-android",
	"mdi mdi-cellphone-iphone",
	"mdi mdi-do-not-disturb",
	"mdi mdi-do-not-disturb-off",
	"mdi mdi-laptop-chromebook",
	"mdi mdi-laptop-mac",
	"mdi mdi-laptop-windows",
	"mdi mdi-tablet-ipad",
	"mdi mdi-telegram",
	"mdi mdi-18px mdi-account",
	"mdi mdi-24px mdi-account",
	"mdi mdi-36px mdi-account",
	"mdi mdi-48px mdi-account",
	"mdi mdi-36px mdi-account",
	"mdi mdi-36px mdi-rotate-45 mdi-account",
	"mdi mdi-36px mdi-rotate-90 mdi-account",
	"mdi mdi-36px mdi-rotate-135 mdi-account",
	"mdi mdi-36px mdi-rotate-180 mdi-account",
	"mdi mdi-36px mdi-rotate-225 mdi-account",
	"mdi mdi-36px mdi-rotate-270 mdi-account",
	"mdi mdi-36px mdi-rotate-315 mdi-account",
	"mdi mdi-36px mdi-account-alert",
	"mdi mdi-36px mdi-flip-h mdi-account-alert",
	"mdi mdi-36px mdi-flip-v mdi-account-alert",
	"mdi mdi-36px mdi-spin mdi-loading",
	"mdi mdi-36px mdi-spin mdi-star",
	"mdi mdi-36px mdi-light mdi-account dark-demo",
	"mdi mdi-36px mdi-light mdi-inactive mdi-account dark-demo",
	"mdi mdi-36px mdi-dark mdi-account",
	"mdi mdi-36px mdi-dark mdi-inactive mdi-account"
]
